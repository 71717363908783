import { useState } from 'react';
import { Balls, Button, Input, Text } from '../../components/ui';
import { useFormik } from "formik";
import { otpValidationSchema } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { ServiceProxy } from '../../ServiceProxy'
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageService, storageKeys } from '../../application/services';
import { withErrorHandler } from '../../components';
import axios from 'axios';
export const VerifyPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let { token } = location.state;
    const [submit, setSubmit] = useState(false);
    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: otpValidationSchema,
        onSubmit: (values) => {
            handleSendOtp(values)
        },
    });

    const handleSendOtp = (data) => {
        setSubmit(true)
        ServiceProxy.AuthService.verifyOTP({ ...data, token })
            .then(response => {
                if (response && response.data) {
                    StorageService.set(storageKeys.userID, response.data.user_id)
                    StorageService.set(storageKeys.accessToken, response.data.access_token)
                    StorageService.set(storageKeys.refreshToken, response.data.refresh_token)
                    setTimeout(() => {
                        setSubmit(false)
                        navigate('/home', { state: response.data })
                        window.location.reload()

                    }, 2000);


                }
            })
            .catch(e => {
                console.error({ e })
                setSubmit(false)
            })
    }
    const handleResendOtp = () => {

        ServiceProxy.AuthService.resend({ token })
            .then(response => {

                if (response && response.data) {

                }
            })
            .catch(e => {
                console.error({ e })

            })
    }

    return (
        <div className='container-fluid h-100'>
            <div className='row'>
                <div className='col xPay-logo' />
                <div className='col form-outer-card'>
                    <div className='form-wrapper'>
                        <Text className='head-text m-5' characters={t('otp')} />
                        <form onSubmit={formik.handleSubmit} autoComplete="off" >
                            <div className='form-card container mb-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput mb-5'
                                            name='otp'
                                            placeholder={t('otp')}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.otp &&
                                                Boolean(formik.errors.otp)
                                            }
                                            helperText={
                                                formik.touched.otp && t(formik.errors.otp)
                                            }
                                            maxLength={6}
                                            minLength={6}
                                        />
                                    </div>
                                    <p onClick={handleResendOtp} className='resend-text'>Resend</p>

                                </div>
                            </div>
                            <Balls numOfBalls={10} />
                            <Button className='btn-submit' type='submit' disabled={submit} title={submit ? <CircularProgress /> : t('send')} />
                        </form>

                    </div>
                </div>
            </div >
        </div >
    );
}
export const Verify = withErrorHandler(VerifyPage, axios)

