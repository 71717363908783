
import { Suspense, useEffect } from "react";
import Routes from "./routes";
import './config/i18n';
import { getCurrentLanguage, languageEnum } from './config';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import WebFont from 'webfontloader';
function App() {
  const selectedLang = getCurrentLanguage();
  useEffect(() => {
    if (selectedLang === languageEnum.Arabic) {
      document.documentElement.dir = "rtl"
    }
    else {
      document.documentElement.dir = "ltr"
    }
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });

  }, [selectedLang]);
  return (
    <Suspense fallback={<div>Loading.......</div>}>
      <Routes />
    </Suspense>
  );
}

export default App;
