export const storageKeys = {
  otpToken: "otp_token",
  userID: "user_id",
  accessToken: "a_token",
  refreshToken: "r_token",
  userName: "user_name",
  userPhoto: "photo",
  userEmail: "email",
  userPhone: "phone"
}

export class StorageService {
  static get = (key) => {
    return JSON.parse(localStorage.getItem(key) || null);
  };
  static set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  static delete = (key) => {
    localStorage.removeItem(key);
  };
  static clear = () => {
    var langItem = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('i18nextLng', langItem);
    window.location.reload()
  }
}

