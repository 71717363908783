import { useEffect, useState } from 'react'
import 'react-modern-drawer/dist/index.css'
import Logo from '../../../assets/img/logo.svg';
import Location from '../../../assets/img/locationSmallWhite.svg';
import CloseDrawer from '../../../assets/img/closeDrawer.svg';
import CalendarWhite from '../../../assets/img/calendarWhite.svg';
import ArrowDown from '../../../assets/img/arrow-down.svg';
import OrganizationsIcon from '../../../assets/img/organizationsIcon.svg';
import UshersIcon from '../../../assets/img/ushersIcon.svg';
import StatisticsIcon from '../../../assets/img/statisticsIcon.svg';
import WalletIcon from '../../../assets/img/walletIcon.svg';
import SettingsBigIcon from '../../../assets/img/settingsBigIcon.svg';
import LogoutBigIcon from '../../../assets/img/logoutBigIcon.svg';
import LogoSmall from '../../../assets/img/logoSmall.svg';
import { useNavigate } from 'react-router-dom';
import { ServiceProxy } from '../../../ServiceProxy';
import { StorageService, storageKeys } from '../../../application/services';
import { pageSize } from '../../../config';
import { Link } from 'react-router-dom';
const DrawerUI = ({ close, setClose, style, className, activeTab = 1, activeItem = 1, openList = 1, activeId }) => {

	const [openEvent, setOpenEvent] = useState(openList === 1 && true)
	const [openOrg, setOpenOrg] = useState(openList === 2 && true)
	const navigate = useNavigate()
	const UserId = StorageService.get(storageKeys.userID)
	const [isNextOrg, setIsNextOrg] = useState(null)
	const [orgCurrentPage, setOrgCurrentPage] = useState(1)
	const [orgs, setOrgs] = useState([])
	const eventsParams = {
		user: UserId,
		page_size: pageSize,
		page: orgCurrentPage
	}
	const getUserOrganizations = () => {
		ServiceProxy.OrganizationsService.getUserOrganizations(eventsParams)
			.then(response => {
				if (response && response.data) {
					// setIsNextOrg(response.data.next ? true : null)
					const result = orgCurrentPage === 1 ? response.data.results : [...orgs, ...response.data.results]
					setOrgs(result)
				}
			})
			.catch(e => console.error({ e }))
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	useEffect(() => {
		getUserOrganizations()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps


	return (
		<div className={className} style={style}>
			{close ?
				<div>
					<div className='logoSmall'>
						<img src={LogoSmall} alt='logo' />
					</div>
					<div className='logoSmall p-0'>
						<img src={CloseDrawer} alt='CloseDrawer' onClick={() => setClose(false)} />
					</div>
				</div> :
				<div className='logo'>
					<Link to="/"><img src={Logo} alt='logo' onClick={() => navigate('/')} /></Link>
					<img className='pl-2' src={CloseDrawer} alt='CloseDrawer' onClick={() => setClose(true)} />
				</div>
			}

			<div className={activeTab === 1 ? 'item item-active ' : 'item'}
				onClick={() => setOpenEvent(!openEvent)}>
				<img className={close ? 'm-0' : 'item-img'} src={CalendarWhite} alt='CalendarWhite' />
				<p className={close ? 'display-none' : 'item-title'}>My Events</p>
				<img className={close ? 'display-none' : ''} src={ArrowDown} alt='ArrowDown' style={{ marginLeft: 'auto' }} />
			</div>

			{
				!close && openEvent ?
					<ul>
						<Link to="/my-events" className='decoration-none'><li className={activeItem !== 1 ? 'opacity-25' : ''} onClick={() => navigate('/my-events')}>My Events</li></Link>
						<Link to="/create-event" className='decoration-none'><li className={activeItem !== 2 ? 'opacity-25' : ''} onClick={() => navigate('/create-event')}>Create Event</li></Link>
					</ul>
					: null
			}
			<div className={close && activeTab === 2 ? 'item item-active' : close && activeTab !== 2 ? 'item' : !close && activeTab === 2 ? 'item justify-content-start item-active' : 'item justify-content-start'}
				onClick={() => setOpenOrg(!openOrg)}>
				<img className={close ? 'm-0' : 'item-img'} src={OrganizationsIcon} alt='OrganizationsIcon' />
				<p className={close ? 'display-none' : 'item-title'}>Organizations</p>
				<img className={close ? 'display-none' : ''} src={ArrowDown} alt='ArrowDown' style={{ marginLeft: 'auto' }} />
			</div>
			{
				!close && openOrg ?
					<ul>
						{orgs.length > 0 && orgs.map((org, index) => {
							return (
								<Link to={`/my-organizations/${org.id}`} state={{ org }} className='decoration-none'><li key={index} className={org.id !== Number(activeId) ? 'opacity-25' : ''} onClick={() => navigate(`/my-organizations/${org.id}`, { state: { org } })}>{org.name}</li></Link>
							)
						})}
						<Link to='/create-organization' className='decoration-none'><li className={activeItem !== 4 ? 'opacity-25' : ''} onClick={() => navigate('/create-organization')}>Create Organization</li></Link>
					</ul>
					: null
			}
			{/* <div className={close && activeTab === 3 ? 'item item-active' : close && activeTab !== 3 ? 'item' : !close && activeTab === 3 ? 'item justify-content-start item-active' : 'item justify-content-start'}>
				<img className={close ? 'm-0' : 'item-img'} src={UshersIcon} alt='UshersIcon' />
				<p className={close ? 'display-none' : 'item-title'}>Ushers</p>
			</div> */}
			<Link to="/locations" className='decoration-none'>
				<div onClick={() => navigate('/locations')} className={close && activeTab === 4 ? 'item item-active' : close && activeTab !== 4 ? 'item' : !close && activeTab === 4 ? 'item justify-content-start item-active' : 'item justify-content-start'}>
					<img className={close ? 'm-0' : 'item-img'} src={Location} alt='LocationIcon' />
					<p className={close ? 'display-none' : 'item-title'}>Locations</p>
				</div>
			</Link>
			<div className={close && activeTab === 5 ? 'item item-active opacity-25' : close && activeTab !== 5 ? 'item opacity-25' : !close && activeTab === 5 ? 'item justify-content-start item-active opacity-25' : 'item justify-content-start opacity-25'}>
				<img className={close ? 'm-0' : 'item-img'} src={StatisticsIcon} alt='StatisticsIcon' />
				<p className={close ? 'display-none' : 'item-title'}>Statistics <span style={{ fontSize: '10px' }}>(Coming soon)</span></p>
			</div>
			{/* <div className={close && activeTab === 6 ? 'item item-active' : close && activeTab !== 6 ? 'item' : !close && activeTab === 6 ? 'item justify-content-start item-active' : 'item justify-content-start'}>
				<img className={close ? 'm-0' : 'item-img'} src={WalletIcon} alt='WalletIcon' />
				<p className={close ? 'display-none' : 'item-title'}>Wallet</p>
			</div>
			<div className={close && activeTab === 7 ? 'item item-active' : close && activeTab !== 7 ? 'item' : !close && activeTab === 7 ? 'item justify-content-start item-active' : 'item justify-content-start'}>
				<img className={close ? 'm-0' : 'item-img'} src={SettingsBigIcon} alt='SettingsBigIcon' />
				<p className={close ? 'display-none' : 'item-title'}>Settings</p>
			</div> */}
			<div className={close ? 'item' : 'item justify-content-start'} onClick={logout}>
				<img className={close ? 'm-0' : 'item-img'} src={LogoutBigIcon} alt='LogoutBigIcon' />
				<p className={close ? 'display-none' : 'item-title'}>Logout</p>
			</div>
		</div >
	)
}
export default DrawerUI