import { Header } from "../Home/components";
// import HeartRed from '../../assets/img/heartRed.svg';
// import UserImgEvent from '../../assets/img/userImgEvent.svg';
// import Play from '../../assets/img/play.svg';
// import Pause from '../../assets/img/pause.svg';
// import Location from '../../assets/img/location.svg';
// import FacebookSocial from '../../assets/img/facebookSocial.svg';
// import InstagramSocial from '../../assets/img/instagramSocial.svg';
// import LinkedinSocial from '../../assets/img/linkedinSocial.svg';
// import Twitter from '../../assets/img/twitterWhite.svg';
import TrueIcon from '../../assets/img/trueIcon.svg';
// import NoEvents from '../../assets/img/noEvents.svg';
// import ActiveEvents from '../../assets/img/avtiveEvents.svg'
// import PastEvents from '../../assets/img/eventDetails.svg';
// import OrganizationsIcon from '../../assets/img/organizationsIcon.svg';
// import ArrowRight from '../../assets/img/arrowRight.svg';
import ChangeDataIcon from '../../assets/img/changeDataIcon.svg';
// import userImg from '../../assets/img/userImg.svg';
import CameraIcon from '../../assets/img/cameraIcon.svg';
import SettingsBigIcon from '../../assets/img/settingsBigIcon.svg';
import UserPlaceholder from '../../assets/img/userPlaceholder.png';

// import ArrowDown from '../../assets/img/arrowDown.svg';
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceProxy } from "../../ServiceProxy";
import { Overlay } from "../../components/ui";
import { Input } from "antd";
// import OTPInput from "react-otp-input";
import { StorageService, storageKeys } from "../../application/services";
import { withErrorHandler } from "../../components";
import axios from "axios";

const SettingsPage = () => {

	const [user, setUser] = useState(null)
	const navigate = useNavigate()
	const userID = StorageService.get(storageKeys.userID)
	const [editUserName, setEditUserName] = useState(false)
	const fileRef = useRef(null)
	const clickUploader = () => {
		fileRef.current.click();
	}
	const handleChangeProfileFile = async (event) => {
		if (event.target.files.length > 0) {
			var file = event.target.files[0]

			var fileSize = file.size / 1024 / 1024
			var fileName = file.name
			var reg = /(\.png|\.jpg|\.jpeg)$/i

			if (fileSize > 5) {
				alert("File SSize exceeds 5MB")
			}
			else if (!reg.exec(fileName)) {
				alert("It does't support your extension !")
			}
			else {
				const base64 = await convertBase64(file)
				editUser({ ...user, photo: base64 })


			}
		}

	}
	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}
	const handleChangeInput = (e) => {
		const name = e.target.value.trim()
		const nameSplitted = name.split(" ")
		if (nameSplitted.length > 1) {
			var firstName = nameSplitted.shift()
			setUser({ ...user, first_name: firstName, last_name: nameSplitted.join(' ') })
		}
		else {
			setUser({ ...user, first_name: nameSplitted[0] })
		}

	}
	const editUser = (values) => {
		setEditUserName(false)
		ServiceProxy.UsersService.editUser(userID, values)
			.then(response => {
				if (response && response.data) {
					setUser(response.data)
					StorageService.set(storageKeys.userName, `${response.data.first_name} ${response.data.last_name}`)
					StorageService.set(storageKeys.userEmail, response.data.email)
					StorageService.set(storageKeys.userPhone, response.data.phone_number)
					StorageService.set(storageKeys.userPhoto, response.data.photo)
				}
			})
			.catch(e => console.error({ e }))

	}
	const subitEditName = () => {
		let newValues = { ...user }
		delete newValues.photo
		editUser(newValues)
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	useEffect(() => {
		setUser({
			photo: StorageService.get(storageKeys.userPhoto),
			email: StorageService.get(storageKeys.userEmail),
			name: StorageService.get(storageKeys.userName),
			phone_number: StorageService.get(storageKeys.userPhone)
		})
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container-fluid event-organizer">
			<Header logout={logout} />
			{!user ? <Overlay /> :
				<>
					<div>
						<div style={{ margin: '2.25rem', marginLeft: '5rem' }}>
							<p className='create-title' style={{ fontWeight: '700' }}><img style={{ width: '2.5rem', height: '2.25rem' }} src={SettingsBigIcon} alt="SettingsBigIcon" />Settings</p>
						</div>
						<div className="img-event position-relative">
							{/* <img src={PastEvents} alt="" /> */}
							<div className="camera">
								{/* <p>1400 * 235 px ~ Max 5 MB</p> */}
								{/* <img src={CameraIcon} alt="cameraIcon" /> */}
							</div>
						</div>
						<div className="all-events-organizer">
							<div className="organizer">
								<div className="img-organizer position-relative">
									<img src={user.photo ? user.photo : UserPlaceholder} alt="" />
									<div className="camera-img-user" onClick={clickUploader}>
										<input className="d-none" type="file" ref={fileRef} accept="image/" onChange={handleChangeProfileFile} />
										<img src={CameraIcon} alt="cameraIcon" />
									</div>
								</div>

								{editUserName === false ?
									<div className="name-organizer d-flex align-items-center flex-row gap-4">
										<div>
											<Input disabled bordered={false} suffix={<img style={{ cursor: 'pointer' }} onClick={() => setEditUserName(true)} width={48} src={ChangeDataIcon} alt="changeDataIcon" />}
												className="border-none user-name-edit" defaultValue={`${user.name}`}
											/>
										</div>
									</div> :
									<div className="name-organizer d-flex align-items-center flex-row gap-4">
										<div>
											<Input bordered={false}
												onChange={handleChangeInput}
												suffix={<img style={{ cursor: 'pointer' }} onClick={subitEditName} width={48} src={TrueIcon} alt="changeDataIcon" />}
												className="border-none user-name-edit" style={{ borderBottom: '1px solid white', borderRadius: ' 0px' }}
											/>

											{/* <p className="position m-0">Organization </p> */}
										</div>
									</div>}

							</div>
						</div>
					</div>
					<div className="row m-2 mt-4 gap-3 viewContent">
						{/* <div className="col-xl-3 col-lg-3 col-md-12">
							<div className="p-0 cardContent mt-3">
								<div className="content p-4">
									<div className="col">
										<div className="d-flex align-items-center flex-row justify-content-between">
											<p className="date m-0">About </p>
											<img style={{ cursor: 'pointer' }} width={40} src={ChangeDataIcon} alt="changeDataIcon" />
										</div>
										<TextArea bordered={false} className="desc " autoSize={{ minRows: 2 }} style={{ color: disable && '#fff' }}
											defaultValue={"It’s Mohamed Zaid an Egyptian event organizer specialized in making business event meetings"} disabled={false} />
									</div>
								</div>
							</div>
							<div className="p-0 cardContent mt-3">
								<div className="content p-4">
									<div className="col">
										<div className="d-flex align-items-center flex-row justify-content-between">
											<p className="date m-0">Social Links</p>
											<img style={{ cursor: 'pointer' }} width={40} src={ChangeDataIcon} alt="changeDataIcon" />
										</div>
										<div className="location-event d-flex gap-3">
											<Link to="" ><img src={FacebookSocial} alt="FacebookSocial" /></Link>
											<Link to="" ><img src={InstagramSocial} alt="InstagramSocial" /></Link>
											<Link to="" ><img src={LinkedinSocial} alt="LinkedinSocial" /></Link>
										</div>
										<div className="d-flex gap-3 mb-3">
											<img src={FacebookSocial} alt="FacebookSocial" />
											<Input className="social-setting" placeholder="Your Facebook Link" />
										</div>
										<div className="d-flex gap-3 mb-3">
											<img src={InstagramSocial} alt="InstagramSocial" />
											<Input className="social-setting" placeholder="Your Instagram Link" />
										</div>
										<div className="d-flex gap-3 mb-3">
											<img src={LinkedinSocial} alt="LinkedinSocial" />
											<Input className="social-setting" placeholder="Your linkedin Link" />
										</div>
									</div>
								</div>
							</div>
						</div> */}
						<div className="col">
							{/* {!changePassword ? */}
							<div className="card-settings mt-3 mb-4">
								<div className="row">
									<div className="col">
										<p>E-mail</p>
										<p className="e-mail">{user.email}</p>
									</div>
								</div>
								<div className="row mt-5">
									<div className="col">
										<p>Password</p>
										<div style={{ gap: '5.87rem' }} className="d-flex align-items-center flex-row">
											<p>**********</p>
											{/* <span className="change-password pointer" onClick={() => setChangePassword(true)}>Change Password</span> */}
										</div>
									</div>
								</div>
							</div>
							{/* :
								<div className="card-settings mt-3 mb-4">
									<div className="row">
										<div className="col">
											<p className="e-mail mb-4">Change password</p>
											<p className="change-password text-white">We sent a <span className="change-password">6 Numbers</span> to your mail check it and change password</p>
											<OTPInput
												value={otp}
												onChange={setOtp}
												numInputs={6}
												renderSeparator={<span>-</span>}
												renderInput={(props) => <input {...props} />}
											/>
										</div>
									</div>
									<div className="row mt-5">
										<div className="col">
											<p>Password</p>
											<div style={{ gap: '5.87rem' }} className="d-flex align-items-center flex-row">
												<p>**********</p>
												<span className="change-password">Change Password</span>
											</div>
										</div>
									</div>
								</div>
							} */}
						</div>
					</div>
				</>
			}
		</div>
	)
}
export const Settings = withErrorHandler(SettingsPage, axios)
