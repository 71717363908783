
import * as Yup from "yup";

import { validationRules } from "./validationRules";
const MOBILE_REGEX = new RegExp(validationRules.mobileRegex)
const NAME_REGEX = new RegExp(validationRules.nameRegex);
const MOBILE_LENGTH = validationRules.mobileLength;
const NAME_MIN_LENGTH = validationRules.nameMinLength;
const NAME_MAX_LENGTH = validationRules.nameMaxLength;
const PASSWORD_MIN_LENGTH = validationRules.passwordMinLength;
const PASSWORD_MAX_LENGTH = validationRules.passwordMaxLength;
const Password_REGEX = new RegExp(validationRules.passwordRegex);





export const loginValidationSchema = Yup.object({
    phone_number: Yup
        .string()
        .required("validation_is_required_message")
        .test('email_or_phone', 'Email / Phone is invalid', (value) => {
            return validateEmail(value) || validatePhone(value) || validateString(value);
        }),
    password: Yup.string()
        .required()

});
const validateEmail = (email) => {
    return Yup.string().email().isValidSync(email)
};

const validatePhone = (phone) => {
    return Yup.string()

        .max(MOBILE_LENGTH, "validation_phone_length_message")
        .matches(MOBILE_REGEX, "VALIDATION_PHONE_FORMAT").isValidSync(phone);
};
const validateString = (name) => {
    return Yup.string().isValidSync(name)
};
export const registrationSchema = Yup.object({
    email: Yup.string()
        .required("validation_email_is_required_message")
        .email(),

    password: Yup.string()
        .required("validation_passsword_is_required_message")
        .min(PASSWORD_MIN_LENGTH, "validation_password_minimum_length_message", { length: PASSWORD_MIN_LENGTH })
        .max(PASSWORD_MAX_LENGTH, "validation_password_maximum_length_message", { length: PASSWORD_MAX_LENGTH })
        .matches(Password_REGEX, "validation_password_format_message"),

    first_name: Yup
        .string()
        .trim()
        .min(NAME_MIN_LENGTH, "validation_name_minimum_length_message", { length: NAME_MIN_LENGTH })
        .max(NAME_MAX_LENGTH, "validation_name_maximum_length_message", { length: NAME_MAX_LENGTH })
        .matches(NAME_REGEX, "validation_name_format_message")
        .required("validation_name_is_required_message"),

    last_name: Yup
        .string()
        .trim()
        .min(NAME_MIN_LENGTH, "validation_name_minimum_length_message", { length: NAME_MIN_LENGTH })
        .max(NAME_MAX_LENGTH, "validation_name_maximum_length_message", { length: NAME_MAX_LENGTH })
        .matches(NAME_REGEX, "validation_name_format_message")
        .required("validation_name_is_required_message"),

    phone_number: Yup
        .string()
        .required("validation_phone_is_required_message")
        .max(MOBILE_LENGTH, "validation_phone_length_message")
        .matches(MOBILE_REGEX, "VALIDATION_PHONE_FORMAT")
});
export const forgetPasswordValidationSchema = Yup.object({
    user_identifier: Yup.string()
        .required("validation_is_required_message")
        .test('email_or_phone', 'Email / Phone is invalid', (value) => {
            return validateEmail(value) || validatePhone(value);
        }),
});
export const resetPasswordValidationSchema = Yup.object({
    new_password: Yup.string()
        .required("validation_passsword_is_required_message")
        .min(PASSWORD_MIN_LENGTH, "validation_password_minimum_length_message", { length: PASSWORD_MIN_LENGTH })
        .max(PASSWORD_MAX_LENGTH, "validation_password_maximum_length_message", { length: PASSWORD_MAX_LENGTH })
        .matches(Password_REGEX, "validation_password_format_message"),
});
export const otpValidationSchema = Yup.object({
    otp: Yup.string()
        .required("validation_email_is_required_message")
        .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "inavlid_otp_format_message")
});
export const organizationValidationSchema = Yup.object({
    name: Yup.string()
        .required("validation_is_required_message"),
    description: Yup.string()
        .required("validation_is_required_message"),

    facebook_link: Yup.string()
        .matches(/^(https?:\/\/)(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, 'Enter a valid url'),

    instagram_link: Yup.string()
        //eslint-disable-next-line
        .matches(/^(https?:\/\/)(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, 'Enter a valid url'),
    twitter_link: Yup.string()
        //eslint-disable-next-line
        .matches(/^(https?:\/\/)(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, 'Enter a valid url'),


});
export const createEventValidationSchema = Yup.object({
    name: Yup.string()
        .required("validation_is_required_message"),
    description: Yup.string()
        .required("validation_is_required_message"),
    phone_number: Yup
        .string()
        .required("validation_phone_is_required_message")
        .max(MOBILE_LENGTH, "validation_phone_length_message")
        .matches(MOBILE_REGEX, "VALIDATION_PHONE_FORMAT"),
    category: Yup.string()
        .required("validation_is_required_message"),
    organization: Yup.string()
        .required("validation_is_required_message"),

    locations: Yup.boolean(),
    location: Yup.string()
        .when("locations", {
            is: true,
            then: Yup.string()
                .required("validation_is_required_message"),
        }),
    address: Yup.string()
        .when("locations", {
            is: false,
            then: Yup.string()
                .required("validation_is_required_message"),
        }),
    google_map_link: Yup.string()
        .when("locations", {
            is: false,
            then: Yup.string()
                .required("validation_is_required_message"),
        }),
    city: Yup.string()
        .when("locations", {
            is: false,
            then: Yup.string()
                .required("validation_is_required_message"),
        }),
    date: Yup.string()
        .required("validation_is_required_message"),
    time: Yup.string()
        .required("validation_is_required_message")

});
export const eventTicketsValidationSchema = Yup.object({
    ticket: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string()
                    .required("validation_is_required_message"),
                price: Yup.number()
                    .nullable(true)
                    .positive()
                    .required('validation_is_required_message'),
                quantity: Yup.number()
                    .positive()
                    .nullable(true)
                    .required('validation_is_required_message'),
                early: Yup.boolean(),
                early_bird_price: Yup.number()
                    .when("early", {
                        is: true,
                        then: Yup.number()
                            .positive()
                            .nullable(true)
                            .required('validation_is_required_message')
                    }),
                late: Yup.boolean(),
                late_price: Yup.number()
                    .when("late", {
                        is: true,
                        then: Yup.number()
                            .positive()
                            .nullable(true)
                            .required('validation_is_required_message')
                    }),
                early_bird: Yup.string(),
                early_bird_quantity: Yup.number()
                    .when("early_bird", {
                        is: "byTicket",
                        then: Yup.number()
                            .positive()
                            .nullable(true)
                            .required('validation_is_required_message')
                    }),
                early_bird_date_limit: Yup.string()
                    .when("early_bird", {
                        is: "byDate",
                        then: Yup.string()
                            .required('validation_is_required_message')
                    }),
                late_tickets: Yup.string(),
                late_quantity: Yup.number()
                    .when("late_tickets", {
                        is: "byTicket",
                        then: Yup.number()
                            .positive()
                            .nullable(true)
                            .required('validation_is_required_message')
                    }),
                late_date_start: Yup.string()
                    .when("late_tickets", {
                        is: "byDate",
                        then: Yup.string()
                            .required('validation_is_required_message')
                    }),
            })
        )



});


export const addUsherValidationSchema = Yup.object({

    phone_number: Yup
        .string()
        .required("validation_phone_is_required_message")
        .max(MOBILE_LENGTH, "validation_phone_length_message")
        .matches(MOBILE_REGEX, "VALIDATION_PHONE_FORMAT"),
    first_name: Yup.string()
        .required("validation_is_required_message"),
    last_name: Yup.string()
        .required("validation_is_required_message"),
});
export const locationValidationSchema = Yup.object({
    address: Yup.string()
        .required("validation_is_required_message"),
    city: Yup.string()
        .required("validation_is_required_message"),

    google_map_link: Yup.string().required("validation_is_required_message")
        //eslint-disable-next-line
        .matches(/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, 'Enter a valid url'),

});





