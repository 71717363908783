
import ActiveEvents from '../../assets/img/activeEvents.svg';
import PastEvents from '../../assets/img/pastEvents.svg';
import NoEvents from '../../assets/img/noEvents.svg';
import CalendarWhiteEvents from '../../assets/img/calendarWhiteEvents.svg';
import Plus from '../../assets/img/plusSmallWhite.svg';
import { useState, useEffect } from "react";
import DrawerUI from "../../components/ui/Drawer";
import { useNavigate } from 'react-router-dom';
import { UserMenu } from '../../components';
import { StorageService } from '../../application/services';
import { ServiceProxy } from '../../ServiceProxy';
import { MyEventCard } from '../../components';
import { pageSize } from '../../config';
import { Overlay } from '../../components';
import { withErrorHandler } from '../../components';
import { message } from 'antd';
import axios from 'axios';
const MyEventsPage = () => {
    const navigate = useNavigate()
    const [toggle, setToggle] = useState(1)
    const [close, setClose] = useState(false)
    const [events, setEvents] = useState([])
    const [pastEvents, setPastEvents] = useState([])

    const [loading, setLoading] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Done Successfully',
        });
    };
    const logout = () => {
        StorageService.clear()
        navigate('/')
    }
    const eventsParams = {
        page_size: pageSize,
        page: 1
    }
    const clickEditEvent = (data) => {
        navigate(`/edit-event/${data.id}`, { state: { event: data } })
    }
    const eventDetailsNavigate = (id, eventData) => navigate(`/event-details/${id}`, { state: { eventData } })

    const getActiveEvents = () => {
        ServiceProxy.EventsService.getUserEvents({ ...eventsParams, is_active: true })
            .then(response => {
                if (response && response.data) {
                    const result = response.data.results
                    setEvents(result)
                    getPastEvents()
                }
            })
            .catch(e => {
                console.error({ e })
                setLoading(false)
            })
    }
    // const getMoreActiveEvents = () => {
    //     if (isNextEvents !== null) {
    //         setEventsCurrentPage(eventsCurrentPage + 1);
    //         getActiveEvents({ ...eventsParams, page: eventsCurrentPage + 1, is_active: true })
    //     }
    // }
    const getPastEvents = () => {
        ServiceProxy.EventsService.getUserEvents({ ...eventsParams, is_active: false })
            .then(response => {
                if (response && response.data) {
                    const result = response.data.results
                    setPastEvents(result)
                    setLoading(false)
                }
            })
            .catch(e => {
                console.error({ e })
                setLoading(false)
            })
    }
    // const getMorePastEvents = () => {
    //     if (isNextPastEvents !== null) {
    //         setPastEventsCurrentPage(pastEventsCurrentPage + 1);
    //         getPastEvents({ ...eventsParams, page: pastEventsCurrentPage + 1, is_active: false })
    //     }
    // }
    const handleDeleteEvent = (id) => {
        setLoading(true)
        ServiceProxy.EventsService.deleteEvent(id)
            .then(() => {
                setLoading(false)
                getActiveEvents()
                getPastEvents()
                success()
            })
            .catch(e => {
                console.error({ e })
                setLoading(false)
            })
    }
    useEffect(() => {
        getActiveEvents()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="container-fluid">
            {contextHolder}
            <div className="row">
                <DrawerUI className='drawer' close={close} setClose={setClose} style={{ width: close && '5rem' }} />
                {loading ? <Overlay /> :
                    <div className={close ? "col width-colum" : "col drawer-in-small width-colum"}>
                        <div className="my-events container">
                            <div>
                                <div className="row">
                                    <div className='d-flex gap-2 navUser justify-content-end m-1'>
                                        <UserMenu logout={logout} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className='create-title'><img src={CalendarWhiteEvents} alt="CalendarWhiteEvents" />My Events</p>
                                    </div>
                                    <div className='col mt-5 statistics justify-content-end' onClick={() => navigate('/create-event')}>
                                        <button className="p-3 bookNow" style={{ width: '10.5rem' }}><img src={Plus} alt='plus' />Create Event</button>

                                    </div>
                                </div>
                            </div>

                            <div className="all-event">
                                <ul className="tabs-events">
                                    <li className={toggle === 1 ? 'tab-events-show col-3' : 'tab-events col-3'} onClick={() => setToggle(1)}><img src={ActiveEvents} alt="Play" /> Active Events</li>
                                    <li className={toggle === 2 ? 'tab-events-show col-3' : 'tab-events col-3'} onClick={() => setToggle(2)}><img src={PastEvents} alt="Pause" /> Past Events</li>
                                </ul>
                            </div>
                            <div className="row  mt-4  viewContent">
                                {toggle === 1 && events.length > 0 ?
                                    events.map(e => {
                                        return (
                                            <div className="col-4 mb-4 " key={e.id}>
                                                <MyEventCard eventDetailsNavigate={() => eventDetailsNavigate(e.id, e)} handleEdit={clickEditEvent} data={e} activeEvent handleDelete={handleDeleteEvent} />
                                            </div>
                                        )
                                    })
                                    : toggle === 1 && events.length === 0 ?
                                        <div className="no-event-to-show">
                                            <img src={NoEvents} alt="NoEvents" />
                                            <p className="date m-3">No Events to show</p>
                                        </div>
                                        : toggle === 2 && pastEvents.length > 0 ?
                                            pastEvents.map(e => {
                                                return (
                                                    <div className="col-4 mb-4 " key={e.id}>
                                                        <MyEventCard data={e} handleDelete={handleDeleteEvent} />
                                                    </div>
                                                )
                                            })
                                            : <div className="no-event-to-show">
                                                <img src={NoEvents} alt="NoEvents" />
                                                <p className="date m-3">No Events to show</p>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}
export const MyEvents = withErrorHandler(MyEventsPage, axios)
