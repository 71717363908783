import Ticket from '../../../assets/img/ticket.svg';
import ArrowRight from '../../../assets/img/arrowRight.svg';
import Subtract from '../../../assets/img/Subtract.svg';
import { useNavigate } from 'react-router-dom';
export const EventsIntro = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='container-fluid d-flex flex-row mt-5 home-card'>
                <div className='row m-0'>
                    <div className='col'>
                        <div className='row d-flex align-items-start mb-5 text-card'>
                            <h1>Unleash<span> Event </span></h1>
                            <h1>Potential With</h1>
                            <h1>XPay</h1>
                            <p>Manage your events with ease, XPay’s user-friendly platform  help you create and manage events , Sell tickets , assigning Ushers to scan </p>
                            <div className='btns-home-card'>
                                <button className="create-event text-white" type="submit" onClick={() => navigate('/create-event')}>Create Event</button>
                                <button className="create-event discover-event text-white" onClick={() => navigate('/events')}>Discover Events
                                    <img className='d-griud mx-2' src={ArrowRight} alt='ArrowRight' />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col imgTicket'>
                        <img src={Ticket} alt='Ticket' />
                    </div>
                </div>
            </div>
            <img className='container-fluid steps p-0' src={Subtract} alt='Subtract' />
        </>
    )
}