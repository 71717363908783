
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class UsersService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/users`
    }
    getUserData(id) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/${id}/`);
        removeDefaultHeaders(axios)
        return request
    }
    editUser(id, values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.patch(`${this._baseUrl}/${id}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
}