import location from '../../assets/img/locationBigWhite.svg';

import { Inputs, UploadFile } from '../CreateEvent/components/Inputs';
import DrawerUI from '../../components/ui/Drawer';
import PlusBigWhite from '../../assets/img/plusBigWhite.svg';
import PlusSmallWhite from '../../assets/img/plusSmallWhite.svg';
import NoLocations from '../../assets/img/noLocations.svg';
import { useState, useEffect, useRef } from 'react';
import { CardLocation } from '../../components/ui/CardLocation';
import { Overlay, UserMenu } from '../../components';
import { DropdownOutline } from '../CreateEvent/components/Dropdowns';
import { useNavigate } from 'react-router-dom';
import { StorageService } from '../../application/services';
import { ServiceProxy } from '../../ServiceProxy';
import { pageSize, locationValidationSchema } from '../../config';
import { useFormik } from "formik";
import DeleteIcon from '../../assets/img/deleteIcon.svg';
import Location from '../../assets/img/locations.svg';
import { t } from 'i18next';
export const Locations = () => {
	const navigate = useNavigate()
	const [close, setClose] = useState(false)
	const [loading, setLoading] = useState(true)
	const [submit, setSubmit] = useState(false)

	const [locations, setLocations] = useState([])
	const [showForm, setShowForm] = useState(false)
	const [cities, setCities] = useState([])
	const [profileFile, setProfileFile] = useState(null)
	const [profileBase64, setProfileBase64] = useState(null)
	const fileRef = useRef(null)

	const clickUploader = () => {
		fileRef.current.click();
	}
	const handleChangeProfileFile = async (event) => {
		if (event.target.files.length > 0) {
			var file = event.target.files[0]

			var fileSize = file.size / 1024 / 1024
			var fileName = file.name
			var reg = /(\.png|\.jpg|\.jpeg)$/i

			if (fileSize > 5) {
				alert("File SSize exceeds 5MB")
			}
			else if (!reg.exec(fileName)) {
				alert("It does't support your extension !")
			}
			else {
				const base64 = await convertBase64(file)

				setProfileBase64(base64)
				setProfileFile(file)
			}
		}
	}
	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	const params = {
		page: 1,
		page_size: pageSize
	}
	const getCities = () => {
		ServiceProxy.HelpersService.getCities()
			.then(response => {
				if (response && response.data) {
					getUserLocations()
					setCities(response.data.results)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const getUserLocations = () => {
		ServiceProxy.LocationsService.getLocationsList(params)
			.then(response => {
				setLoading(false)
				if (response && response.data) {
					setLocations(response.data.results)
				}
			})
			.catch(e => {
				setLoading(false)
				console.error({ e })
			})
	}
	const deleteLocation = (id) => {
		setSubmit(true)
		ServiceProxy.LocationsService.deleteLocation(id)
			.then(response => {
				setSubmit(false)
				getUserLocations()
			})
			.catch(e => {
				setSubmit(false)
				console.error({ e })
			})
	}
	const addLocation = (values) => {
		setSubmit(true)
		ServiceProxy.LocationsService.addLocation(values)
			.then(response => {
				setSubmit(false)
				if (response && response.data) {
					formik.resetForm()
					getUserLocations()

				}
			})
			.catch(e => {
				setSubmit(false)
				console.error({ e })
			})
	}
	const formik = useFormik({
		initialValues: {
			city: '',
			address: '',
			google_map_link: '',

		},
		validationSchema: locationValidationSchema,
		onSubmit: (values) => {
			addLocation({ ...values, logo: profileBase64 })
		},
	});
	useEffect(() => {
		getCities()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container-fluid">
			{loading ? <Overlay /> :
				<div className='row'>
					<DrawerUI className='drawer' activeTab={4} close={close} setClose={setClose} style={{ width: close && '5rem' }} />
					<div className={close ? 'col width-colum' : 'col drawer-in-small'}>
						<div className='container'>
							<div className='row'>
								<div className='col'>
									<div className="row">
										<div className='d-flex gap-2 navUser justify-content-end m-1'>
											<UserMenu logout={logout} />
										</div>
									</div>
									<div className="col">
										<p className='create-title'><img src={location} alt="location" />Locations</p>
									</div>
									{locations.length > 0 ?
										<div className="the-table mt-4">
											<table className="table table-borderless">
												<thead className="position-relative">
													<th scope="col" className="usher-name" style={{ background: '#171717', left: '4rem' }}>Name</th>
												</thead>
												<tbody>
													{locations.map((data, index) => {
														return (
															<tr key={index}>
																<div className='statistics m-0'>
																	<td>
																		<a href={data.google_map_link} target="_blank" rel="noreferrer">
																			<p className="like text-white" style={{ fontSize: '1rem', fontWeight: 400 }}><img src={Location} alt='Location' />   {data.address}  - {data.city?.name}</p>
																		</a>
																	</td>
																	<td>
																		<button onClick={() => deleteLocation(data.id)} className="btn w-fit  bookNow edit more-details"><img src={DeleteIcon} alt='deleteIcon' />Delete</button>
																	</td>
																</div>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
										:
										locations.length === 0 && showForm === false ?
											<div className="row">
												<div className="row mt-5">
													<div className='col d-flex justify-content-center'>
														<img src={NoLocations} alt='NoLocations' />
													</div>
												</div>
												<div className='col statistics justify-content-center mt-3'>
													<div className='row justify-content-center'>
														<p className='create-subtitle justify-content-center mb-4'><img src={PlusBigWhite} alt="location" />No locations to show</p>
														<button onClick={() => setShowForm(true)} className="bookNow p-3"><img src={PlusSmallWhite} alt='PlusSmallWhite' />Add</button>
													</div>
												</div>
											</div>
											: null}
									{locations.length > 0 || showForm ?
										<form onSubmit={formik.handleSubmit} autoComplete="off" >
											<div className="row mt-5">
												<div className="col">
													<p className='create-subtitle'><img src={PlusBigWhite} alt="location" />Add New Locations</p>
												</div>
											</div>
											<div className="row mt-5">
												<div className="col-lg-3 col-12">
													<Inputs label={'Location Name'}
														placeholder={'Place Name'}
														name="address"
														onChange={formik.handleChange}
														value={formik.values.address}
														error={
															formik.touched.address &&
															Boolean(formik.errors.address)
														}
														helperText={
															formik.touched.address && t(formik.errors.address)
														} />
												</div>
												<div className="col-lg-6 col-12">
													<Inputs label={'Google Maps URL'} placeholder={'Google Maps URL'}
														name="google_map_link"
														onChange={formik.handleChange}
														value={formik.values.google_map_link}
														error={
															formik.touched.google_map_link &&
															Boolean(formik.errors.google_map_link)
														}
														helperText={
															formik.touched.google_map_link && t(formik.errors.google_map_link)
														} />
												</div>
												<div className="col-lg-3 col-12">
													<DropdownOutline label={'City'}
														data={cities} name="city"
														onChange={formik.handleChange}
														value={formik.values.city}
														error={
															formik.touched.city &&
															Boolean(formik.errors.city)
														}
														helperText={
															formik.touched.city && t(formik.errors.city)
														} />
												</div>
											</div>
											<div className="row mt-5">
												{/* <div className="col-lg-4 col-12">
													<UploadFile label={'Place Image (Optional)'}
														onChange={handleChangeProfileFile}
														inputRef={fileRef} clickUploader={clickUploader}
														value={profileFile && profileFile.name} />
												</div> */}
												<div className='col statistics justify-content-end'>
													<button type="submit" className="bookNow p-3">
														<img src={PlusSmallWhite} alt='PlusSmallWhite' />Add Location</button>
												</div>
											</div>
										</form> : null
									}
								</div>
							</div>
						</div>
					</div>
				</div>}
			{submit && <Overlay />}
		</div>
	)
}