import DrawerUI from "../../components/ui/Drawer";

import { EditStepperUI } from "../../components/ui/Stepper";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useState } from "react";
import { StorageService } from "../../application/services";
import { UserMenu } from '../../components/ui'
import { ServiceProxy } from "../../ServiceProxy";
import { withErrorHandler } from "../../components/application";
import axios from 'axios'
const EditEventStepsPage = () => {
	const navigate = useNavigate()
	const [close, setClose] = useState(false)
	const [activeStep, setActiveStep] = useState(0);
	const [submit, setSubmit] = useState(false)
	const [eventId, setEventId] = useState(null)
	const [organizationId, setOrganizationId] = useState(null)
	const location = useLocation()
	const event = location.state?.event
	let { id } = useParams();
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	const submitEvent = (values) => {
		setSubmit(true)
		ServiceProxy.EventsService.editEvent(id, values)
			.then(response => {
				setSubmit(false)
				if (response && response.data) {
					setEventId(response.data.id)
					setOrganizationId(response.data.organization)
					setActiveStep(activeStep + 1)
				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
	}

	const addTicket = (id, values) => {
		setSubmit(true)
		ServiceProxy.EventTicketsService.editEventTicket(id, { ...values, event: eventId })
			.then(response => {
				if (response && response.data) {
					// setEventId(response.data.id)
					setSubmit(false)
					// setCities(response.data.results)
					setActiveStep(activeStep + 1)
					if (!organizationId) {
						navigate('/my-events')

					}

				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
		// setActiveStep(activeStep + 1)
	}

	const addNewTicket = (values) => {
		setSubmit(true)
		ServiceProxy.EventTicketsService.addEventTicket({ ...values, event: eventId })
			.then(response => {
				if (response && response.data) {

				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
		// setActiveStep(activeStep + 1)
	}
	return (
		<div className="container-fluid">
			<div className='row'>
				<DrawerUI className='drawer' close={close}
					setClose={setClose} activeTab={1} activeItem={2} style={{ width: close && '5rem' }} />
				<div className={close ? 'col width-colum' : 'col drawer-in-small'}>
					<div className='container'>
						<div className='row'>
							<div className='col'>
								<div className="row">
									<div className='d-flex gap-2 navUser justify-content-end m-1'>
										<UserMenu logout={logout} />
									</div>
								</div>
								<div className="row">
									<EditStepperUI clickNext={submitEvent} submit={submit} setSubmit={setSubmit} activeStep={activeStep} setActiveStep={setActiveStep} addTicket={addTicket} addNewTicket={addNewTicket} organization={organizationId} event={eventId} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export const EditEventSteps = withErrorHandler(EditEventStepsPage, axios)