import React from "react";
import { Modal } from "../../ui";
import { ServiceProxy } from "../../../ServiceProxy";
import { StorageService, storageKeys } from "../../../application/services";
// import { Navigate } from "react-router-dom";
export const withErrorHandler = (WrappedComponent, axios) => {
  class WithErrorHandler extends React.Component {
    state = {
      error: "",
      show: false,
      errorObj: null,
    };
    getToken = () => {
      const rToken = localStorage.getItem("r_token");
      if (rToken !== "undefined") {
        ServiceProxy.AuthService.resquestToken({
          refresh: StorageService.get(storageKeys.refreshToken),
        })
          .then((response) => {
            if (response && response.data) {
              StorageService.set(storageKeys.accessToken, response.data.access);
              StorageService.set(
                storageKeys.refreshToken,
                response.data.refresh
              );
              window.location.reload();
            }
          })
          .catch((e) => {
            StorageService.clear();
            console.error({ e });
          });
      } else {
        StorageService.clear();
        // Navigate("/");
      }
    };
    componentDidMount() {
      this.reqInterceptor = axios.interceptors.request.use((request) => {
        this.setState({ error: null });
        return request;
      });
      this.resInterceptor = axios.interceptors.response.use(
        (res) => res,
        (error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            this.getToken();
          } else {
            if (
              error.response.data &&
              error.response.data.non_field_errors &&
              error.response.data.non_field_errors.length > 0
            ) {
              this.setState({
                errorObj: error.response.data.non_field_errors[0],
              });
            }
            this.setState({ error: error.response.data, show: true });
          }
        }
      );
    }
    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }
    handleCloseModal = () => {
      this.setState({ show: false });
    };
    render() {
      const { errorObj, error } = this.state;

      return (
        <div>
          <WrappedComponent {...this.props} />

          <Modal
            show={this.state.show}
            handleClose={this.handleCloseModal}
            message={
              errorObj
                ? errorObj
                : error && typeof error === "string"
                ? error
                : ""
            }
          />
        </div>
      );
    }
  }
  return WithErrorHandler;
};
