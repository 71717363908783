import Stepper from 'react-stepper-horizontal';

import { DetailsEventUpdate } from '../../../pages/EditEventSteps/components';
import PlusBigWhite from '../../../assets/img/plusBigWhite.svg';
import RightIcon from '../../../assets/img/rightIcon.svg';
import { TicketsUpdate } from '../../../pages/EditEventSteps/components/Tickets';
import { UshersUpdate } from '../../../pages/EditEventSteps/components/Ushers';
import { DetailsEvent } from '../../../pages/CreateEvent/components/DetailsEvent';
import { Tickets } from '../../../pages/CreateEvent/components/Tickets';
import { Ushers } from '../../../pages/CreateEvent/components/Ushers';
import { useNavigate } from 'react-router-dom';


export function StepperUI({ clickNext, activeStep, setActiveStep, submit, addTicket, setSubmit, organization, event }) {
	const navigate = useNavigate()
	const steps = [
		{ title: 'Event Details' },
		{ title: 'Tickets' },
		{ title: 'Ushers' },
	];

	const getSectionComponent = () => {
		switch (activeStep) {
			case 0: return <DetailsEvent activeStep={activeStep} clickNext={clickNext} steps={steps} submit={submit} setSubmit={setSubmit} />;
			case 1: return <Tickets activeStep={activeStep} setActiveStep={setActiveStep} clickNext={clickNext} steps={steps} submit={submit} setSubmit={setSubmit} addTicket={addTicket} />;
			case 2: return <Ushers organization={organization} event={event} />;
			default: return null;
		}
	}

	return (
		<div className='container-stepper'>
			<div className='row-stepper'>
				<div className="col-4">
					<p className='create-title'><img src={PlusBigWhite} alt="PlusBigWhite" />Create Event</p>
				</div>
				<div className="col container span-in-stepper">
					<Stepper
						steps={steps}
						activeStep={activeStep}
						size={50}
						circleFontSize={20}
						activeColor="#413DF6"
						completeColor="#413DF6"
						defaultBorderColor="white"
						completeBarColor="#413DF6"
						defaultColor="#171717"
						defaultBarColor="#fff"
						defaultBorderStyle="solid"
						defaultBorderWidth={2}
						defaultTitleColor='#fff'
						completeTitleColor='#fff'
						activeTitleColor='#fff'
						lineMarginOffset={0}
						lineWidthOffset={10}

					/>
				</div>
				<div className='col-3 cancel-create'>
					{(activeStep !== 0 && activeStep !== steps.length)
						? <button className='stepper-next stepper-back' onClick={() => setActiveStep(activeStep - 1)}><img src={RightIcon} alt='RightIcon' /> Back</button>
						: <button className='stepper-next stepper-back' onClick={() => navigate(-1)}>Cancel</button>
					}
				</div>

			</div>
			<div>
				{getSectionComponent()}

			</div>

		</div>
	);
}
export function EditStepperUI({ clickNext, activeStep, setActiveStep, submit, addTicket, addNewTicket, setSubmit, organization, event }) {
	const navigate = useNavigate()
	const steps = [
		{ title: 'Event Details' },
		{ title: 'Tickets' },
		{ title: 'Ushers' },
	];

	const getSectionComponent = () => {
		switch (activeStep) {
			case 0: return <DetailsEventUpdate activeStep={activeStep} clickNext={clickNext} steps={steps} submit={submit} setSubmit={setSubmit} />;
			case 1: return <TicketsUpdate event={event} activeStep={activeStep} setActiveStep={setActiveStep} clickNext={clickNext} steps={steps} submit={submit} setSubmit={setSubmit} addTicket={addTicket} addNewTicket={addNewTicket} />;
			case 2: return <UshersUpdate organization={organization} event={event} />;
			default: return null;
		}
	}

	return (
		<div className='container-stepper'>
			<div className='row-stepper'>
				<div className="col-4">
					<p className='create-title'><img src={PlusBigWhite} alt="PlusBigWhite" />Edit Event</p>
				</div>
				<div className="col container span-in-stepper">
					<Stepper
						steps={steps}
						activeStep={activeStep}
						size={50}
						circleFontSize={20}
						activeColor="#413DF6"
						completeColor="#413DF6"
						defaultBorderColor="white"
						completeBarColor="#413DF6"
						defaultColor="#171717"
						defaultBarColor="#fff"
						defaultBorderStyle="solid"
						defaultBorderWidth={2}
						defaultTitleColor='#fff'
						completeTitleColor='#fff'
						activeTitleColor='#fff'
						lineMarginOffset={0}
						lineWidthOffset={10}

					/>
				</div>
				<div className='col-3 cancel-create'>
					{(activeStep !== 0 && activeStep !== steps.length)
						? <button className='stepper-next stepper-back' onClick={() => setActiveStep(activeStep - 1)}><img src={RightIcon} alt='RightIcon' /> Back</button>
						: <button className='stepper-next stepper-back' onClick={() => navigate(-1)}>Cancel</button>
					}
				</div>

			</div>
			<div>
				{getSectionComponent()}

			</div>

		</div>
	);
}
