
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class LocationsService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/locations`
    }
    getLocationsList(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/list/`, { params });
        removeDefaultHeaders(axios)
        return request;
    }
    getLocationData(id) {
        return axios.get(`${this._baseUrl}/${id}`);
    }
    addLocation(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
    deleteLocation(id) {
        addAxiosDefaultHeaders(axios)
        const request = axios.delete(`${this._baseUrl}/${id}/`);
        removeDefaultHeaders(axios)
        return request;
    }

}