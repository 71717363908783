
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class OrganizationsService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/organizations`
    }
    getOrganizationsList(params) {
        return axios.get(`${this._baseUrl}/list`, { params });
    }
    getOrganizationData(id) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/${id}`);
        removeDefaultHeaders(axios)
        return request
    }
    getUserOrganizations(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/users/list/`, { params });
        removeDefaultHeaders(axios)
        return request
    }
    addOrganization(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/`, values);
        removeDefaultHeaders(axios)
        return request
    }
    editOrganization(id, values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.patch(`${this._baseUrl}/${id}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
}