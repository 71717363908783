
import axios from "axios";
export class AuthService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/auth`
    }
    login(values) {
        return axios.post(`${this._baseUrl}/login/`, values);
    }
    register(values) {
        return axios.post(`${this._baseUrl}/register/`, values);
    }
    resquestToken(values) {
        return axios.post(`${this._baseUrl}/refresh/`, values);
    }
    verifyOTP(values) {
        return axios.post(`${this._baseUrl}/verify/`, values);
    }
    forgetPassword(values) {
        return axios.post(`${this._baseUrl}/forget-password/`, values);
    }
    resend(values) {
        return axios.post(`${this._baseUrl}/resend/`, values);
    }
    resetPasssword(values) {
        return axios.post(`${this._baseUrl}/reset-password/`, values);
    }
}