import { useState } from 'react';
import { Balls, Button, Input, Text } from '../../components/ui';
import { useFormik } from "formik";
import { resetPasswordValidationSchema } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { ServiceProxy } from '../../ServiceProxy'
import { t } from 'i18next';
import { withErrorHandler } from '../../components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const ResetPasswordPage = () => {

    let { token } = useParams();
    const navigate = useNavigate()
    const [submit, setSubmit] = useState(false);

    const formik = useFormik({
        initialValues: {
            new_password: '',
        },
        validationSchema: resetPasswordValidationSchema,
        onSubmit: (values) => {
            handleResetPassword(values)
        },
    });

    const handleResetPassword = (data) => {
        setSubmit(true)
        ServiceProxy.AuthService.resetPasssword({ ...data, token })
            .then(response => {

                if (response && response.status === 200) {
                    setSubmit(false)
                    navigate('/login')
                }
            })
            .catch(e => {
                console.error({ e })
                setSubmit(false)
            })
    }
    return (
        <div className='container-fluid h-100'>
            <div className='row'>
                <div className='col xPay-logo' />
                <div className='col form-outer-card'>
                    <div className='form-wrapper'>
                        <Text className='head-text m-5' characters={'Reset Password'} />
                        <form onSubmit={formik.handleSubmit} autoComplete="off" >
                            <div className='form-card container mb-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput mb-5'
                                            name='new_password'
                                            placeholder={'New Password'}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.new_password &&
                                                Boolean(formik.errors.new_password)
                                            }
                                            helperText={
                                                formik.touched.new_password && t(formik.errors.new_password)
                                            }
                                            maxLength={16}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Balls numOfBalls={10} />
                            <Button className='btn-submit' type='submit' disabled={submit} title={submit ? <CircularProgress /> : 'Send'} />
                        </form>
                        <div className='action-wrapper'>
                            <a href="/login">{t('sign_in')}</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export const ResetPassword = withErrorHandler(ResetPasswordPage, axios)

