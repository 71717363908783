import { DatePickerUI, Input } from '../../../components';
import SearchEvent from '../../../assets/img/searchEvent.svg';
import { SelectUI } from '../../../components/ui/Select';
import ArrowDown from '../../../assets/img/arrowDown.svg';
import { DatePicker } from 'antd';
export const SearchEvents = ({ cities, categories, date, category, city, handleChangeCity, handleChangCategory,
	handleChangeDate, handleSendSearch, handleChangeSearchInput, search, eventsParams }) => {
	return (
		<>
			<div className='container-fluid events'>
				<h3>Explore events and buy your ticket</h3>
				<h3>safely in minutes</h3>
				{/* <p>Explore, events by location </p> */}
				{/*<div className='events-input'>
					<input placeholder='Search by event or organization' />
					<Input placeholder='Search by event or organization' />
					<img src={SearchEvent} alt='SearchEvent' />
				</div>*/}
				<div className='events-input'>
					{/* <input placeholder='Search by event or organization' /> */}
					<Input placeholder='Find the right event for you' onChange={handleChangeSearchInput} value={search} />
					<img src={SearchEvent} alt='SearchEvent' onClick={() => handleSendSearch({ ...eventsParams, search })} />
				</div>
			</div>
			<div className='container-fluid all-events pb-0'>
				<div className='row'>
					<div className='d-flex gap-4 col justify-content-xl-start justify-content-sm-center align-items-center search'>
						<div className='popular'>Popular in</div>
						{/* <DropDownSelect label={city ? '' : 'Location'} dropDownLabelStyle='menu-label' options={cities} handleChange={handleChangeCity} value={city} /> */}
						<SelectUI className='catagory-location mt-2'
							placeholder='Location'
							Icon={<img style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }} src={ArrowDown} alt="expand" />}
							data={cities}
							handleChange={handleChangeCity} value={city} />
					</div>

					<div className='d-flex gap-4 col justify-content-xl-end justify-content-sm-center align-items-center search'>
						<div className='date'>
							<DatePicker placeholder="Date" className="date-for-events-home" bordered={false} onChange={handleChangeDate} />
							{/* <DatePickerUI label={date ? '' : 'Date'} value={date} onChange={handleChangeDate} /> */}
						</div>
						<div className='date'>
							{/* <DropDownUI label={category ? '' : 'All Categories'} dropDownLabelStyle='menu-categories' options={categories} handleChange={handleChangCategory} value={category} /> */}
							<SelectUI className='catagory'
								placeholder='All Categories'
								Icon={<img style={{ height: '0.568rem', marginTop: '0.5rem', marginLeft: '0.5rem' }} src={ArrowDown} alt="expand" />}
								data={categories}
								handleChange={handleChangCategory} value={category} />
						</div>
					</div>

				</div>
			</div>
		</>
	);
}