
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class PurchaseService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/purchase`
    }
    purchasePreview(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/review/`, values);
        removeDefaultHeaders(axios)
        return request
    }
    purchase(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/`, values);
        removeDefaultHeaders(axios)
        return request
    }
    listPurchases(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/list/`, { params });
        removeDefaultHeaders(axios)
        return request
    }

}