
export const ErrorPage = () => {
    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>Not Found</i>
                </p>
            </div>
        </div>
    );
}
export function Error({ message, btnText, onClick }) {
    return (
        <div >
            <div className="response-wrapper">
                <h1>Oops!</h1>
                <p>{message}</p>
                <div className="btn-wrapper">
                    <button onClick={onClick}>{btnText}</button>
                </div>
            </div>
        </div>
    )
}