import { StorageService, storageKeys } from '../../../application/services';
// import AR from '../../../assets/img/ar.svg';
import Logo from '../../../assets/img/logo.svg';
// import Notification from '../../../assets/img/notification.svg';

import { UserMenu } from '../../../components';
// import { changeCurrentLanguage, getCurrentLanguage, languageEnum } from '../../../config'
import { Link } from 'react-router-dom';
export const Header = ({ loginNavigate, signupNavigate, logout }) => {
	// const selectedLang = getCurrentLanguage();
	const userID = StorageService.get(storageKeys.userID)
	const pathname = window.location.pathname
	return (
		<nav className="navbar navbar-expand-lg bg-body-tertiary navbar-custom">
			<div className="container-fluid marginHeaderAndFooter">
				{/* <img src={Logo} alt='Logo' /> */}
				<Link to="/"><img src={Logo} alt='Logo' /></Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarTogglerDemo02">
					<ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
						<li className="nav-item">
							<a className={pathname === "/" || pathname === "/home" ? "nav-link active" : "nav-link"} aria-current="page" href="/">Home</a>
						</li>
						<li className="nav-item">
							<a className={pathname === "/events" ? "nav-link active" : "nav-link"} href="/events">Events</a>
						</li>
						<li className="nav-item">
							<a className={pathname === "/about" ? "nav-link active" : "nav-link"} href="/about">About</a>
						</li>
						<li className="nav-item">
							<a className={pathname === "/contact-us" ? "nav-link active" : "nav-link"} href="contact-us">Contact us</a>
						</li>
						{/* {selectedLang === languageEnum.English ?
							<li className="nav-item" onClick={() => changeCurrentLanguage(languageEnum.Arabic)}>
								<img src={AR} alt='AR' />
								<span>العربية</span>
							</li>
							:
							<li className="nav-item" onClick={() => changeCurrentLanguage(languageEnum.English)}>
								<img src={AR} alt='AR' />
								<span>EN</span>
							</li>
						} */}
					</ul>
					{userID ?
						<>
							{/* <div className='d-flex gap-3'>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										<img src={Notification} alt='Notification' />
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><a className="dropdown-item" href="#">Action</a></li>
										<li><a className="dropdown-item" href="#">Another action</a></li>
										<li><a className="dropdown-item" href="#">Something else here</a></li>
									</ul>
								</li>
							</div> */}

							<UserMenu logout={logout} />
						</>
						:
						<div className='d-flex gap-3'>
							<button className="btn text-white signin-btn" type="submit" onClick={signupNavigate}>Sign Up</button>
							<button className="btn text-white border-0" type="submit" onClick={loginNavigate}>Log In</button>
						</div>
					}
				</div>
			</div>
		</nav>
	);
}

