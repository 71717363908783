
import CalendarBlue from '../../../assets/img/calendarBlue.svg';
// import Eye from '../../../assets/img/eye.svg';
import EventImg2 from '../../../assets/img/eventImg2.svg';
// import HeartWhite from '../../../assets/img/heartWhite.svg';
// import plusSmallWhite from '../../../assets/img/plusSmallWhite.svg';
import EditIcon from '../../../assets/img/editIcon.svg';
import DeleteIcon from '../../../assets/img/deleteIcon.svg';
import Location from '../../../assets/img/location.svg';
import { Popconfirm } from 'antd';
export const OrganizationEventCard = ({ data, handleDelete, handleEdit, active }) => {
	const confirm = (e) => {
		console.log(e);
		handleDelete(data.id)
	};
	const cancel = (e) => {
		console.log(e);

	};
	return (
		<div className="cards-events" key={data.id}>
			<div className="img-card">
				{!data.image ?
					<div className='event-placeholder-img' style={{ height: '50%', width: '100%' }}></div> :
					<img src={data.image} alt="img-event" />
				}
			</div>
			<div className="card-body">
				{/* <div className='col m-0 statistics btns'>
					<button  className="btn btn-add-admin"><img src={plusSmallWhite} alt='plusSmallWhite' />Add Admins</button>
				</div> */}
				<div className='col statistics'>
					{/* <p className='like'><img src={HeartWhite} alt='HeartRed' />20 Like</p>
					<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p> */}
					<p className='like'><p className='like'>{data.category && data.category.name}</p></p>

				</div>
				<div className='col statistics'>
					<p className="card-title m-0">{data.name}</p>
				</div>
				<div className='col statistics'>
					<p className="like date"><img src={CalendarBlue} alt='CalendarBlue' />{data.date}  ~ {data.time}</p>
				</div>
				<div className='col statistics'>
					{data.location.name ?
						<p className="like"><img src={Location} alt='Location' />   {data.location.name}- {data.location.address}</p>
						:
						<p className="like"><img src={Location} alt='Location' />   {data.location.address}</p>
					}
					<a className="like card-text directions" target={'_blank'} rel="noreferrer" href={data.location.google_map_link}>Get Directions</a>

				</div>
				{active && <div className='col m-0 statistics btns'>
					<button onClick={handleEdit} className="btn bookNow edit"><img src={EditIcon} alt='EditIcon' />Edit</button>
					<Popconfirm
						title="Delete the event"
						description="Are you sure to delete this event?"
						onConfirm={confirm}
						onCancel={cancel}
						okText="Yes"
						cancelText="No"
					>
						<button className="btn bookNow edit more-details"><img src={DeleteIcon} alt='deleteIcon' />Delete</button>
					</Popconfirm>
				</div>}
			</div>
		</div>
	);
};
export const MyEventCard = ({ data, activeEvent, handleDelete, handleEdit, eventDetailsNavigate }) => {
	const confirm = (e) => {
		console.log(e);
		handleDelete(data.id)
	};
	const cancel = (e) => {
		console.log(e);

	};
	return (
		<div className="cards">
			<div className="img-card pointer" onClick={eventDetailsNavigate}>
				{!data.image ?
					<div className='event-placeholder-img' style={{ height: '50%', width: '100%' }}></div> :
					<img src={data.image} alt="img-event" />
				}
			</div>
			<div className="card-body">
				<div className='col statistics'>
					{/* <p className='like'><img src={HeartWhite} alt='HeartRed' />20 Like</p>
					<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p> */}
					<p className='like'>{data.category && data.category.name}</p>
				</div>
				<div className='col statistics' onClick={eventDetailsNavigate}>
					<p className="card-title m-0 pointer">{data.name}</p>
				</div>
				<div className='col statistics'>
					<p className="like"><img src={CalendarBlue} alt='CalendarBlue' />{data.date}  ~ {data.time}</p>
				</div>
				<div className='col statistics'>
					{data.location.name ?
						<p className="like"><img src={Location} alt='Location' />   {data.location.name}- {data.location.address}</p>
						:
						<p className="like"><img src={Location} alt='Location' />   {data.location.address}</p>
					}
					<a className="like card-text directions" target={'_blank'} rel="noreferrer" href={data.location.google_map_link}>Get Directions</a>

				</div>
				<div className='col m-0 statistics btns'>
					{activeEvent && <button onClick={() => handleEdit(data)} className="btn bookNow edit"><img src={EditIcon} alt='EditIcon' />Edit</button>}
					<Popconfirm
						title="Delete the event"
						description="Are you sure to delete this event?"
						onConfirm={confirm}
						onCancel={cancel}
						okText="Yes"
						cancelText="No"
					>
						<button className="btn bookNow edit more-details" style={{ width: !activeEvent ? '100%' : '8.3rem' }}><img src={DeleteIcon} alt='deleteIcon' />Delete</button>
					</Popconfirm>
				</div>
			</div>
		</div>
	)
}

