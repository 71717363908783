export const validationRules = {
  mobileRegex: "^(\u0660\u0661|01)[0-2,5,\u0665,\u0660-\u0662]{1}[0-9,\u0660-\u0669]{8}",
  nameRegex: "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z -_]*$",
  mobileLength: 11,
  nameMinLength: 3,
  nameMaxLength: 50,
  passwordMinLength: 8,
  passwordMaxLength: 16,
  //eslint-disable-next-line
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
};
