// import UserImgEvent from '../../assets/img/userImgEvent.svg';
import FacebookSocial from '../../assets/img/facebookSocial.svg';
import UshersIcon from '../../assets/img/ushersIcon.svg';

import Twitter from '../../assets/img/twitterWhite.svg';
import InstagramSocial from '../../assets/img/instagramSocial.svg';
// import LinkedinSocial from '../../assets/img/linkedinSocial.svg';
import NoEvents from '../../assets/img/noEvents.svg';
import Plus from "../../assets/img/plusSmallWhite.svg";
import ActiveEvents from '../../assets/img/activeEvents.svg';
import PastEvents from '../../assets/img/pastEvents.svg';
import EditIcon from '../../assets/img/editIcon.svg';
import Star from '../../assets/img/star.svg';
import { OrganizationEventCard } from "../../components/ui/Card";
import DrawerUI from "../../components/ui/Drawer";
import { Overlay, UserMenu } from '../../components';
import { useEffect, useState } from 'react';
import { ServiceProxy } from '../../ServiceProxy';
import { StorageService } from '../../application/services';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { pageSize } from '../../config';
import { Inputs } from '../CreateEvent/components';
import { useFormik } from "formik";
import { t } from 'i18next';
import { CircularProgress } from "@mui/material";
import { addUsherValidationSchema } from '../../config';
import { withErrorHandler } from '../../components';
import UserPlaceholder from '../../assets/img/userPlaceholder.png';
import axios from 'axios';
import { message } from 'antd';
const OrganizationsPage = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const org = location.state?.org
	let { id } = useParams();
	const [toggle, setToggle] = useState(1)
	const [close, setClose] = useState(false)
	const [events, setEvents] = useState([])
	const [pastEvents, setPastEvents] = useState([])
	const [ushers, setUshers] = useState([])
	const [loading, setLoading] = useState(true)
	const [submit, setSubmit] = useState(false)
	const [messageApi, contextHolder] = message.useMessage();
	const success = () => {
		messageApi.open({
			type: 'success',
			content: 'Done Successfully',
		});
	};
	const eventsParams = {
		organization: id,
		page_size: pageSize,
		page: 1
	}
	const formik = useFormik({
		initialValues: {
			phone_number: '',
			last_name: '',
			first_name: ''
		},
		validationSchema: addUsherValidationSchema,
		onSubmit: (values) => {
			addUsher({ ...values, organization: id, first_name: values.first_name })

		},
	});
	const clickEditOrg = () => {
		navigate(`/edit-organization/${id}`, { state: { org } })
	}
	const addUsher = (values) => {
		setSubmit(true)
		ServiceProxy.UshersService.addUsher(values)
			.then(response => {
				if (response && response.data) {
					// setUshers(response.data.results)
					setSubmit(false)
					formik.resetForm()
					getOrganizationsUshers()
				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
	}
	const getOrgActiveEvents = () => {
		id && ServiceProxy.EventsService.getUserEvents({ ...eventsParams, is_active: true })
			.then(response => {
				if (response && response.data) {
					const result = response.data.results
					setEvents(result)
					setLoading(false)
				}
			})
			.catch(e => console.error({ e }))
	}
	// const getMoreActiveEvents = () => {
	// 	if (isNextEvents !== null) {
	// 		setEventsCurrentPage(eventsCurrentPage + 1);
	// 		getOrgActiveEvents({ ...eventsParams, page: eventsCurrentPage + 1, is_active: true })
	// 	}
	// }
	const getOrgPastEvents = () => {
		if (id) {
			setLoading(true)
			ServiceProxy.EventsService.getUserEvents({ ...eventsParams, is_active: false })
				.then(response => {
					if (response && response.data) {
						const result = response.data.results
						setPastEvents(result)
						setLoading(false)


					}
				})
				.catch(e => console.error({ e }))
		}
	}
	// const getMorePastEvents = () => {
	// 	if (isNextPastEvents !== null) {
	// 		setPastEventsCurrentPage(pastEventsCurrentPage + 1);
	// 		getOrgPastEvents({ ...eventsParams, page: pastEventsCurrentPage + 1, is_active: false })
	// 	}
	// }
	const getOrganizationsUshers = () => {
		setLoading(true)
		ServiceProxy.UshersService.getUshersList(eventsParams)
			.then(response => {
				if (response && response.data) {
					setUshers(response.data)
					setLoading(false)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	const handleDeleteEvent = (id) => {
		setLoading(true)
		ServiceProxy.EventsService.deleteEvent(id)
			.then(() => {
				setLoading(false)
				getOrgPastEvents()
				getOrgActiveEvents()
				success()
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})

	}
	useEffect(() => {
		getOrgActiveEvents()
		if (toggle === 2) {
			getOrgPastEvents()
		}
		if (toggle === 3) {
			getOrganizationsUshers()
		}
	}, [id]);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container-fluid">
			{contextHolder}
			<div className='row'>
				<DrawerUI className='drawer' close={close} setClose={setClose} style={{ width: close && '5rem' }}
					activeTab={2} activeItem={3} openList={2} activeId={id} />
				{loading || !org ? <Overlay /> :
					<div className={close ? "container event-organizer col width-colum" : 'width-colum container event-organizer col drawer-in-small'}>
						<div className='d-flex gap-2 navUser justify-content-end m-1'>
							<UserMenu logout={logout} />
						</div>
						<div>
							{org && org.cover_image ? <div className="img-event">
								<img src={org.cover_image} alt="cover" style={{ objectFit: 'cover' }} />
							</div> :
								<div className="img-event position-relative" />
							}
							<div className="all-events-organizer">
								<div className="organizer">
									<div className="img-organizer">
										<img src={org && org.logo ? org.logo : UserPlaceholder} alt="" />
									</div>
									<div className="name-organizer">
										<p className="name m-0">{org.name}</p>
										<p className="position d-flex m-0">Organization <img className="h-75 pb-0" src={Star} alt="Star" /></p>
									</div>
								</div>
								<ul className="tabs-events mr-2">
									<li className={toggle === 1 ? 'tab-events-show border-li' : 'tab-events p-0'} onClick={() => setToggle(1)}><img src={ActiveEvents} alt="activeEvents" /> Active Events</li>
									<li className={toggle === 2 ? 'tab-events-show border-li' : 'tab-events p-0'} onClick={() => {
										setToggle(2)
										getOrgPastEvents()
									}}><img src={PastEvents} alt="PastEvents" /> Past Events</li>
									<li className={toggle === 3 ? 'tab-events-show border-li' : 'tab-events p-0'} onClick={() => {
										setToggle(3)
										getOrganizationsUshers()
									}}><img src={UshersIcon} alt="PastEvents" /> Ushers</li>

								</ul>
							</div>
						</div>
						<div className="row m-2 mt-4 gap-3 viewContent">
							<div className="col-xl-3 col-lg-3 col-md-12">
								<div className="p-0 cardContent mt-1">
									<div className="content p-4">
										<div className="col">
											<p className="date m-0">About </p>
											<p className="desc pt-3 w-75">{org.description} </p>
											<button onClick={clickEditOrg} className="edit-organization"><img className="opacity-50" src={EditIcon} alt='EditIcon' />Edit organization</button>
										</div>
									</div>
								</div>
								{org.facebook_link || org.instagram_link || org.twitter_link ?
									<div className="p-0 cardContent mt-4">
										<div className="content p-4">
											<div className="col">
												<p className="date m-0">Social Links</p>
												<div className="location-event d-flex gap-3">
													{org.facebook_link && <a href={org.facebook_link} target='_blank' rel="noreferrer" ><img src={FacebookSocial} alt="FacebookSocial" /></a>}
													{org.instagram_link && <a href={org.instagram_link} target='_blank' rel="noreferrer" ><img src={InstagramSocial} alt="InstagramSocial" /></a>}
													{org.twitter_link && <a href={org.twitter_link} target='_blank' rel="noreferrer" ><img src={Twitter} alt="LinkedinSocial" /></a>}
												</div>
											</div>
										</div>
									</div>
									: null}
							</div>
							{toggle === 1 && events.length > 0 ?
								<div className="col">
									<div className="row gap-4 m-2 cards-center">
										{events.map(e => {
											return (
												<OrganizationEventCard data={e} active handleDelete={handleDeleteEvent} handleEdit={() => navigate(`/edit-event/${e.id}`, { state: { event: e } })} />
											)
										})}

									</div>
								</div>
								: toggle === 1 && events.length === 0 ?
									<div className="col cardTickets m-2">
										<div className="row">
											<div className="col">
												<img src={NoEvents} alt="NoEvents" />
												<p className="date">No Events to show</p>
											</div>
										</div>
									</div>

									: toggle === 2 && pastEvents.length > 0 ?
										<div className="col">
											<div className="row gap-4 m-2 cards-center">
												{pastEvents.map(e => {
													return (
														<OrganizationEventCard data={e} />

													)
												})}
											</div>
										</div>
										: toggle === 2 && events.length === 0 ?
											<div className="col cardTickets m-2">
												<div className="row">
													<div className="col">
														<img src={NoEvents} alt="NoEvents" />
														<p className="date">No Events to show</p>
													</div>
												</div>
											</div>
											:
											toggle === 3 && ushers.length > 0 ?
												<div className="ushers col">
													<div className="row mb-4">
														<div className="col-10">
															<h6 className="m-0">My Ushers</h6>
														</div>
													</div>
													<div className="the-table">
														<table className="table table-borderless">
															<thead className="position-relative">
																<th scope="col" className="usher-name">Name</th>
																{/* <th scope="col" className="usher-name usher-mail">Mail</th> */}
																<th scope="col" className="usher-name usher-number" style={{ left: '54%' }}>Number</th>
																{/* <th scope="col" className="assign-text">Assign to Scan Tickets</th> */}
															</thead>
															<tbody>
																{ushers.map((usher, index) => {
																	return (
																		<tr key={index}>
																			<td>{`${usher.first_name} ${usher.last_name}`}</td>
																			{/* <td>{usher.email}</td> */}
																			<td>{usher.phone_number}</td>
																			{/* <td className="d-flex justify-content-end"><RadioBtn name={usher.id} /></td> */}
																		</tr>
																	)
																})}
															</tbody>
														</table>
													</div>
													<form onSubmit={formik.handleSubmit} autoComplete="off" >
														<div className='row mt-2 mb-4 gap-3'>
															<div className="col col-12">
																<p className='create-subtitle'><img style={{ width: '1.25rem', height: '1.75rem' }} src={Plus} alt="Plus" />Add New Ushers</p>
															</div>
															<div className="col col-12 note">
																<p>When you add new ushers to the event they can<span> Start scan tickets </span>
																	at the entrance ,integrated with our<span> Mobile Application </span></p>
															</div>
															<div className='row mt-4' style={{ rowGap: '1.75rem' }}>
																<div className="col-lg-4 col-12">
																	<Inputs label={'First Name'} placeholder={'Ahmed'}
																		name="first_name"
																		onChange={formik.handleChange}
																		error={
																			formik.touched.first_name &&
																			Boolean(formik.errors.first_name)
																		}
																		helperText={
																			formik.touched.first_name && t(formik.errors.first_name)
																		}
																		value={formik.values.first_name} />
																</div>
																<div className="col-lg-4 col-12">
																	<Inputs label={'Last Name'} placeholder={'Ali'}
																		name="last_name"
																		onChange={formik.handleChange}
																		error={
																			formik.touched.last_name &&
																			Boolean(formik.errors.last_name)
																		}
																		helperText={
																			formik.touched.last_name && t(formik.errors.last_name)
																		}
																		value={formik.values.last_name} />
																</div>
																<div className="col-lg-4 col-12">
																	<Inputs label={'Mobile Number'} placeholder={'01123XXXXXX'}
																		name="phone_number"
																		onChange={formik.handleChange}
																		error={
																			formik.touched.phone_number &&
																			Boolean(formik.errors.phone_number)
																		}
																		helperText={
																			formik.touched.phone_number && t(formik.errors.phone_number)
																		}
																		value={formik.values.phone_number} />
																</div>
															</div>
														</div>
														<div className='row mt-4 gap-4'>
															{/* <div className="col d-flex">
																<RadioBtn style={{ width: '2rem', height: '1rem' }} />
																<span>Add as assigned to my upcoming events </span>
															</div> */}
															<div className="col">
																<button style={{ width: '5.5rem', minWidth: '5.5rem', height: '2.5rem', fontSize: '.875rem' }}
																	className='stepper-next m-0' type="submit"><img src={Plus} alt='RightIcon' />{submit ? <CircularProgress /> : "Add"}</button>
															</div>
														</div>
													</form>
												</div> :
												<div className="col cardTickets m-2">
													<div className="row">
														<div className="col">
															<img src={NoEvents} alt="NoEvents" />
															<p className="date">No Ushers to show</p>
														</div>
													</div>
												</div>
							}

						</div>
					</div>
				}
			</div>
		</div>
	)
}
export const Organizations = withErrorHandler(OrganizationsPage, axios)