import { Header } from "../Home/components";
import ticketSmallImg from '../../assets/img/ticketSmallImg.svg';
import AutoFill from '../../assets/img/autoFill.svg';
import BillingDollar from '../../assets/img/billingDollar.svg';
import Visa from '../../assets/img/visa.svg';
import ValuLogo from '../../assets/img/valuLogo.svg';
import AmanLogo from '../../assets/img/amanLogo.svg';
import Fawry from '../../assets/img/fawry.png'
import BmLogo from '../../assets/img/bmLogo.svg';
import EtisalatLogo from '../../assets/img/etisalatLogo.svg';
import VodafoneLogo from '../../assets/img/vodafoneLogo.svg';
import { Inputs } from "../CreateEvent/components";
import { Overlay } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ServiceProxy } from "../../ServiceProxy";
import { StorageService, storageKeys } from "../../application/services";
import { withErrorHandler } from "../../components";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { Iframe } from "../IFrame";
const CheckoutPage = () => {
	const { state } = useLocation()
	const eventData = state.eventData
	const navigate = useNavigate()
	const totalPrice = state.purchaseData.total_price
	const updatedEventTickets = state.updatedEventTickets
	const [tickets, setTickets] = useState([])
	const previewData = Object.values(state.purchaseData.user_quantities)
	const [user, setUser] = useState(null)
	const [ticketData, setTicketData] = useState(null)
	const [submit, setSubmit] = useState(false)
	const userID = StorageService.get(storageKeys.userID)
	const [showModal, setShowModal] = useState(false)
	const [iframeUrl, setIframeUrl] = useState(null)
	const [paymentType, setPaymentType] = useState('card')

	const getUserData = () => {
		ServiceProxy.UsersService.getUserData(userID)
			.then(response => {
				if (response && response.data) {
					setUser(response.data)
				}
			})
			.catch(e => console.error({ e }))
	}
	const sendPurchase = () => {
		setSubmit(true)
		let data = []
		if (eventData.is_ticket_for_each_user === false) {
			let dataTemp = {
				ticket_data: ticketData
			}
			for (let i = 0; i < Object.values(state.purchaseData.user_quantities).length; i++) {
				if (Object.values(state.purchaseData.user_quantities)[i].normal) {
					dataTemp = {
						...dataTemp,
						event_ticket_type_id: Number(Object.keys(state.purchaseData.user_quantities)[i]),
						normal_quantity: Object.values(state.purchaseData.user_quantities)[i].normal.quantity,
					}
					data.push(dataTemp)
				}
				if (Object.values(state.purchaseData.user_quantities)[i].earlybird) {
					dataTemp = {
						...dataTemp,
						event_ticket_type_id: Number(Object.keys(state.purchaseData.user_quantities)[i]),
						earlybird_quantity: Object.values(state.purchaseData.user_quantities)[i].earlybird.quantity,
					}
					data.push(dataTemp)
				}
				if (Object.values(state.purchaseData.user_quantities)[i].late) {
					dataTemp = {
						...dataTemp,
						event_ticket_type_id: Number(Object.keys(state.purchaseData.user_quantities)[i]),
						late_quantity: Object.values(state.purchaseData.user_quantities)[i].late.quantity,
					}
					data.push(dataTemp)
				}
			}
		}
		else {
			for (let i = 0; i < Object.values(state.purchaseData.user_quantities).length; i++) {
				if (Object.values(tickets)[i].normal) {
					const dataTemp = {
						event_ticket_type_id: Number(Object.keys(state.purchaseData.user_quantities)[i]),
						normal: Object.values(tickets)[i].normal.usersData,
					}
					data.push(dataTemp)
				}
				if (Object.values(tickets)[i].earlybird) {
					const dataTemp = {
						event_ticket_type_id: Number(Object.keys(state.purchaseData.user_quantities)[i]),
						earlybird: Object.values(tickets)[i].earlybird.usersData,
					}
					data.push(dataTemp)
				}
				if (Object.values(tickets)[i].late) {
					const dataTemp = {
						event_ticket_type_id: Number(Object.keys(state.purchaseData.user_quantities)[i]),
						late: Object.values(tickets)[i].late.usersData,
					}
					data.push(dataTemp)
				}
			}
		}

		ServiceProxy.PurchaseService.purchase({ tickets: data, payment_type: paymentType })
			.then(response => {

				setSubmit(false)
				if (response.data) {
					const result = response.data
					if (result.iframe) {
						setShowModal(true)
						setIframeUrl(result.iframe)
						// navigate('/buy-tickets', { state: { url: result.iframe }, replace: true })
					}
				}
			})
			.catch(e => {
				setSubmit(false)

				console.error({ e })
			})


	}
	const setTicketsObject = () => {
		var data = Object.values(state.purchaseData.user_quantities)
		for (let i = 0; i < data.length; i++) {
			if (Object.values(state.purchaseData.user_quantities)[i].normal) {
				let obj = Object.values(state.purchaseData.user_quantities)[i].normal
				obj = {
					...obj,
					usersData: Array.from(Array(obj.quantity).keys()).map(item => {
						return { ...item, name: '', email: '', phone_number: '' }
					}),
					ticketId: Object.keys(state.purchaseData.user_quantities)[i]
				}
				data[i].normal = obj
			}
			if (Object.values(state.purchaseData.user_quantities)[i].earlybird) {
				let obj = Object.values(state.purchaseData.user_quantities)[i].earlybird
				obj = {
					...obj,
					usersData: Array.from(Array(obj.quantity).keys()).map(item => {
						return { ...item, name: '', email: '', phone_number: '' }
					}),
					ticketId: Object.keys(state.purchaseData.user_quantities)[i]
				}
				data[i].earlybird = obj
			}
			if (Object.values(state.purchaseData.user_quantities)[i].late) {
				let obj = Object.values(state.purchaseData.user_quantities)[i].late
				obj = {
					...obj,
					usersData: Array.from(Array(obj.quantity).keys()).map(item => {
						return { ...item, name: '', email: '', phone_number: '' }
					}),
					ticketId: Object.keys(state.purchaseData.user_quantities)[i]
				}
				data[i].late = obj
			}
			setTickets(data)
		}
	}
	const handleFillWithMyData = () => {
		setTicketData({
			name: `${user.first_name} ${user.last_name}`,
			phone_number: user.phone_number,
			email: user.email
		})
	}
	const handleChangeName = (e) => {
		setTicketData({ ...ticketData, name: e.target.value })
	}
	const handleChangeEmail = (e) => {
		setTicketData({ ...ticketData, email: e.target.value })
	}
	const handleChangePhone = (e) => {
		setTicketData({ ...ticketData, phone_number: e.target.value })
	}
	const handleUsersNameChange = (idx, ticketidx, memindex, evt) => {
		const newTickets = tickets.map((ticket, ind) => {
			if (ticketidx !== ind) return ticket
			const newUsersData = Object.values(ticket)[memindex].usersData.map((item, index) => {
				if (idx !== index) return item
				return { ...item, name: evt.target.value }
			})
			const key = Object.keys(ticket)[memindex]
			const newValues = { [key]: { ...ticket[key], usersData: newUsersData } }
			return { ...ticket, ...newValues }
		})
		setTickets(newTickets)
	}
	const handleUsersPhoneChange = (idx, ticketidx, memindex, evt) => {
		const newTickets = tickets.map((ticket, ind) => {
			if (ticketidx !== ind) return ticket
			const newUsersData = Object.values(ticket)[memindex].usersData.map((item, index) => {
				if (idx !== index) return item
				return { ...item, phone_number: evt.target.value }
			})
			const key = Object.keys(ticket)[memindex]
			const newValues = { [key]: { ...ticket[key], usersData: newUsersData } }
			return { ...ticket, ...newValues }
		})
		setTickets(newTickets)
	}
	const handleUsersEmailChange = (idx, ticketidx, memindex, evt) => {
		const newTickets = tickets.map((ticket, ind) => {
			if (ticketidx !== ind) return ticket
			const newUsersData = Object.values(ticket)[memindex].usersData.map((item, index) => {
				if (idx !== index) return item
				return { ...item, email: evt.target.value }
			})
			const key = Object.keys(ticket)[memindex]
			const newValues = { [key]: { ...ticket[key], usersData: newUsersData } }
			return { ...ticket, ...newValues }
		})
		setTickets(newTickets)
	}
	const handleUsersDataAutoFill = (idx, ticketidx, memindex) => {
		const newTickets = tickets.map((ticket, ind) => {
			if (ticketidx !== ind) return ticket
			const newUsersData = Object.values(ticket)[memindex].usersData.map((item, index) => {
				if (idx !== index) return item
				return {
					...item,
					name: `${user.first_name} ${user.last_name}`,
					phone_number: user.phone_number,
					email: user.email
				}
			})
			const key = Object.keys(ticket)[memindex]
			const newValues = { [key]: { ...ticket[key], usersData: newUsersData } }
			return { ...ticket, ...newValues }
		})
		setTickets(newTickets)
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	useEffect(() => {
		getUserData()
		setTicketsObject()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container">
			<Header logout={logout} />
			{submit && <Overlay />}
			{!user ? <Overlay /> :
				<div className="row mt-4">
					<div className="col-lg-7 col-12">
						<div className="checkout p-0">
							<div className='features justify-content-start p-0 mt-0'>
								<div className='row'>
									<p className='title mb-2'>{eventData && eventData.category.name}</p>
									<p className='description' style={{ fontSize: '1.5rem' }}>{eventData.name} </p>
								</div>
							</div>
							{eventData.is_ticket_for_each_user ?
								<>
									<p className='create-subtitle gap-3 mt-3'><img src={ticketSmallImg} alt="Ticket" />Tickets Information</p>
									{tickets.map((ticket, index) =>
										Object.values(ticket).map((member, memindex) =>
											Object.values(ticket)[memindex].usersData && Object.values(ticket)[memindex].usersData.length > 0 ?
												Object.values(ticket)[memindex].usersData.map((item, ind) => {
													return (
														<div className="ticket-info row" key={ind}>
															<div className="row">
																<div className="d-flex gap-4 m-3 mt-0 mb-0 align-items-center">
																	<p className="ticket-num">Ticket {ind + 1}</p>
																	<p className="ticket-num ticket-type">{updatedEventTickets?.find(el => el.event_ticket_type_id === Number(Object.values(ticket)[memindex].ticketId))?.name}</p>
																</div>
																<div className="col-lg-6 col-12">
																	<Inputs label={'Name'} placeholder={'Name'}
																		onChange={(e) => handleUsersNameChange(ind, index, memindex, e)}
																		value={item.name} />
																</div>
																<div className="col-lg-6 col-12">
																	<Inputs label={'Email'} placeholder={'Email'}
																		onChange={(e) => handleUsersEmailChange(ind, index, memindex, e)}
																		value={item.email} />
																</div>
															</div>
															<div className="row">
																<div className="col-lg-6 col-12">
																	<Inputs label={'Mobile Number'} placeholder={'Number'}
																		onChange={(e) => handleUsersPhoneChange(ind, index, memindex, e)}
																		value={item.phone_number} />
																</div>
																<div className='col-lg-6 col-12 m-0 statistics btns justify-content-end'>
																	<button className="btn bookNow w-auto p-4 pb-0 pt-0" onClick={(e) => handleUsersDataAutoFill(ind, index, memindex)}>
																		<img src={AutoFill} alt='AutoFill' />Auto-Fill with my data</button>
																</div>
															</div>
														</div>
													)
												})
												: undefined
										))}
								</>
								:
								<div className="ticket-info row">
									<div className="row">
										<div className="d-flex gap-4 m-3 mt-0 mb-0 align-items-center">
											{/* <p className="ticket-num">Ticket 1</p>
										<p className="ticket-num ticket-type">Normal</p> */}
										</div>
										<div className="col-lg-6 col-12">
											<Inputs label={'Name'} onChange={handleChangeName} placeholder={'Name'} value={ticketData && ticketData.name} />
										</div>
										<div className="col-lg-6 col-12">
											<Inputs label={'Email'} onChange={handleChangeEmail} placeholder={'Email'} value={ticketData && ticketData.email} />
										</div>
									</div>
									<div className="row">
										<div className="col-lg-6 col-12">
											<Inputs label={'Mobile Number'} onChange={handleChangePhone} placeholder={'Number'} value={ticketData && ticketData.phone_number} />
										</div>
										<div className='col-lg-6 col-12 m-0 statistics btns justify-content-end'>
											<button className="btn bookNow w-auto p-4 pb-0 pt-0" onClick={handleFillWithMyData}><img src={AutoFill} alt='AutoFill' />Auto-Fill with my data</button>
										</div>
									</div>
								</div>
							}

							{/* <div className="ticket-info ticket-vip row">
							<div className="row">
								<div className="d-flex gap-4 m-3 mt-0 mb-0 align-items-center">
									<p className="ticket-num">Ticket 2</p>
									<p className="ticket-num ticket-type">VIP</p>
								</div>
								<div className="col-lg-6 col-12">
									<Inputs label={'Name'} placeholder={'Name'} />
								</div>
								<div className="col-lg-6 col-12">
									<Inputs label={'Email'} placeholder={'Email'} />
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 col-12">
									<Inputs label={'Mobile Number'} placeholder={'Number'} />
								</div>
								<div className='col-lg-6 col-12 m-0 statistics btns justify-content-end'>
									<button className="btn bookNow w-auto p-4 pb-0 pt-0"><img src={AutoFill} alt='AutoFill' />Auto-Fill with my data</button>
								</div>
							</div>
						</div>
						<div className="ticket-info ticket-super-vip row">
							<div className="row">
								<div className="d-flex gap-4 m-3 mt-0 mb-0 align-items-center">
									<p className="ticket-num ticket-num-three">Ticket 3</p>
									<p className="ticket-num ticket-type">Super VIP</p>
									<p className="ticket-late">Late</p>
								</div>
								<div className="col-lg-6 col-12">
									<Inputs label={'Name'} placeholder={'Name'} />
								</div>
								<div className="col-lg-6 col-12">
									<Inputs label={'Email'} placeholder={'Email'} />
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 col-12">
									<Inputs label={'Mobile Number'} placeholder={'Number'} />
								</div>
								<div className='col-lg-6 col-12 m-0 statistics btns justify-content-end'>
									<button className="btn bookNow w-auto p-4 pb-0 pt-0"><img src={AutoFill} alt='AutoFill' />Auto-Fill with my data</button>
								</div>
							</div>
						</div> */}
							<div className="row">
								<p className='create-subtitle gap-3 mt-3 mb-4 align-items-start'><img src={BillingDollar} alt="billing" />Billing Information</p>
								<div className="form-check m-5 mb-0 mt-0 d-flex align-items-center gap-3">
									<input className="form-check-input mb-1" type="radio" name={'payment_type'} value="card" onChange={(e) => setPaymentType(e.target.value)} checked={paymentType === 'card'} />
									<label className="form-check-label" >
										By Visa / Master card
									</label>
									<img className="m-3 mt-0 mb-0" src={Visa} alt='visa' />
								</div>
								<div className="form-check m-5 mb-0 mt-3 d-flex align-items-center gap-3">
									<input className="form-check-input mb-1" type="radio" name={'payment_type'} value="fawry" onChange={(e) => setPaymentType(e.target.value)} checked={paymentType === 'fawry'} />
									<label className="form-check-label" >
										Fawry
									</label>
									<img className="m-3 mt-0 mb-0" src={Fawry} alt='amanLogo' width={'89px'} />
								</div>
								<div className="form-check m-5 mb-0 mt-3 d-flex align-items-center gap-3">
									<input className="form-check-input mb-1" type="radio" name={'payment_type'} value="kiosk" onChange={(e) => setPaymentType(e.target.value)} checked={paymentType === 'kiosk'} disabled={Number(totalPrice) < 500} />
									<label className="form-check-label" >
										ValU
									</label>
									<img className="m-3 mt-0 mb-0" src={ValuLogo} alt='ValuLogo' />
								</div>

								{/* <div className="form-check m-5 mb-0 mt-3 d-flex align-items-center gap-3">
									<input className="form-check-input mb-1" type="radio" name={'ssss'} value="byDate" />
									<label className="form-check-label" >
										Mobile wallets
									</label>
									<div>
										<img src={BmLogo} alt='BmLogo' />
										<img src={EtisalatLogo} alt='etisalatLogo' />
										<img src={VodafoneLogo} alt='VodafoneLogo' />
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<div className="col">
						<div className="order">
							<p className='create-subtitle gap-3'><img src={ticketSmallImg} alt="Ticket" />Your Order</p>
							<div className="row mt-5 mb-3 opacity-75">
								<div className="col">
									<p className="head-text-order">Ticket Type</p>
								</div>
								<div className="col">
									<p className="head-text-order">Quantity</p>
								</div>
								<div className="col">
									<p className="head-text-order">Price</p>
								</div>
							</div>
							{tickets.map((ticket, index) =>
								Object.keys(ticket).map((item, ind) => {
									return (

										< div className="row align-items-baseline mb-3" >
											<div className="col">
												<p className="head-text-order">{updatedEventTickets?.find(el => el.event_ticket_type_id === Number(Object.values(ticket)[ind].ticketId))?.name}</p>
												{item === 'earlybird' ? <p className="early-order">Early bird</p> : item === 'late' ? <p className="early-order">Late</p> : null}
											</div>
											<div className="col d-flex justify-content-center gap-3">
												<p className="head-text-order">x {Object.values(ticket)[ind].quantity}</p>
												{/* <p className="remove-order">Remove</p> */}
											</div>
											<div className="col d-flex align-items-baseline gap-1">
												<p className="price-order">{Object.values(ticket)[ind].price}</p>
												<p className="egp-order">EGP</p>
											</div>
										</div>
									)
								}))}

							{/* <div className="row align-items-baseline mb-3">
							<div className="col">
								<p className="head-text-order">Normal</p>
							</div>
							<div className="col d-flex justify-content-center gap-3">
								<p className="head-text-order">x 1</p>
								<p className="remove-order">Remove</p>
							</div>
							<div className="col d-flex align-items-baseline gap-1">
								<p className="price-order">250</p>
								<p className="egp-order">EGP</p>
							</div>
						</div>
						<div className="row align-items-baseline mb-3">
							<div className="col">
								<p className="head-text-order">VIP</p>
							</div>
							<div className="col d-flex justify-content-center gap-3">
								<p className="head-text-order">x 1</p>
								<p className="remove-order">Remove</p>
							</div>
							<div className="col d-flex align-items-baseline gap-1">
								<p className="price-order">250</p>
								<p className="egp-order">EGP</p>
							</div>
						</div>
						<div className="row align-items-baseline mb-3">
							<div className="col">
								<p className="head-text-order">Super VIP</p>
								<p className="ticket-late">Late</p>
							</div>
							<div className="col d-flex justify-content-center gap-3">
								<p className="head-text-order">x 1</p>
								<p className="remove-order">Remove</p>
							</div>
							<div className="col d-flex align-items-baseline gap-1">
								<p className="price-order">250</p>
								<p className="egp-order">EGP</p>
							</div>
						</div> */}
							<hr />
							{/* <div className="row mb-4">
							<div className="col">
								<p style={{ fontSize: '1rem' }} className="head-text-order">Tickets</p>
							</div>
							<div className="col d-flex justify-content-end">
								<p style={{ fontSize: '1.25rem' }} className="price-order">3</p>
							</div>
						</div> */}
							<div className="row">
								<div className="col">
									<p style={{ fontSize: '1.25rem' }} className="head-text-order">Total price</p>
								</div>
								<div className="col d-flex align-items-baseline justify-content-end gap-1">
									<p style={{ fontSize: '1.5rem' }} className="price-order">{totalPrice}</p>
									<p className="egp-order">EGP</p>
								</div>
							</div>
							<div className="row">
								<div className="img-btn-order pointer" onClick={sendPurchase}>
									<p style={{ fontSize: '1.25rem' }} className="price-order">Place Order</p>
								</div>
							</div>
						</div>
					</div>
				</div>}
			<Modal
				show={showModal}
				onHide={() => {
					setShowModal(false)
					navigate('/home')
				}}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Checkout</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Iframe iframeUrl={iframeUrl} />
				</Modal.Body>
			</Modal>
		</div >
	)
}
export const Checkout = withErrorHandler(CheckoutPage, axios)

