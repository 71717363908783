import React from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Error } from '../../application';

export const UIModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("modal_error_message_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error message={props.message ? props.message : t("modal_error_message_body")}
          btnText={t("try_again_text")} onClick={() => window.location.reload()} />
      </Modal.Body>
    </Modal>
  )
}
export const InfoPopup = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>Check your mail to reset your password!</Modal.Body>
    </Modal>
  )
}