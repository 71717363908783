import { useEffect, useState } from 'react'
import { Header, EventsIntro, Events, Features, Footer } from './components';
import { useNavigate } from "react-router-dom";
import { ServiceProxy } from '../../ServiceProxy';
import { format } from 'date-fns';
import { Overlay } from '../../components';
import { StorageService, storageKeys } from '../../application/services';
import WebFont from 'webfontloader';

export function Home() {
	const navigate = useNavigate();
	const [cities, setCities] = useState([])
	const [categories, setCategories] = useState([])
	const [date, setDate] = useState(null)
	const [category, setCategory] = useState('');
	const [city, setCity] = useState('');
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true)
	const [events, setEvents] = useState([])
	const [isNextEvents, setIsNextEvents] = useState(null)
	const [eventsCurrentPage, setEventsCurrentPage] = useState(1)
	const userID = StorageService.get(storageKeys.userID)
	const eventsParams = {
		city,
		date: date ? format(new Date(date), 'yyyy-MM-dd') : '',
		search,
		category,
		page_size: 6,
		page: eventsCurrentPage,
		is_active: true
	}
	const getCities = () => {
		ServiceProxy.HelpersService.getCities()
			.then(response => {
				if (response && response.data) {
					setCities(response.data.results)
					getUser()
					getEvents(eventsParams)

				}
			})
			.catch(e => console.error({ e }))
	}
	const getCategories = () => {
		ServiceProxy.HelpersService.getCategories()
			.then(response => {
				if (response && response.data) {
					setCategories(response.data.results)
					getCities()
				}
			})
			.catch(e => console.error({ e }))
	}
	const getEvents = (params) => {
		ServiceProxy.EventsService.getEventsList(params)
			.then(response => {
				if (response && response.data) {
					setIsNextEvents(response.data.next ? true : null)
					const result = eventsCurrentPage === 1 ? response.data.results : [...events, ...response.data.results]
					setEvents(result)
					setLoading(false)
				}
			})
			.catch(e => console.error({ e }))
	}
	const getMoreEvents = () => {
		// if (isNextEvents !== null) {
		// 	setEventsCurrentPage(eventsCurrentPage + 1);
		// 	getEvents({ ...eventsParams, page: eventsCurrentPage + 1 })
		// }
		navigate('/events')
	}
	const getUser = () => {
		if (userID)
			ServiceProxy.UsersService.getUserData(userID)
				.then(response => {
					if (response && response.data) {
						StorageService.set(storageKeys.userName, `${response.data.first_name} ${response.data.last_name}`)
						StorageService.set(storageKeys.userEmail, response.data.email)
						StorageService.set(storageKeys.userPhone, response.data.phone_number)
						StorageService.set(storageKeys.userPhoto, response.data.photo)

					}
				})
				.catch(e => console.error({ e }))
	}
	const handleChangCategory = (value) => {
		setCategory(value);
		value ? handleSendSearch({ ...eventsParams, category: value }) : handleSendSearch({ ...eventsParams, category: '' })
	};
	const handleChangeCity = (value) => {
		setCity(value);
		value ? handleSendSearch({ ...eventsParams, city: value }) : handleSendSearch({ ...eventsParams, city: '' })
	};
	const handleChangeDate = (date, dateString) => {
		setDate(dateString)
		handleSendSearch({ ...eventsParams, date: dateString })
	}
	const handleChangeSearchInput = (e) => {
		setSearch(e.target.value)

	}
	const handleSendSearch = (params) => {
		// if (date || city || category || search) {
		setLoading(true)
		getEvents(params || eventsParams)
		// }
	}
	const loginNavigate = () => navigate('/login')
	const signupNavigate = () => navigate('/signup')
	const eventDetailsNavigate = (id, eventData) => navigate(`/event-details/${id}`, { state: { eventData } })
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	useEffect(() => {
		getCategories()
		WebFont.load({
			google: {
				families: ['Poppins']
			}
		});
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div>
			{loading ? null : <Header loginNavigate={loginNavigate} signupNavigate={signupNavigate} logout={logout} />}
			<EventsIntro />
			<Events cities={cities} categories={categories} date={date} handleChangCategory={handleChangCategory}
				handleChangeCity={handleChangeCity} handleChangeDate={handleChangeDate}
				city={city} category={category} search={search} handleChangeSearchInput={handleChangeSearchInput}
				handleSendSearch={handleSendSearch} getMoreEvents={getMoreEvents} isNext={isNextEvents} events={events}
				eventDetailsNavigate={eventDetailsNavigate} eventsParams={eventsParams} />
			<Features signupNavigate={signupNavigate} />
			<Footer />
			{loading && <Overlay />}
		</div>
	);
}
