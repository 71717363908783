import { Header } from "../Home/components"
// import ArrowDownList from "../../assets/img/arrowDownList.svg"
// import Building from "../../assets/img/building.svg"
import SearchBgWhite from "../../assets/img/searchBgWhite.svg"
import SearchBigblueIcon from "../../assets/img/searchBigblueIcon.svg"
import { Inputs } from "../CreateEvent/components"
// import { SelectUI } from "../../components/ui/Select"
import { DatePicker } from "antd"
import { useState, useEffect } from "react"
import { ServiceProxy } from "../../ServiceProxy"
import { MenuCollapsible, Overlay } from "../../components"
import { useNavigate } from "react-router-dom"
import { StorageService } from "../../application/services"
// import { format } from 'date-fns';
import { EventCardDark } from "../../components"
import ArrowDown from '../../assets/img/arrowDown.svg';

export const Events = () => {
    const navigate = useNavigate()
    const [cities, setCities] = useState([])
    const [categories, setCategories] = useState([])
    const [date, setDate] = useState(null)
    const [category, setCategory] = useState('');
    const [city, setCity] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([])
    const [isNextEvents, setIsNextEvents] = useState(null)
    const [eventsCurrentPage, setEventsCurrentPage] = useState(1)
    const eventsParams = {
        city,
        date,
        search,
        category,
        page_size: 6,
        page: eventsCurrentPage,
        is_active: true
    }
    const eventDetailsNavigate = (id, eventData) => navigate(`/event-details/${id}`, { state: { eventData } })

    const getCities = () => {
        ServiceProxy.HelpersService.getCities()
            .then(response => {
                if (response && response.data) {
                    const updatedCities = response.data.results.map(city => { return { ...city, label: city.name, key: city.id } })
                    setCities(updatedCities)
                    getEvents(eventsParams)
                }
            })
            .catch(e => {
                setLoading(false)
                console.error({ e })
            })
    }
    const getCategories = () => {
        ServiceProxy.HelpersService.getCategories()
            .then(response => {
                if (response && response.data) {
                    const updatedCategories = response.data.results.map(cate => { return { ...cate, label: cate.name, key: cate.id } })

                    setCategories(updatedCategories)
                    getCities()
                }
            })
            .catch(e => {
                setLoading(false)
                console.error({ e })
            })
    }
    const handleChangCategory = (value) => {
        setCategory(value);
        value ? handleSendSearch({ ...eventsParams, category: value }) : handleSendSearch({ ...eventsParams, category: '' })
    };
    const handleChangeCity = (value) => {
        setCity(value);
        value ? handleSendSearch({ ...eventsParams, city: value }) : handleSendSearch({ ...eventsParams, city: '' })
    };
    const handleChangeDate = (date, dateString) => {
        setDate(dateString)
        handleSendSearch({ ...eventsParams, date: dateString })
    }
    const handleChangeSearchInput = (e) => {
        setSearch(e.target.value)

    }
    const handleSendSearch = (params) => {
        getEvents(params || eventsParams)

    }
    const getEvents = (params) => {
        ServiceProxy.EventsService.getEventsList(params)
            .then(response => {
                if (response && response.data) {
                    setIsNextEvents(response.data.next ? true : null)
                    const result = params.page === 1 ? response.data.results : [...events, ...response.data.results]
                    setEvents(result)
                    setLoading(false)
                }
            })
            .catch(e => console.error({ e }))
    }
    const getMoreEvents = () => {
        if (isNextEvents !== null) {
            setEventsCurrentPage(eventsCurrentPage + 1);
            getEvents({ ...eventsParams, page: eventsCurrentPage + 1 })
        }

    }
    const logout = () => {
        StorageService.clear()
        navigate('/')
    }
    const loginNavigate = () => navigate('/login')
    const signupNavigate = () => navigate('/signup')
    useEffect(() => {
        getCategories()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="container-fluid">
            <Header logout={logout} loginNavigate={loginNavigate} signupNavigate={signupNavigate} />

            <div className="row mt-4">
                <div className="col-lg-2 col-md-3" style={{ paddingRight: 0 }}>
                    {/* <div className="mb-5 d-flex">
                        <img src={ArrowDownList} alt="arrowDownList" />
                        <SelectUI className='events-categories' placeholder={'Categories'}
                            data={categories}
                            handleChange={handleChangCategory} />
                    </div> */}
                    {/* <div className="mb-5 d-flex">
                        <img src={Building} alt="building" />
                        <SelectUI className='events-categories' placeholder={'City'}
                            data={cities}
                            handleChange={handleChangeCity} />
                    </div> */}
                    {/* <div className="mb-5 d-flex">
                        <DatePicker placeholder="Date" className="date-for-events" bordered={false} onChange={handleChangeDate} />
                    </div> */}
                    <div className="mb-5 d-flex">
                        <MenuCollapsible cities={cities} categories={categories} handleChangeCity={handleChangeCity} handleChangCategory={handleChangCategory} />
                    </div>

                </div>
                <div className="col">
                    <div className="row">
                        <div className="col-lg-6 col-12 d-flex">
                            <Inputs placeholder={'Search'} onChange={handleChangeSearchInput} value={search} />
                            <img style={{ height: '3rem', cursor: 'pointer' }} src={SearchBgWhite} alt="searchBgWhite" onClick={() => handleSendSearch({ ...eventsParams, search })} />
                        </div>
                        <div className="col-lg-6 col-12 d-flex justify-content-end">
                            <DatePicker placeholder="Date" className="date-for-events" bordered={false} onChange={handleChangeDate} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        {/* <p className="result-for">Result for <span>“Organization-X”</span></p> */}
                    </div>
                    {events.length > 0 &&
                        <div className="row align-items-center">
                            {events.map((event, index) => {
                                return <div className="col-lg-3 col-md-4 col-12 d-flex justify-content-center" key={index}>
                                    <EventCardDark data={event} eventDetailsNavigate={eventDetailsNavigate} />
                                </div>
                            })}
                        </div>}
                    {isNextEvents &&
                        <div className='d-flex justify-content-center m-3'>
                            <div className='load-more' onClick={getMoreEvents}>
                                <span>Load More</span>
                                <img className='arrowDown' src={ArrowDown} alt="arrow" />
                            </div>
                        </div>
                    }

                    {events.length === 0 && <div className="row mt-2">
                        <div className="col no-result">
                            <img src={SearchBigblueIcon} alt="SearchBigblueIcon" />
                            <p className="result-for">No result Results Found</p>
                        </div>
                    </div>}

                </div>
            </div>
            {loading && <Overlay />}
        </div>

    )
}