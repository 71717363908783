import Gallery from '../../assets/img/gallery.svg';
import SocialBlue from '../../assets/img/socialBlue.svg';
import Twitter from '../../assets/img/twitterWhite.svg';
import FacebookSocial from '../../assets/img/facebookSocial.svg';
import InstagramSocial from '../../assets/img/instagramSocial.svg';
import { Inputs, TextAreaUI, UploadFile } from '../CreateEvent/components/Inputs';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { Overlay, UserMenu } from '../../components';
import { useFormik } from "formik";
import { t } from 'i18next';
import { ServiceProxy } from '../../ServiceProxy';
import { StorageService } from '../../application/services';
import { organizationValidationSchema } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { withErrorHandler } from '../../components';
import axios from 'axios';
import DrawerUI from '../../components/ui/Drawer';

const EditOrganizationPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const org = location.state?.org
	let { id } = useParams();
	const [close, setClose] = useState(false)
	const [submit, setSubmit] = useState(false);
	const [profileFile, setProfileFile] = useState(null)
	const [coverFile, setCoverFile] = useState(null)
	const [profileBase64, setProfileBase64] = useState(null)
	const [coverBase64, setCoverBase64] = useState(null)
	const [logoRequired, setLogoRequired] = useState(false)
	const [orgData, setOrgData] = useState(null)
	const [coverRequired, setCoverRequired] = useState(false)
	const fileRef = useRef(null)
	const coverFileRef = useRef(null)

	const clickUploader = () => {
		fileRef.current.click();
	}
	const clickCoverUploader = () => {
		coverFileRef.current.click();
	}
	const handleChangeProfileFile = async (event) => {
		if (event.target.files.length > 0) {
			var file = event.target.files[0]

			var fileSize = file.size / 1024 / 1024
			var fileName = file.name
			var reg = /(\.png|\.jpg|\.jpeg)$/i

			if (fileSize > 5) {
				alert("File SSize exceeds 5MB")
			}
			else if (!reg.exec(fileName)) {
				alert("It does't support your extension !")
			}
			else {
				const base64 = await convertBase64(file)

				setProfileBase64(base64)
				setProfileFile(file)
			}
		}

	}
	const handleChangeCoverFile = async (event) => {
		if (event.target.files.length > 0) {
			var file = event.target.files[0]
			var fileSize = file.size / 1024 / 1024
			var fileName = file.name
			var reg = /(\.png|\.jpg|\.jpeg)$/i

			if (fileSize > 5) {
				alert("File SSize exceeds 5MB")
			}
			else if (!reg.exec(fileName)) {
				alert("It does't support your extension !")
			}
			else {
				const base64 = await convertBase64(file)

				setCoverBase64(base64)
				setCoverFile(file)
			}
		}

	}
	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}
	const formik = useFormik({
		initialValues: {
			name: orgData?.name,
			description: orgData?.description,
			facebook_link: orgData?.facebook_link,
			instagram_link: orgData?.instagram_link,
			twitter_link: orgData?.twitter_link
		},
		enableReinitialize: true,
		validationSchema: organizationValidationSchema,
		onSubmit: (values) => {
			let updatedValues = {
				...values,
				owner: orgData.owner
			}
			if (profileBase64) {
				updatedValues = {
					...updatedValues,
					logo: profileBase64
				}
			}
			if (coverBase64) {
				updatedValues = {
					...updatedValues,
					cover_image: coverBase64
				}
			}
			editOrg(updatedValues)

		},
	});
	const validateImages = () => {
		if (!profileFile) {
			setLogoRequired(true)
		}
		if (!coverFile) {
			setCoverRequired(true)
		}
	}
	const editOrg = (values) => {
		setSubmit(true)
		ServiceProxy.OrganizationsService.editOrganization(id, values)
			.then(response => {
				setSubmit(false)
				if (response && response.data) {

					navigate(`/my-organizations/${response.data.id}`, { state: { org: response.data } })
				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	useEffect(() => {
		org && setOrgData(org)
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container-fluid">
			<div className='row'>
				<DrawerUI className='drawer' close={close} setClose={setClose} style={{ width: close && '5rem' }}
					activeTab={2} activeItem={3} openList={2} activeId={id}
				/>
				{!orgData ? <Overlay /> :
					<div className={close ? 'col width-colum' : 'col drawer-in-small'}>
						<div className='container'>
							<div className='row'>
								<div className='col'>
									<div className="row">
										<div className='d-flex gap-2 navUser justify-content-end m-1'>
											<UserMenu logout={logout} />
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='create-title'>Edit Organization</p>
										</div>
										<div className='col mt-5 statistics justify-content-end'>
											<button className="p-3 bookNow" onClick={() => navigate(-1)}>Cancel</button>
										</div>
									</div>
									<form onSubmit={formik.handleSubmit} autoComplete="off" >
										<div className='row mt-2 mb-3'>
											<div className="col-lg-5 col-12">
												<Inputs label={'Organization Name'}
													placeholder={'Name your organization'}
													name="name"
													onChange={formik.handleChange}
													value={formik.values.name}
													error={
														formik.touched.name &&
														Boolean(formik.errors.name)
													}
													helperText={
														formik.touched.name && t(formik.errors.name)
													} />
											</div>
										</div>
										<div className='row mt-4'>
											<div className="col">
												<TextAreaUI label={'About Organization'}
													placeholder={'Tell the people about your community'}
													name="description"
													onChange={formik.handleChange}
													value={formik.values.description}
													error={
														formik.touched.description &&
														Boolean(formik.errors.description)
													}
													helperText={
														formik.touched.description && t(formik.errors.description)
													} />
											</div>
										</div>
										<div className='row mt-5'>
											<div className="col">
												<p className='create-subtitle'><img src={Gallery} alt="Gallery" />Organization Thumbnails</p>
												<div className='row mt-4'>
													<div className="col-12 col-lg-6 mt-4">
														<UploadFile label={'Organization Profile'} fileSize={'300 * 300 px ~ Max 5 MB'}
															onChange={handleChangeProfileFile}
															inputRef={fileRef} clickUploader={clickUploader}
															value={profileFile ? profileFile.name : orgData.logo ? orgData.logo.substring(orgData.logo.lastIndexOf('/') + 1) : ''}

															error={!profileBase64 && logoRequired && !orgData.logo}
															helperText={!profileBase64 && !orgData.logo && logoRequired ? "Required" : ""} />
													</div>
													<div className="col mt-4">
														<UploadFile label={'Cover Image'} fileSize={'1400 * 235 px ~ Max 5 MB'}
															onChange={handleChangeCoverFile} inputRef={coverFileRef} clickUploader={clickCoverUploader}
															// value={coverFile && coverFile.name}
															value={coverFile ? coverFile.name : orgData.cover_image ? orgData.cover_image.substring(orgData.cover_image.lastIndexOf('/') + 1) : ''}
															error={!coverBase64 && coverRequired && !orgData.cover_image}
															helperText={!coverBase64 && !orgData.cover_image && coverRequired ? "Required" : ""}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className='row mt-5'>
											<div className="col">
												<p className='create-subtitle'><img src={SocialBlue} alt="SocialBlue" />Social Links</p>
												<div className='row mt-3'>
													<div className="col-12 col-lg-5 social-link">
														<img src={FacebookSocial} alt='facebookSocial' />
														<Inputs label={''} placeholder={'Your Facebook Link'}
															name="facebook_link"
															onChange={formik.handleChange}
															value={formik.values.facebook_link}
															error={
																formik.touched.facebook_link &&
																Boolean(formik.errors.facebook_link)
															}
															helperText={
																formik.touched.facebook_link && t(formik.errors.facebook_link)
															} />
													</div>
												</div>
												<div className='row mt-3'>
													<div className="col-12 col-lg-5 social-link">
														<img src={InstagramSocial} alt='InstagramSocial' />
														<Inputs label={''} placeholder={'Your Instagram Link'}
															name="instagram_link"
															onChange={formik.handleChange}
															value={formik.values.instagram_link}
															error={
																formik.touched.instagram_link &&
																Boolean(formik.errors.instagram_link)
															}
															helperText={
																formik.touched.instagram_link && t(formik.errors.instagram_link)
															} />
													</div>
												</div>
												<div className='row mt-3'>
													<div className="col-12 col-lg-5 social-link">
														<img src={Twitter} alt='linkedinSocial' />
														<Inputs label={''} placeholder={'Your linkedin Link'}
															name="twitter_link"
															onChange={formik.handleChange}
															value={formik.values.twitter_link}
															error={
																formik.touched.twitter_link &&
																Boolean(formik.errors.twitter_link)
															}
															helperText={
																formik.touched.twitter_link && t(formik.errors.twitter_link)
															} />
													</div>
												</div>
											</div>
										</div>
										<div className='col mt-5 pt-3 statistics justify-content-end'>
											{/* <button className="bookNow p-3  more-details"><img src={EyePreview} alt='EyePreview' />Preview</button> */}
											<button type="submit" disabled={submit} className="bookNow p-3" onClick={validateImages}> {submit ? <CircularProgress /> : 'Edit'}</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	)
}
export const EditOrganization = withErrorHandler(EditOrganizationPage, axios)