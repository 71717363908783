import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "../globalization/locales/en.json";
import ar from "../globalization/locales/arab.json";
const options = {
  order: ['querystring', 'path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
  lookupQuerystring: 'lang',
}
export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: "ar",
    supportedLngs: ["en", "ar"],
    resources: {
      ar: {
        translation: ar
      },
      en: {
        translation: en
      },
    },
    react: {
      bindI18n: '',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
  })
export function changeCurrentLanguage(language) {
  window.location.reload()
  i18n.changeLanguage(language);
}
export function getCurrentLanguage() {
  return i18n.language;
}
