
export function Dropdowns({ label, data, onChange, name, helperText, error, value }) {
	return (
		<>
			<select className={error ? "form-select red-border" : "form-select"} name={name} value={value} onChange={onChange}>
				<option value="" hidden>Please select</option>
				{data.map(option => {
					return (
						<option key={option.id} value={option.id}>{option.name}</option>
					)
				})}
			</select>
			<span className="helper" style={{ color: 'red' }}>{helperText}</span>
		</>
	);
};

export function DropdownOutline({ label, data, onChange, name, attribute, error, helperText, value, disabled = false }) {
	return (
		<div className="form-group h-auto border-none" style={{ opacity: disabled && '0.3' }}>
			<label>{label}</label>
			<select className={error ? "form-select bg-transparent red-border" : "form-select bg-transparent"}
				name={name} open onChange={onChange} value={value} disabled={disabled}>
				<option value="" defaultChecked hidden style={{ color: 'red' }}>Please select</option>
				{data && data.map(option => {
					return (
						<option style={{ background: "#171717" }} key={option.id} value={option.id}>{attribute ? option[attribute] : option.name}</option>
					)
				})}
			</select>
			<span className="helper" style={{ color: 'red' }}>{helperText}</span>
		</div>
	);
}