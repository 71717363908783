export const Balls = ({ numOfBalls }) => {
    return (
        <div className='balls'>
            <div className='leftBall' />
            {Array.from(Array(numOfBalls).keys()).map((_, index) => {
                return (
                    <div className='ball' key={index} />
                )
            })}
            <div className='rightBall' />
        </div>
    );
};

