import { useState } from 'react';
import { Balls, Button, InfoPopup, Input, Text } from '../../components/ui';
import { useFormik } from "formik";
import { forgetPasswordValidationSchema } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { ServiceProxy } from '../../ServiceProxy'
import { t } from 'i18next';
import { withErrorHandler } from '../../components';
import axios from 'axios';
const ForgetPasswordPage = () => {
    const [submit, setSubmit] = useState(false);
    const [show, setShow] = useState(false)
    const formik = useFormik({
        initialValues: {
            user_identifier: '',
        },
        validationSchema: forgetPasswordValidationSchema,
        onSubmit: (values) => {
            handleResetPassword(values)
        },
    });
    const closePopup = () => {
        setShow(false)

    }
    const handleResetPassword = (data) => {
        setSubmit(true)
        ServiceProxy.AuthService.forgetPassword(data)
            .then(response => {
                setSubmit(false)
                if (response && response.status === 200) {

                    formik.resetForm()
                    setShow(true)
                }
            })
            .catch(e => {
                console.error({ e })
                setSubmit(false)
            })
    }
    return (
        <div className='container-fluid h-100'>
            <div className='row'>
                <div className='col xPay-logo' />
                <div className='col form-outer-card'>
                    <div className='form-wrapper'>
                        <Text className='head-text m-5' characters={t('forgot_password')} />
                        <form onSubmit={formik.handleSubmit} autoComplete="off" >
                            <div className='form-card container mb-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput mb-5'
                                            name='user_identifier'
                                            placeholder={'email or phone number'}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    formik.handleSubmit()
                                                }
                                            }}
                                            error={
                                                formik.touched.user_identifier &&
                                                Boolean(formik.errors.user_identifier)
                                            }
                                            helperText={
                                                formik.touched.user_identifier && t(formik.errors.user_identifier)
                                            }
                                        />
                                    </div>
                                </div>

                            </div>
                            <Balls numOfBalls={10} />
                            <Button className='btn-submit' type='submit' disabled={submit} title={submit ? <CircularProgress /> : t('send_link')} />
                        </form>
                        <div className='action-wrapper'>
                            <a href="/login">{t('sign_in')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <InfoPopup show={show} handleClose={closePopup} />
        </div>
    );
}
export const ForgetPassword = withErrorHandler(ForgetPasswordPage, axios)

