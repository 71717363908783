import { useState } from 'react';
import closedEye from '../../assets/img/closedEye.svg';
import closedEyeGray from '../../assets/img/closedEyeGray.svg';
import { Balls, Button, Input, Text } from '../../components/ui';
import { useFormik } from "formik";
import { loginValidationSchema, validationRules } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { ServiceProxy } from '../../ServiceProxy'
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { withErrorHandler } from '../../components';
import axios from 'axios';
const LoginPage = () => {
    const navigate = useNavigate()
    const [passwordShown, setPasswordShown] = useState(false);
    const [submit, setSubmit] = useState(false);
    const formik = useFormik({
        initialValues: {
            phone_number: '',
            password: ''
        },
        validationSchema: loginValidationSchema,
        onSubmit: (values) => {
            if (values.phone_number.match(/.+@.+/)) {

                let newValues = { ...values, email: values.phone_number }
                delete newValues.phone_number

                handleLogin(newValues)
            }
            else if (values.phone_number.match(/\d/)) {
                handleLogin(values)

            }
            else {
                let newValues = { ...values, ussername: values.phone_number }
                delete newValues.phone_number

                handleLogin(newValues)
            }
        },
    });
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const handleLogin = (values) => {
        setSubmit(true)
        const data = values.phone_number ? { ...values, phone_number: `+2${values.phone_number}` } : values
        ServiceProxy.AuthService.login(data)
            .then(response => {
                setSubmit(false)
                if (response && response.data) {
                    navigate('/verify', { state: { token: response.data.token } })
                }
            })
            .catch(e => {
                console.error({ e })
                setSubmit(false)
            })
    }

    return (
        <div className='container-fluid h-100'>
            <div className='row'>
                <div className='col xPay-logo' />
                <div className='col form-outer-card'>
                    <div className='form-wrapper'>
                        <Text className='welcome-txt' characters={t('welcome_back')} />
                        <Text className='head-text mb-5' characters={t('login')} />
                        <form onSubmit={formik.handleSubmit} autoComplete="off" >
                            <div className='form-card container'>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput'
                                            name='phone_number'
                                            placeholder={t('email_or_phone')}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    formik.handleSubmit()
                                                }
                                            }}
                                            error={
                                                formik.touched.phone_number &&
                                                Boolean(formik.errors.phone_number)
                                            }
                                            helperText={
                                                formik.touched.phone_number && t(formik.errors.phone_number, { length: validationRules.mobileLength })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='passwordInput'>
                                            <Input
                                                type={passwordShown ? 'text' : 'password'}
                                                icon={passwordShown ? closedEye : closedEyeGray}
                                                iconClassName={'input-icon'}
                                                className='userNameInput'
                                                name='password'
                                                placeholder={t('password')}
                                                onChange={formik.handleChange}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        formik.handleSubmit()
                                                    }
                                                }}
                                                error={
                                                    formik.touched.password &&
                                                    Boolean(formik.errors.password)
                                                }
                                                helperText={
                                                    formik.touched.password && t(formik.errors.password)
                                                }
                                                inputProps={{
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                }}
                                                onClickIcon={togglePassword}
                                                maxLength={16}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {/* <div className='col-md-6 col-12'>
                                        <Checkbox label={t('remember_me')} className={'check-box-wrapper'} />
                                    </div> */}
                                    <div className='col'> <p className='forget-password-text'><a className='text-white' href="/forget-password">{t('ask_forgot_password')}</a></p></div>
                                </div>
                            </div>
                            <Balls numOfBalls={10} />

                            <Button type='submit' disabled={submit} title={submit ? <CircularProgress /> : t('login')} className={'btn-submit'} />

                        </form>
                        <div className='action-wrapper'>
                            <p>{t('not_have_account')}<a href="/signup">{t('sign_up')}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const Login = withErrorHandler(LoginPage, axios)