
import axios from "axios";
export class HelpersService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}`
    }
    getCategories() {
        return axios.get(`${this._baseUrl}/categories/list/`);
    }
    getCities() {
        return axios.get(`${this._baseUrl}/cities/list/`);
    }
}