import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import SettingsIcon from '../../../assets/img/settingsIcon.svg';
import { DatePicker, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
	// Can not select days before today and today
	return current && current < dayjs().startOf('day');
};

export default function DateAndTime() {
	return (
		<div className='date-and-time'>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={['DatePicker']}>
					<DatePicker label="Basic date picker"
						slotProps={{
							textField: {
								borderColor: '#fff'
							},
						}}
						sx={{
							width: "100%",
							"& .MuiInputLabel-root.Mui-focused": { color: "#979797" },
							"& .MuiOutlinedInput-root": {
								"&:hover > fieldset": { borderColor: "#fff" },
								borderRadius: "6px",
								color: '#fff',
								borderColor: '#fff'
							},
							"& .css-4jnixx-MuiStack-root": {
								overflow: 'hidden'
							},
							"& .MuiOutlinedInput-root": {
								color: '#fff',
								borderColor: '#fff',
								overflow: 'hidden'
							},
							"& fieldset": {
								color: '#fff',
								borderColor: '#fff',
							},
							"& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
								paddingRight: '14px'
							},
						}}
					/>
				</DemoContainer>
			</LocalizationProvider>
		</div>
	);
}

export const DateExapmle = ({ label, icon, placeholder, onChange, name, value, helperText, error }) => {

	return (
		<div className={error ? "form-group red-border" : "form-group"}>
			<label>{label}</label>
			<DatePicker
				disabledDate={disabledDate}
				className='w-100 h-100 bg-transparent' name={name}
				placeholder={placeholder} onChange={onChange} suffixIcon={icon}
				defaultValue={value && dayjs(value, 'YYYY-MM-DD')}
			/>
			<span className="helper" style={{ color: 'red' }}>{helperText}</span>
		</div>
	);
};
export const TimeExapmle = ({ label, icon, placeholder, onChange, name, value, helperText, error }) => {
	return (
		<div className={error ? "form-group red-border" : "form-group"}>
			<label>{label}</label>
			<TimePicker format={'HH:mm'}
				className='w-100 h-100 bg-transparent' name={name} placeholder={placeholder} suffixIcon={icon} onChange={onChange} defaultValue={value && dayjs(value, 'HH:mm:ss')} />
			<span className="helper" style={{ color: 'red' }}>{helperText}</span>
		</div>
	);
};