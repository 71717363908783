import {
    AuthService, HelpersService, EventsService, StorageService, UsersService, OrganizationsService, EventTicketsService, LocationsService, UshersService,
    PurchaseService, TicketsService
} from "./services";

export class ServiceProxy {
    AuthService;
    HelpersService;
    EventsService;
    StorageService;
    UsersService;
    OrganizationsService;
    EventTicketsService;
    LocationsService;
    UshersService;
    PurchaseService;
    TicketsService
    constructor(_apiUrl) {
        this._apiUrl = _apiUrl
        this.AuthService = new AuthService(this._apiUrl);
        this.HelpersService = new HelpersService(this._apiUrl);
        this.EventsService = new EventsService(this._apiUrl);
        this.StorageService = new StorageService(this._apiUrl);
        this.UsersService = new UsersService(this._apiUrl);
        this.OrganizationsService = new OrganizationsService(this._apiUrl);
        this.EventTicketsService = new EventTicketsService(this._apiUrl);
        this.LocationsService = new LocationsService(this._apiUrl);
        this.UshersService = new UshersService(this._apiUrl);
        this.PurchaseService = new PurchaseService(this._apiUrl);
        this.TicketsService = new TicketsService(this._apiUrl)



    }

}
