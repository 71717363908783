import { Button } from "../../../components/ui";
import uploadFile from "../../../assets/img/uploadFile.svg";

export function Inputs({ label, placeholder, icon, onChange, value, name, helperText, error, disabled = false }) {
	return (
		<div className={error ? "form-group red-border" : "form-group"} style={{ opacity: disabled && '0.3' }}>
			<label>{icon}{label}</label>
			<input type="text" className="form-control input-lg" name={name} placeholder={placeholder}
				value={value} onChange={onChange} disabled={disabled} />
			<span className="helper" style={{ color: 'red' }}>{helperText}</span>
		</div>
	);
}

export function TextAreaUI({ label, placeholder, name, value, onChange, helperText, error }) {
	return (
		<div className={"form-group h-auto border-none"}>
			<label>{label}</label>
			<textarea rows="4" cols="50"
				className={error ? "form-control input-lg textarea red-border" : "form-control input-lg textarea"}
				placeholder={placeholder}
				name={name}
				onChange={onChange}
				value={value}
			>
			</textarea>
			<span className="helper">{helperText}</span>
		</div>
	);
}

export function CheckBoxUI({ label, checked, onChange, name }) {
	return (
		<div className="form-check-box">
			<label className="form-checkbox">
				<input type="checkbox" name={name} checked={checked}
					onChange={onChange} />
				{label}
			</label>
		</div>
	);
}
export function UploadFile({ label, fileSize, onChange, value, inputRef, clickUploader, error, helperText }) {
	const displayFileName = (value) => {
		if (value.length <= 20) return value
		return value.slice(-20)
	}
	return (
		<>
			<div className={error ? "form-group form-group-uploadfile position-relative red-border" : "form-group form-group-uploadfile position-relative"} onClick={clickUploader}>
				<label>{label}</label>
				<input type="file" ref={inputRef} style={{ display: 'none' }} className="form-control input-file opacity-0 " accept="image/" onChange={onChange} />
				{value && displayFileName(value)}<Button className='uploud-file-btn' type="button" title={'upload'} icon={<img src={uploadFile} alt="uploadFile" />} />
			</div>
			<span className="helper">{helperText}</span>
			<p className="pt-1 m-0">{fileSize}</p>
		</>
	);

}
export function RadioBtn({ style, onChange, name, value }) {
	return (
		<div className="form-switch">
			<input style={style} name={name} className="form-check-input" type="checkbox"
				onChange={onChange} checked={value} />
		</div>
	);
}