import { TextField } from '@mui/material';
export const Input = ({ name, value, type = 'text', placeholder, className, icon, iconClassName, onChange, error,
    helperText, onClickIcon, maxLength = 30, ...props }) => {
    return (
        <>
            <TextField autoComplete='off' fullWidth name={name} className={className} variant='outlined' type={type} placeholder={placeholder} onChange={onChange} value={value} error={error}
                helperText={helperText} {...props}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: "white" },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                    },
                }}
                InputProps={{
                    sx: {
                        '&:focus-within fieldset, &:focus-visible &:focus fieldset': {
                            border: '2px solid white!important',
                        },
                    },

                }}
                inputProps={{ ...props.inputProps, maxLength: maxLength }}
                InputLabelProps={{
                    sx: {
                        color: 'white!important',
                    },
                }}
            />
            {icon && <img onClick={onClickIcon} className={iconClassName} src={icon} alt={`${icon}`} />}
        </>
    );
};

