import React from 'react';
import { Select } from 'antd';

export const SelectUI = ({ Icon, className, placeholder, data, handleChange }) => (
  <Select
    placeholder={placeholder}
    bordered={false}
    suffixIcon={Icon}
    className={className}
    allowClear
    onChange={handleChange}
    options={data && data.map(e => ({ label: e.name, value: e.id }))}
  />
);  