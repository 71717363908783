import LogoutIcon from '../../../assets/img/logoutIcon.svg';
import { Link } from 'react-router-dom';
import { StorageService, storageKeys } from '../../../application/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faUser } from '@fortawesome/free-regular-svg-icons';

export const UserMenu = ({ logout }) => {
    const userName = StorageService.get(storageKeys.userName)
    const userPhoto = StorageService.get(storageKeys.userPhoto)

    return (
        <div className='d-flex gap-2 navUser'>
            {userPhoto && <img src={userPhoto} alt='userImg' style={{ width: '41px', height: '41px', borderRadius: '100%' }} />}
            <div className="d-flex align-items-center nav-item dropdown p-0">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {userName}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li className='p-0'>
                        <Link className="dropdown-item align-items-baseline" to="/my-events"><FontAwesomeIcon icon={faCalendarDays} style={{ color: "#E7E7E8", marginRight: '0.5rem' }} />Manage Events</Link>
                    </li>
                    <hr align="center" width="70%" color='#ffffff26' className='m-auto opacity-100' />
                    <li className='p-0'><Link className="dropdown-item" to="/settings"><FontAwesomeIcon icon={faUser} style={{ color: "#E7E7E8", marginRight: '0.5rem' }} />Profile</Link></li>
                    <hr align="center" width="70%" color='#ffffff26' className='m-auto opacity-100' />
                    <li className="p-0" onClick={logout}>
                        <div className="dropdown-item text-white">
                            <img style={{ marginRight: '0.5rem', marginLeft: 0 }} src={LogoutIcon} alt='LogoutIcon' />Logout</div>
                    </li>
                </ul>






            </div>
        </div>
    )
}