

import { useState, useEffect } from 'react';
import { Overlay, UserMenu } from '../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import { StorageService } from '../../application/services';
import RightIcon from '../../assets/img/rightIcon.svg';
import { ServiceProxy } from '../../ServiceProxy';
import { pageSize } from '../../config';
import ArrowDown from '../../assets/img/arrowDown.svg';

export const Payments = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	const [loading, setLoading] = useState(true)
	const [purchasesData, setPurchasesData] = useState([])
	const [isNextData, setIsNextData] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)

	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	const params = {
		page: 1,
		page_size: pageSize,
		event: state.id
	}
	const getPurchases = (params) => {
		setLoading(true)

		ServiceProxy.PurchaseService.listPurchases(params)
			.then(response => {
				if (response && response.data) {
					setLoading(false)
					setPurchasesData([...purchasesData, ...response.data.results])
				}
			})
			.catch(e => console.error({ e }))
	}
	const getMoreData = () => {
		if (isNextData !== null) {
			setCurrentPage(currentPage + 1);
			getPurchases({ ...params, page: currentPage + 1 })
		}

	}
	useEffect(() => {
		setPurchasesData(state.purchases.results)
		setIsNextData(state.purchases.next)
		setLoading(false)
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container-fluid">
			{loading ? <Overlay /> :
				<div className='row'>
					<div className={'col drawer-in-small'}>
						<div className='container'>
							<div className='row'>
								<div className='col'>
									<div className="row">
										<div className='d-flex gap-2 navUser justify-content-end m-1'>
											<UserMenu logout={logout} />
										</div>
									</div>
									<div className="col">
										<p className='create-title'>Payments</p>
									</div>
									<div className='d-flex  navUser justify-content-end '>
										<button className='stepper-next stepper-back m-0' onClick={() => navigate(`/event-details/${state.id}`)} ><img src={RightIcon} alt='RightIcon' /> Back</button>
									</div>
									{purchasesData.length > 0 ?
										<div className="the-table mt-4">
											<table className="table table-borderless">
												<thead className="position-relative">
													<th scope="col" className="t-head" >Payment ID</th>
													<th scope="col" className="t-head" >Payment Method</th>
													<th scope="col" className="t-head" >Payment Status</th>
													<th scope="col" className="t-head" >Price</th>
													<th scope="col" className="t-head" >User Name</th>
													<th scope="col" className="t-head" >Phone</th>


												</thead>
												<tbody>
													{purchasesData.map((data, index) => {
														return (
															<tr key={index}>

																<td>{data.purchase_uuid}</td>
																<td >{data.payment_method}</td>
																<td>{data.payment_status}</td>
																<td>{data.price}</td>
																<td>{data.user?.first_name}{data.user?.last_name}</td>
																<td>{data.user?.phone_number}</td>


															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
										: <div className='col statistics justify-content-center mt-3'>
											<div className='row justify-content-center'>
												<p className='create-subtitle justify-content-center mb-4'>No payments to show</p>
											</div>
										</div>}
									{isNextData &&
										<div className='d-flex justify-content-center m-3'>
											<div className='load-more' onClick={getMoreData}>
												<span>Load More</span>
												<img className='arrowDown' src={ArrowDown} alt="arrow" />
											</div>
										</div>
									}

								</div>
							</div>
						</div>
					</div>
				</div>}

		</div>
	)
}