
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";
export class EventsService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/events`
    }
    getEventsList(params) {
        return axios.get(`${this._baseUrl}/list/`, { params });
    }
    getEventData(id) {
        return axios.get(`${this._baseUrl}/${id}`);
    }
    getUserEvents(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/users/list/`, { params });
        removeDefaultHeaders(axios)
        return request;
    }
    addEvent(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
    deleteEvent(id) {
        addAxiosDefaultHeaders(axios)
        const request = axios.delete(`${this._baseUrl}/${id}`);
        removeDefaultHeaders(axios)
        return request;
    }
    editEvent(id, values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.patch(`${this._baseUrl}/${id}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
}