
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class EventTicketsService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/event_ticket_types`
    }
    getEventTicketsList(params) {
        return axios.get(`${this._baseUrl}/list/`, { params });
    }
    getEventTicketData(id) {
        return axios.get(`${this._baseUrl}/${id}`);
    }
    addEventTicket(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
    editEventTicket(id, values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.patch(`${this._baseUrl}/${id}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
    getEditEventTicketsList(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/edit/list`, { params });
        removeDefaultHeaders(axios)
        return request;
    }

}