import { DateExapmle, TimeExapmle } from "./DateAndTime";
import { DropdownOutline, Dropdowns } from "./Dropdowns";
import { CheckBoxUI, Inputs, TextAreaUI, UploadFile } from "./Inputs";
import LocationBlue from "../../../assets/img/locationBlue.svg";
import Gallery from "../../../assets/img/gallery.svg";
import PhoneIcon from "../../../assets/img/phoneIcon.svg";
import CalendarSmallWhite from "../../../assets/img/calendarSmallWhite.svg";
import { useState, useEffect, useRef } from "react";
import { ServiceProxy } from "../../../ServiceProxy";
import { createEventValidationSchema, pageSize, validationRules } from "../../../config";
import RightIcon from '../../../assets/img/rightIcon.svg';

//import { DatePickerUI } from "../../../components";
import { useFormik } from "formik";
import { t } from 'i18next';

import { Overlay } from "../../../components/ui";
import { CircularProgress } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
export const DetailsEventUpdate = ({ clickNext, activeStep, steps, submit, setSubmit }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [close, setClose] = useState(false)
	const event = location.state?.event
	let { id } = useParams();
	const [coverFile, setCoverFile] = useState(null)
	const [coverBase64, setCoverBase64] = useState(null)
	const fileRef = useRef(null)
	const coverFileRef = useRef(null)
	const [loading, setLoading] = useState(true)
	const [orgs, setOrgs] = useState([])
	const [categories, setCategories] = useState([])
	const [locations, setLocations] = useState([])
	const [cities, setCities] = useState([])
	const [date, setDate] = useState(event?.date)
	const [time, setTime] = useState(event?.time)
	const [eventImgFile, setEventImgFile] = useState(null)
	const [ticketForEachUser, setTicketForEachUser] = useState(event ? event.is_ticket_for_each_user : false)
	const [eventImgBase64, setEventImgBase64] = useState(null)
	const imgFileRef = useRef(null)
	const params = {
		page: 1,
		page_size: pageSize
	}
	const getOrganizations = () => {
		ServiceProxy.OrganizationsService.getUserOrganizations(params)
			.then(response => {
				if (response && response.data) {
					getCategories()
					setOrgs(response.data.results)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const getCategories = () => {
		ServiceProxy.HelpersService.getCategories()
			.then(response => {
				if (response && response.data) {
					setCategories(response.data.results)
					getLocations()
					getCities()
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const getLocations = () => {
		ServiceProxy.LocationsService.getLocationsList(params)
			.then(response => {
				if (response && response.data) {
					setLocations(response.data.results)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const getCities = () => {
		ServiceProxy.HelpersService.getCities()
			.then(response => {
				if (response && response.data) {
					setLoading(false)
					setCities(response.data.results)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const clickUploader = () => {
		imgFileRef.current.click();
	}
	const clickCoverUploader = () => {
		coverFileRef.current.click();
	}
	const handleChangeImageFile = async (event) => {
		if (event.target.files.length > 0) {
			var file = event.target.files[0]

			var fileSize = file.size / 1024 / 1024
			var fileName = file.name
			var reg = /(\.png|\.jpg|\.jpeg)$/i

			if (fileSize > 5) {
				alert("File SSize exceeds 5MB")
			}
			else if (!reg.exec(fileName)) {
				alert("It does't support your extension !")
			}
			else {
				const base64 = await convertBase64(file)

				setEventImgBase64(base64)
				setEventImgFile(file)
			}
		}

	}
	const handleChangeCoverFile = async (event) => {
		if (event.target.files.length > 0) {
			var file = event.target.files[0]
			var fileSize = file.size / 1024 / 1024
			var fileName = file.name
			var reg = /(\.png|\.jpg|\.jpeg)$/i

			if (fileSize > 5) {
				alert("File SSize exceeds 5MB")
			}
			else if (!reg.exec(fileName)) {
				alert("It does't support your extension !")
			}
			else {
				const base64 = await convertBase64(file)

				setCoverBase64(base64)
				setCoverFile(file)
			}
		}

	}
	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result);
			}
			fileReader.onerror = (error) => {
				reject(error);
			}
		})
	}
	const addLocation = (values) => {
		setSubmit(true)
		ServiceProxy.LocationsService.addLocation(values)
			.then(response => {
				setSubmit(false)
				if (response && response.data) {
					formik.setFieldValue('location', response.data.id)
					clickNext({ ...formik.values, location: response.data.id })
				}
			})
			.catch(e => {
				setSubmit(false)
				console.error({ e })
			})
	}
	const formik = useFormik({
		initialValues: {
			name: event?.name,
			description: event?.description,
			phone_number: event?.phone_number,
			organization: event?.organization?.id,
			category: event?.category?.id,
			city: event?.city?.id,
			location: event?.location?.id,
			is_ticket_for_each_user: event?.is_ticket_for_each_user,
			locations: true,
			address: '',
			google_map_link: '',
			date: event?.date,
			time: event?.time
		},
		validationSchema: createEventValidationSchema,
		onSubmit: (values) => {
			let updatedValues = {
				...values,
				organization: values.organization ? Number(values.organization) : '',
				category: Number(values.category),
				is_ticket_for_each_user: ticketForEachUser
			}
			if (eventImgBase64) {
				updatedValues = { ...updatedValues, image: eventImgBase64 }
			}
			if (coverBase64) {
				updatedValues = { ...updatedValues, cover_image: coverBase64 }
			}
			if (!values.location && values.address && values.city && values.google_map_link) {
				addLocation({ address: values.address, city: values.city, google_map_link: values.google_map_link })
			}

			else {
				clickNext(updatedValues)
			}
		},
	});
	const onChangeDate = (date, dateString) => {
		setDate(dateString)
		formik.setFieldValue('date', dateString)
	};
	const onChangeTime = (time, timeString) => {
		setTime(timeString)
		formik.setFieldValue('time', timeString)
	}
	useEffect(() => {
		getOrganizations()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container details-event">
			{loading ? <Overlay /> :
				<form onSubmit={formik.handleSubmit} autoComplete="off" >

					<div className='row mt-2 mb-4 gap-3'>
						<div className="col-lg-6 col-12">
							<p className="m-0">Organization</p>
							<Dropdowns label={'Organization Name'} placeholder={'Name your organization'}
								data={orgs}
								onChange={formik.handleChange}
								name="organization"
								error={
									formik.touched.organization &&
									Boolean(formik.errors.organization)
								}
								helperText={
									formik.touched.organization && t(formik.errors.organization)
								}
								value={formik.values.organization} />
						</div>
						{/* <div className="col-lg-6 col-12 note">
						<p>Note : If you create event with your<span> organization profile </span>
							you will be able to assign other admins to manage your event</p>
					</div> */}
					</div>
					<div className='row mt-4'>
						<div className="col mt-2">
							<Inputs label={'Event Name'} placeholder={'Event Name'}
								name="name"
								onChange={formik.handleChange}
								error={
									formik.touched.name &&
									Boolean(formik.errors.name)
								}
								helperText={
									formik.touched.name && t(formik.errors.name)
								}
								value={formik.values.name} />
						</div>
					</div>
					<div className='row mt-4 gap-4'>
						<div className="col mt-2">
							<DateExapmle label={'Date'}
								placeholder={'DD/MM/YYYY'}
								icon={<img src={CalendarSmallWhite} alt="CalendarSmallWhite" />}
								name="date"
								onChange={onChangeDate}
								value={formik.values.date}
								error={
									formik.touched.date &&
									Boolean(formik.errors.date)
								}
								helperText={
									formik.touched.date && t(formik.errors.date)
								} />
						</div>
						<div className="col mt-2">
							<TimeExapmle label={'Time'}
								placeholder={'00:00 AM/PM'}
								icon={<img src={CalendarSmallWhite} alt="CalendarSmallWhite" />}
								name="time"
								onChange={onChangeTime}
								value={time}
								error={
									formik.touched.date &&
									Boolean(formik.errors.date)
								}
								helperText={
									formik.touched.date && t(formik.errors.date)
								} />
						</div>
					</div>
					<div className='row mt-4'>
						<div className="col mt-2">
							<TextAreaUI label={'Event Description'} placeholder={'Describe your event'}
								name="description"
								onChange={formik.handleChange}
								value={formik.values.description}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description && t(formik.errors.description)
								} />
						</div>
					</div>
					<div className='row mt-4 gap-4'>
						<div className="col-12 col-lg-5 mt-2">
							<DropdownOutline label={'Event Type (Category)'} name="category"
								onChange={formik.handleChange} data={categories}
								error={
									formik.touched.category &&
									Boolean(formik.errors.category)
								}
								helperText={
									formik.touched.category && t(formik.errors.category)
								}
								value={formik.values.category} />
						</div>
						{/* <div className="col  mt-2">
				<DropdownOutline label={'Event Tags'} />
			</div> */}
					</div>

					<div className='row mt-5'>
						<p className='create-subtitle'><img src={LocationBlue} alt="Location" />Location</p>
						<div className="col note row align-items-center justify-content-around m-2 gap-4">
							<div className="col p-0 ">
								<CheckBoxUI label={'From mine or previous locations'} name="locations"
									checked={formik.values.locations} onChange={(e) => {
										formik.handleChange(e)

										e.target.checked === false && formik.setFieldValue('location', '')
									}} />
							</div>
							{formik.values.locations &&
								<div className="col">
									<DropdownOutline className="m-0" label={'previous locations'} attribute="address"
										data={locations} name="location" onChange={formik.handleChange}
										value={formik.values.location}
										error={
											formik.touched.location &&
											Boolean(formik.errors.location)
										}
										helperText={
											formik.touched.location && t(formik.errors.location)
										} />
								</div>}
						</div>
					</div>
					<div className='row mb-5'>
						<div className="col">
							<div className='row mt-4 gap-4'>
								<div className="col-lg-4 col-12">
									<Inputs label={'Event Location'} placeholder={'Place Name'}
										name="address"
										onChange={formik.handleChange}
										value={formik.values.address}
										disabled={formik.values.locations}
										error={
											formik.touched.address &&
											Boolean(formik.errors.address)
										}

										helperText={
											formik.touched.address && t(formik.errors.address)
										} />
								</div>
								<div className="col-lg-3 col-12">
									<DropdownOutline label={'City'} data={cities} name="city"
										onChange={formik.handleChange}
										value={formik.values.city}
										disabled={formik.values.locations}
										error={
											formik.touched.city &&
											Boolean(formik.errors.city)
										}
										helperText={
											formik.touched.city && t(formik.errors.city)
										} />
								</div>
								<div className="col">
									<Inputs label={'Google Maps URL'} placeholder={'Google Maps URL'}
										name="google_map_link"
										onChange={formik.handleChange}
										value={formik.values.google_map_link}
										disabled={formik.values.locations}
										error={
											formik.touched.google_map_link &&
											Boolean(formik.errors.google_map_link)
										}
										helperText={
											formik.touched.google_map_link && t(formik.errors.google_map_link)
										} />

								</div>
							</div>
						</div>
					</div>
					<div className='row mt-5'>
						<div className="col">
							<p className='create-subtitle'><img src={Gallery} alt="Gallery" />Event Thumbnails</p>
							<div className='row mt-4'>
								<div className="col-12 col-lg-6 mt-4">
									<UploadFile label={'Event image'}
										fileSize={'300 * 300 px ~ Max 5 MB'}
										onChange={handleChangeImageFile}
										inputRef={imgFileRef}
										clickUploader={clickUploader}
										value={eventImgFile ? eventImgFile.name : event.image} />
								</div>
								<div className="col mt-4">
									<UploadFile label={'Event Cover Image'}
										fileSize={'1400 * 235 px ~ Max 5 MB'}
										onChange={handleChangeCoverFile} inputRef={coverFileRef} clickUploader={clickCoverUploader}
										value={coverFile ? coverFile.name : event.cover_image} />
								</div>
							</div>
						</div>
					</div>
					<div className='row mt-5'>
						<div className="col-lg-6 col">
							<Inputs label={'Contact Number'} placeholder={'01123XXXXXX'}
								name='phone_number'
								value={formik.values.phone_number}
								onChange={formik.handleChange}
								error={
									formik.touched.phone_number &&
									Boolean(formik.errors.phone_number)
								}
								helperText={
									formik.touched.phone_number && t(formik.errors.phone_number, { length: validationRules.mobileLength })
								}
								icon={<img style={{ width: '1.2rem', height: '1.2rem', marginRight: '.5rem', marginBottom: '.3rem' }} src={PhoneIcon} alt="PhoneIcon" />} />
						</div>
					</div>
					<div className='row mt-5'>
						<div className="col note row align-items-center justify-content-around m-2 gap-4">
							<div className="col p-0 ">
								<CheckBoxUI label={'Is ticket for each user'} name="is_ticket_for_each_user" checked={ticketForEachUser} onChange={(e) => setTicketForEachUser(e.target.checked)} />
							</div>

						</div>
					</div>

					<button className='stepper-next' type="submit">{submit ? <CircularProgress /> : "Next"} <img src={RightIcon} alt='RightIcon' /></button>


				</form>
			}
		</div>
	);
}
