import { useEffect, useState } from "react";
import { Footer, Header } from "../Home/components";
import EventImg from '../../assets/img/eventDetails.svg';
import Copy from '../../assets/img/copy.svg';
// import eye from '../../assets/img/eye.svg';
// import HeartRed from '../../assets/img/heartRed.svg';
import CalenderBigBlue from '../../assets/img/calenderBigBlue.svg';
import UserImgEvent from '../../assets/img/userImgEvent.svg';
import LocationBlue from '../../assets/img/locationBlue.svg';
import TicketBlue from '../../assets/img/ticketBlue.svg';
import Discount from '../../assets/img/discount.svg';
import Minus from '../../assets/img/minus.svg';
import MinusDisabled from '../../assets/img/minusDisabled.svg';
import Plus from '../../assets/img/plus.svg';
import PlusDisabled from '../../assets/img/plusDisabled.svg';
import { StorageService, storageKeys } from "../../application/services";
import { Button } from "../../components/ui";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceProxy } from "../../ServiceProxy";
import { Overlay } from "../../components/ui";
import PhoneIcon from '../../assets/img/phoneIcon.svg';
import { withErrorHandler } from "../../components";
import { CircularProgress } from "@mui/material";
import Clock from '../../assets/img/clock.svg';
import axios from "axios";
import { pageSize } from "../../config";
const EventDetailsPage = () => {
	const navigate = useNavigate()
	let { id } = useParams();
	const [eventData, setEventData] = useState(null)
	const [eventTickets, setEventTickets] = useState(null)
	const [loading, setLoading] = useState(true)
	const [totalTickets, setTotalTickets] = useState(0)
	const [total, setTotal] = useState(0)
	const [send, setSend] = useState(false)
	const [soldTickets, setSoldTickets] = useState(null)
	const [purchases, setPurchases] = useState(null)
	const userID = StorageService.get(storageKeys.userID)
	const getEventTickets = () => {
		ServiceProxy.EventTicketsService.getEventTicketsList({ event: id })
			.then(response => {
				if (response && response.data) {
					const updatedTickets = response.data.results.map(r => { return { ...r, bookingTickets: 0 } })
					setEventTickets(updatedTickets)
					setLoading(false)
				}
			})
			.catch(e => console.error({ e }))
	}
	const getEventData = () => {
		id && ServiceProxy.EventsService.getEventsList({ id })
			.then(response => {
				if (response && response.data) {
					getEventTickets()
					setEventData(response.data.results[0])
				}
			})
			.catch(e => console.error({ e }))
	}
	const increment = (id, price, quantity, booking) => {
		if (booking < quantity) {
			const data = eventTickets.map(ticket => {
				if (id !== ticket.id) return ticket;
				return { ...ticket, bookingTickets: ticket.bookingTickets + 1 }
			})
			setEventTickets(data)
			setTotal(total + price)
			setTotalTickets(totalTickets + 1)
		}
	}
	const decrement = (id, price, booking) => {
		if (booking > 0) {
			const data = eventTickets.map(ticket => {
				if (id !== ticket.id) return ticket;
				return { ...ticket, bookingTickets: ticket.bookingTickets - 1 }
			})
			setEventTickets(data)
			setTotal(total - price)
			setTotalTickets(totalTickets - 1)
		}

	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	const handleCheckout = () => {
		if (userID) {
			const updatedEventTickets = eventTickets.map(r => { return { name: r.name, event_ticket_type_id: r.id, quantity: r.bookingTickets } })
			const isvalid = updatedEventTickets.some(element => element.quantity > 0);
			if (isvalid) {
				setSend(true)
				ServiceProxy.PurchaseService.purchasePreview({ tickets: updatedEventTickets })
					.then(response => {
						setSend(false)
						navigate('/checkout', { state: { purchaseData: response.data, eventData, updatedEventTickets } })
					})
					.catch(e => console.error({ e }))
			}
		}
		else {
			navigate('/login', { replace: true })
		}
	}
	const getSoldTickets = () => {
		setLoading(true)
		const params = {
			page: 1,
			page_size: pageSize,
			event: id
		}
		ServiceProxy.TicketsService.getTicketsList(params)
			.then(response => {
				if (response && response.data) {
					setSoldTickets(response.data.results)
					setLoading(false)
					navigate('/sold-tickets', { state: { soldTickets: response.data, id: eventData.id } })
				}
			})
			.catch(e => console.error({ e }))
	}
	const getPurchases = () => {
		setLoading(true)
		const params = {
			page: 1,
			page_size: pageSize,
			event: id
		}
		ServiceProxy.PurchaseService.listPurchases(params)
			.then(response => {
				if (response && response.data) {
					setLoading(false)
					setPurchases(response.data.results)
					navigate('/payments', { state: { purchases: response.data, id: eventData.id } })
				}
			})
			.catch(e => console.error({ e }))
	}
	const loginNavigate = () => navigate('/login')
	const signupNavigate = () => navigate('/signup')
	useEffect(() => {
		getEventData()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container">
			<Header logout={logout} loginNavigate={loginNavigate} signupNavigate={signupNavigate} />
			{loading ? <Overlay />
				:
				<>
					<div className="container-fluid">
						<div className="row" style={{ width: '100%', height: '235px' }}>
							<img src={eventData && eventData.cover_image ? eventData.cover_image : EventImg} height={'100%'} width={'100%'} style={{ borderRadius: '20px', objectFit: 'ccover' }} alt="EventDetails" />
						</div>
						<div className="row viewAndLove">
							<div className="col-8 ">
								<div className='features p-0 justify-content-start'>
									<div className='row m-1'>
										<p className='title'>{eventData.category.name}</p>
										<p className='description'>{eventData.name}</p>
									</div>
								</div>
							</div>
							<div className="col d-flex justify-content-end">
								<div className="row gap-3 m-3">
									{/* <div className="col box bg-transparent">
										<img className="m-2" src={eye} alt="eye" />33  Views
									</div> */}
									{/* <div className="col box">
										<img className="m-2" src={HeartRed} alt="HeartRed" />
										20 Liked
									</div> */}
									<div className="col box pointer" onClick={() => { navigator.clipboard.writeText(window.location.href) }}>
										<img className="m-2" src={Copy} alt="copy" />
										Copy link
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row m-3 gap-3 viewContent">
						<div className="col-xl-7 col-lg-7 col-md-12 cardContent">
							<div className="content">
								<img className="m-2" src={CalenderBigBlue} alt="CalenderBigBlue" />
								<p className="date">{eventData.date}  <img className="m-4" src={Clock} alt="Clock" /> {eventData.time}</p>
							</div>
						</div>
						<div className="col cardContent">
							<div className="content">
								{eventData.organization ?
									<img className="m-2 profile-img" src={eventData.organization.logo ? eventData.organization.logo : UserImgEvent} alt="UserImgEvent" />
									:
									<img className="m-2 profile-img" src={eventData.user.photo ? eventData.user.photo : UserImgEvent} alt="UserImgEvent" />

								}
								<div className="col">
									<p className="organizedBy m-0">Organized by</p>
									{eventData.organization ?
										<p className="organizedBy userName" onClick={() => navigate(`/organiztion-profile/${eventData.organization.id}`)}>{eventData.organization.name}</p>
										:
										<p className="organizedBy userName" onClick={() => navigate(`/organizer-profile/${eventData.user.id}`)}>{eventData.user.first_name} {eventData.user.first_name}</p>

									}
									{/* <p className="organizedBy">20 Followers</p> */}
								</div>
								{/* <button type="button" className="btn viewBtn">{eventData.phone_number}</button> */}
							</div>
						</div>
						<div className="col cardContent">
							<div className="content">
								<div className="col d-flex align-items-center flex-column">
									<img className="m-2" src={PhoneIcon} alt="PhoneIcon" />
									<p className="organizedBy m-0 opacity-75">{eventData.phone_number}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row m-3 gap-3 viewContent">
						<div className="col-xl-7 col-lg-7 col-md-12 p-0">
							<div className=" cardContent">
								<div className="content">
									<img className="m-2" src={LocationBlue} alt="LocationBlue" />
									<div className="col">
										<p className="date m-0">{eventData.city.name}</p>
										{eventData.location.name ?
											<p className="organizedBy">{eventData.city.name}  - {eventData.location.name} - {eventData.location.address} </p>
											:
											<p className="organizedBy">  {eventData.location.address} </p>
										}
									</div>

								</div>
								<div className="content justify-content-around">
									<a href={eventData.location.google_map_link} target="_blank" rel="noreferrer"><button type="button" className="btn viewBtn w-auto">Get map directions</button></a>
								</div>
							</div>
							<div className="content about-event">
								<div className="col">
									<p className="title-about-event">About Event </p>
									<p className="desc mb-5">{eventData.description}</p>
									{/* {eventData?.user?.id === userID && purchases && purchases.length > 0 ?
										<button type="button" className="btn viewBtn w-auto mb-5" onClick={() => navigate('/payments', { state: { purchases } })}>Show Payments</button>
										: null} */}
									{eventData?.user?.id === userID ?
										<>
											<button type="button" className="btn viewBtn w-auto mb-5" onClick={getSoldTickets}>Show Sold Tickets</button>
											<button type="button" className="btn viewBtn w-auto mb-5" onClick={getPurchases}>Show Payments</button>
										</> : null}

									{/* <p className="desc">As a social media user , connecting with your devices side is the roadmap to a more fulfilled life where you can active your goal through taking Ai to your hand </p>
									<p className="desc">This workshop is designed to help you explore and express your relationship with AI
										through guided prompts and exercises. You’ll be able to reflect on your personal experiences,
										beliefs, and emotions surrounding technology.</p>
									<p className="desc">As a social media user , connecting with your devices side is the roadmap to a more fulfilled life where you can active your goal through taking Ai to your hand </p>
									<p className="desc font-bold">After this workshop:</p>
									<p className="desc mb-1">-You’ll have a deeper connection with your technologies and devices which will
										eventually enhance your work </p>
									<p className="desc">-You’ll have tools and practices to continue exploring and expressing Ai thought some portals.</p> */}
									{/* <div className="col mt-5">
										<p className="desc m-0">Date: {eventData.date} </p>
										<p className="desc m-0">Time: {eventData.time} </p>
										<p className="desc m-0"><span className="desc font-bold">Fees: </span>3OO EGP </p>
									</div> */}
									{/* <div className="col mt-5">
										<p className="title-about-event mb-2">Tags </p>
										<div className="tags">
											<div className="tag">
												technology
											</div>
											<div className="tag">
												workshop
											</div>
											<div className="tag">
												Ai
											</div>
										</div>
									</div> */}


								</div>

							</div>

						</div>

						{eventTickets.length > 0 && <div className="col cardTickets">

							<div className="tickets">
								<img className="m-2" src={TicketBlue} alt="TicketBlue" />
								<span className="date m-0">Tickets</span>

							</div>
							{eventTickets.length > 0 && eventTickets.map((ticket, index) => {
								return (
									<div className="col ticketCard" key={index}>
										<div className="content ticket">
											<div className="col">
												<p className="organizedBy ticketType">Ticket Type</p>
												<p className="date m-0">{ticket.name}</p>
											</div>
											<div className="col position-relative">
												{ticket.current_status && <p className="organizedBy discount">{ticket.current_status} <img src={Discount} alt="Discount" /></p>}
												<div className="col d-flex align-items-center justify-content-start gap-1">
													<p className="date m-0">{ticket.current_price}</p>
													<span className="organizedBy">EGP</span>
												</div>
											</div>
											{ticket.bookingTickets === 0 && ticket.bookingTickets >= ticket.current_quantity ?
												<div className="col minusAndPlus">
													<p className="date m-0">Soldout</p>

												</div>
												:
												<div className="col minusAndPlus">
													<img src={ticket.bookingTickets === 0 ? MinusDisabled : Minus} alt="Minus" onClick={() => decrement(ticket.id, ticket.current_price, ticket.bookingTickets)} />
													<p className="date m-0">{ticket.bookingTickets}</p>
													<img src={ticket.bookingTickets >= ticket.current_quantity ? PlusDisabled : Plus} alt="Plus" onClick={() => increment(ticket.id, ticket.current_price, ticket.current_quantity, ticket.bookingTickets)} />
												</div>}
										</div>
									</div>
								)
							})}

							{/* <div className="col ticketCard">
								<div className="content ticket">
									<div className="col">
										<p className="organizedBy ticketType">Ticket Type</p>
										<p className="date m-0">Normal</p>
									</div>
									<div className="col position-relative">
										<p className="organizedBy discount">Early bird <img src={Discount} alt="Discount" /></p>
										<div className="col d-flex align-items-center justify-content-start gap-1">
											<p className="date m-0">250</p>
											<span className="organizedBy">EGP</span>
										</div>
									</div>
									<div className="col minusAndPlus">
										<img src={Minus} alt="Minus" />
										<p className="date m-0">0</p>
										<img src={Plus} alt="Plus" />
									</div>
								</div>
							</div>
							<div className="col ticketCard">
								<div className="content ticket">
									<div className="col">
										<p className="organizedBy ticketType">Ticket Type</p>
										<p className="date vip m-0">VIP</p>
									</div>
									<div className="col">
										<div className="col d-flex align-items-center justify-content-start gap-1">
											<p className="date m-0">400</p>
											<span className="organizedBy">EGP</span>
										</div>
										<span className="organizedBy textBox">Available for 1 person</span>
									</div>
									<div className="col minusAndPlus">
										<img src={Minus} alt="Minus" />
										<p className="date m-0">0</p>
										<img src={Plus} alt="Plus" />
									</div>
								</div>
							</div>
							<div className="col ticketCard">
								<div className="content ticket">
									<div className="col">
										<p className="organizedBy ticketType">Ticket Type</p>
										<p className="date superVip m-0">Super VIP</p>
									</div>
									<div className="col">
										<div className="col d-flex align-items-center justify-content-start gap-1">
											<p className="date m-0">600</p>
											<span className="organizedBy">EGP</span>
										</div>
										<span className="organizedBy textBox">Available for 1 person</span>
									</div>
									<div className="col minusAndPlus">
										<img src={Minus} alt="Minus" />
										<p className="date m-0">0</p>
										<img src={Plus} alt="Plus" />
									</div>
								</div>
							</div> */}
							<br />
							<div className="container">
								<div className="row">
									<div className="col ticketCard">
										<div className="col totalTickets">
											<p className="date m-0">Tickets</p>
											<p className="date m-0">{totalTickets}</p>
										</div>
										<div className="col totalTickets">
											<p className="date m-0">Total price</p>
											<div className="col d-flex align-items-center justify-content-end gap-1">
												<p className="date m-0">{total}</p>
												<span className="organizedBy">EGP</span>
											</div>
										</div>
									</div>
								</div>
								<div className='row proceedBtn' onClick={handleCheckout}>

									<Button disabled={send} title={send ? <CircularProgress /> : 'Proceed To Checkout'} className='Btn' />
								</div>
							</div>
						</div>}
						{/* {eventData?.user?.id === userID && soldTickets && soldTickets.length > 0 ?
							<div style={{ background: '#413df60d', borderRadius: '1.25rem', padding: '20px', marginBottom: '4rem' }}>
								<button type="button" className="btn viewBtn w-auto mb-5">Sold Tickets</button>
								<div className="the-table mt-10">
									<table className="table table-borderless">
										<thead className="position-relative">
											<th scope="col" className="t-head" >Ticket Type</th>
											<th scope="col" className="t-head" >Quantity</th>
											<th scope="col" className="t-head" >Price</th>
											<th scope="col" className="t-head" >Phone Number</th>
											<th scope="col" className="t-head" >Name</th>
											<th scope="col" className="t-head" >Email</th>
											<th scope="col" className="t-head" >Paymnet Status</th>
										</thead>
										<tbody>

											{soldTickets && soldTickets.map((data, index) => {
												return (
													<tr key={index}>
														<td >{data.ticket_type}</td>
														<td>{data.quantity}</td>
														<td >{data.price}</td>
														<td >{data.phone_number}</td>
														<td >{data.name}</td>
														<td >{data.email}</td>
														<td >{data.payment_status}</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
							: null} */}
					</div>

				</>}


			{/* <div style={{ marginBottom: '11.14rem' }} /> */}
			<Footer />
		</div>
	);
}
export const EventDetails = withErrorHandler(EventDetailsPage, axios)