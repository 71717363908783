import ArrowRight from '../../../assets/img/arrowRight.svg';
import StepTwo from '../../../assets/img/stepTwo.svg';
import StepThree from '../../../assets/img/stepThree.svg';
import { StorageService, storageKeys } from '../../../application/services';
import { useNavigate } from 'react-router-dom';

export const Features = ({ signupNavigate }) => {
    const userID = StorageService.get(storageKeys.userID)
    const navigate = useNavigate()
    return (
        <>
            <div className='features'>
                <div className='row'>
                    <p className='title'>FEATURES</p>
                    <p className='description'>Solutions that will change your events forever</p>
                </div>
            </div>
            <div className='container mt-2'>
                <div className='row steps-container'>
                    <div className='card step-one'>
                        <div className='number-one'>1</div>
                        <div className='col content'>
                            <p className='title'>
                                Buy your ticket
                            </p>
                            <p className='description'>
                                With less effort
                            </p>
                            <p className='description'>
                                find your best event to attend
                            </p>
                            <div className='row justify-content-center mb-3'>
                                <button className="create-event discover-event text-white" type="submit" onClick={() => navigate('/events')}>Discover Events
                                    <img className='d-griud mx-2' src={ArrowRight} alt='ArrowRight' />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='card step-one step-two'>
                        <div className='number-one'>2</div>
                        <div className='col content'>
                            <img className='stepTwoImg' src={StepTwo} alt='StepTwo' />
                            <p className='title'>
                                Create & Manage Events
                            </p>
                            <p className='description'>
                                Build unforgettable events
                            </p>
                            <p className='description'>
                                with our Easy-to-user
                            </p>
                            <p className='description'>
                                XPay platform
                            </p>
                        </div>
                    </div>
                    <div className='card step-one step-two step-three'>
                        <div className='number-three'>3</div>
                        <img className='step-three-img' src={StepThree} alt='StepThree' />
                        <div className='col content'>
                            <p className='title'>
                                Assign Your Ushers
                            </p>
                            <p className='description'>
                                Integrate Usher mail with
                            </p>
                            <p className='description'>
                                our mobile app to start
                            </p>
                            <p className='description'>
                                scanning ticket easily
                            </p>
                            {!userID && <button onClick={signupNavigate} className="create-event discover-event text-white" type="submit">Sign up now
                                <img className='d-griud mx-2' src={ArrowRight} alt='ArrowRight' />
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}