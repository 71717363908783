// import CalendarBlue from '../../../assets/img/calendarBlue.svg';
// import Eye from '../../../assets/img/eye.svg';
// import HeartRed from '../../../assets/img/heartRed.svg';
// import Location from '../../../assets/img/location.svg';
// import ArrowRight from '../../../assets/img/arrowRight.svg';
// import HeartWhite from '../../../assets/img/heartWhite.svg';
// import EventImg from '../../../assets/img/eventImg.svg';
// import EventImg1 from '../../../assets/img/eventImg1.svg';
// import EventImg2 from '../../../assets/img/eventImg2.svg';
import ArrowDown from '../../../assets/img/arrowDown.svg';
import { SearchEvents } from './SearchEvents';
import { EventCard } from '../../../components';
import NoSearchResults from '../../../assets/img/searchIccon.svg'
export const Events = ({ cities, categories, date, category, city, handleChangeCity, handleChangCategory, handleChangeDate,
	handleChangeSearchInput, search, handleSendSearch, getMoreEvents, isNext, events, eventDetailsNavigate, eventsParams }) => {
	return (
		<div className='container-fluid events-cards pb-0'>
			<SearchEvents cities={cities} categories={categories} date={date}
				handleChangCategory={handleChangCategory} handleChangeCity={handleChangeCity}
				handleChangeDate={handleChangeDate} city={city} category={category}
				handleChangeSearchInput={handleChangeSearchInput} search={search}
				handleSendSearch={handleSendSearch} getMoreEvents={getMoreEvents} eventsParams={eventsParams} />
			<div className='container pb-5'>
				<div className="row align-items-center">
					{events.length > 0 ? events.map((event, index) => {
						return <div className="col-lg-6 col-xl-4 col-sm-12 d-flex justify-content-center" key={index}>
							<EventCard data={event} eventDetailsNavigate={eventDetailsNavigate} />
						</div>
					}) :
						<div className='no-search-results'>
							<img src={NoSearchResults} alt='results' />
							<p className='card-title'>No Search Results Found</p>
						</div>
					}
					{/* <div className="col">
						<div className="card">
							<img className="card-img-top" src={EventImg1} alt="cardImg" />
							<div className="card-body">
								<div className='col statistics'>
									<p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
									<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p>
									<p className='like'>Business</p>
								</div>
								<div className='col statistics'>
									<p className="card-title m-0">PRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with Morgan , Avery ..</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={CalendarBlue} alt='HeartRed' />05 AUG  ~ 6:00 PM</p>
									<p className="like card-text text-decoration-underline">Organization-X</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text location"><img src={Location} alt='Location' />   Lusaka  - North coast</p>
									<p className="like card-text directions">Get Directions</p>
								</div>
								<div className='col m-0 statistics btns'>
									<button href="#" className="btn book-now more-details">More Details<img src={ArrowRight} alt='ArrowRight' /></button>
									<button href="#" className="btn book-now">Book Now</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<img className="card-img-top" src={EventImg2} alt="cardImg" />
							<div className="card-body">
								<div className='col statistics'>
									<p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
									<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p>
									<p className='like'>Workshop</p>
								</div>
								<div className='col statistics'>
									<p className="card-title m-0">PRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with Morgan , Avery ..</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={CalendarBlue} alt='HeartRed' />05 AUG  ~ 6:00 PM</p>
									<p className="like card-text text-decoration-underline">Organization-X</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={Location} alt='Location' />   Lusaka  - North coast</p>
									<p className="like card-text directions">Get Directions</p>
								</div>
								<div className='col m-0 statistics btns'>
									<button href="#" className="btn book-now more-details">More Details<img src={ArrowRight} alt='ArrowRight' /></button>
									<button href="#" className="btn book-now">Book Now</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<img className="card-img-top" src={EventImg} alt="cardImg" />
							<div className="card-body">
								<div className='col statistics'>
									<p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
									<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p>
									<p className='like'>Workshop</p>
								</div>
								<div className='col statistics'>
									<p className="card-title m-0">PRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with Morgan , Avery ..</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={CalendarBlue} alt='CalendarBlue' />05 AUG  ~ 6:00 PM</p>
									<p className="like card-text text-decoration-underline">Organization-X</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={Location} alt='Location' />   Lusaka  - North coast</p>
									<p className="like card-text directions">Get Directions</p>
								</div>
								<div className='col m-0 statistics btns'>
									<button href="#" className="btn book-now more-details">More Details<img src={ArrowRight} alt='ArrowRight' /></button>
									<button href="#" className="btn book-now">Book Now</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<img className="card-img-top" src={EventImg1} alt="Cardimagecap" />
							<div className="card-body">
								<div className='col statistics'>
									<p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
									<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p>
									<p className='like'>Business</p>
								</div>
								<div className='col statistics'>
									<p className="card-title m-0">PRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with Morgan , Avery ..</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={CalendarBlue} alt='HeartRed' />05 AUG  ~ 6:00 PM</p>
									<p className="like card-text text-decoration-underline">Organization-X</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text location"><img src={Location} alt='Location' />   Lusaka  - North coast</p>
									<p className="like card-text directions">Get Directions</p>
								</div>
								<div className='col m-0 statistics btns'>
									<button href="#" className="btn book-now more-details">More Details<img src={ArrowRight} alt='ArrowRight' /></button>
									<button href="#" className="btn book-now">Book Now</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<img className="card-img-top" src={EventImg2} alt="cardImg" />
							<div className="card-body">
								<div className='col statistics'>
									<p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
									<p className='like'><img src={Eye} alt='Eye' /> 33 Views</p>
									<p className='like'>Workshop</p>
								</div>
								<div className='col statistics'>
									<p className="card-title m-0">PRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with MorPRAISE & Worship with Morgan , Avery ..</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={CalendarBlue} alt='HeartRed' />05 AUG  ~ 6:00 PM</p>
									<p className="like card-text text-decoration-underline">Organization-X</p>
								</div>
								<div className='col statistics'>
									<p className="like card-text"><img src={Location} alt='Location' />   Lusaka  - North coast</p>
									<p className="like card-text directions">Get Directions</p>
								</div>
								<div className='col m-0 statistics btns'>
									<button href="#" className="btn book-now more-details">More Details<img src={ArrowRight} alt='ArrowRight' /></button>
									<button href="#" className="btn book-now">Book Now</button>
								</div>
							</div>
						</div>
					</div> */}
				</div>
				{isNext && <div className='container eventsCards'>
					<div className='container'>
						<div className='d-flex justify-content-center row'>
							<div className='load-more' onClick={getMoreEvents}>
								<span>Load More</span>
								<img className='arrowDown' src={ArrowDown} alt="arrow" />
							</div>
						</div>
					</div>
					{/* <div className='col'>
						<div className='col statistics m-3 align-items-center'>
							<p className="best-selling">Best Selling</p>
							<p className="view-more">View more</p>
						</div>
					</div> */}
				</div>}
			</div>
		</div>
	);
}
