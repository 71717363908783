import { useState } from 'react';
import closedEye from '../../assets/img/closedEye.svg';
import closedEyeGray from '../../assets/img/closedEyeGray.svg';
import { Balls, Button, Checkbox, Input, Text } from '../../components/ui';
import { useFormik } from "formik";
import { registrationSchema, validationRules } from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { ServiceProxy } from '../../ServiceProxy'
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { StorageService, storageKeys } from '../../application/services';
import { withErrorHandler } from '../../components';
import axios from 'axios';
const RegisterPage = () => {
    const navigate = useNavigate()
    const [passwordShown, setPasswordShown] = useState(false);
    const [submit, setSubmit] = useState(false);
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            password: ''
        },
        validationSchema: registrationSchema,
        onSubmit: (values) => {
            handleRegister(values)
        },
    });
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const handleRegister = (values) => {
        setSubmit(true)
        const data = { ...values, phone_number: `+2${values.phone_number}` }
        ServiceProxy.AuthService.register(data)
            .then(response => {
                setSubmit(false)
                if (response && response.data) {
                    StorageService.set(storageKeys.userID, response.data.id)
                    navigate('/login')

                }
            })
            .catch(e => {
                console.error({ e })
                setSubmit(false)
            })
    }
    return (
        <div className='container-fluid h-100'>
            <div className='row'>
                <div className='col xPay-logo' />
                <div className='col form-outer-card'>
                    <div className='form-wrapper'>
                        <Text className='head-text' characters={t('sign_up')} />
                        <form onSubmit={formik.handleSubmit} autoComplete="off" >
                            <div className='form-card container'>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput'
                                            name='first_name'
                                            placeholder={t('first_name')}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    formik.handleSubmit()
                                                }
                                            }}
                                            error={
                                                formik.touched.first_name &&
                                                Boolean(formik.errors.first_name)
                                            }
                                            helperText={
                                                formik.touched.first_name && t(formik.errors.first_name)
                                            }
                                        />
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput'
                                            name='last_name'
                                            placeholder={t('last_name')}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    formik.handleSubmit()
                                                }
                                            }}
                                            error={
                                                formik.touched.last_name &&
                                                Boolean(formik.errors.last_name)
                                            }
                                            helperText={
                                                formik.touched.last_name && t(formik.errors.last_name)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput'
                                            name='phone_number'
                                            placeholder={t('phone_number')}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    formik.handleSubmit()
                                                }
                                            }}
                                            error={
                                                formik.touched.phone_number &&
                                                Boolean(formik.errors.phone_number)
                                            }
                                            helperText={
                                                formik.touched.phone_number && t(formik.errors.phone_number, { length: validationRules.mobileLength })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <Input
                                            className='userNameInput'
                                            name='email'
                                            placeholder={t('email')}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    formik.handleSubmit()
                                                }
                                            }}
                                            error={
                                                formik.touched.email &&
                                                Boolean(formik.errors.email)
                                            }
                                            helperText={
                                                formik.touched.email && t(formik.errors.email)
                                            }

                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <div className='passwordInput'>
                                            <Input
                                                type={passwordShown ? 'text' : 'password'}
                                                icon={passwordShown ? closedEye : closedEyeGray}
                                                iconClassName={'input-icon'}
                                                className='userNameInput'
                                                name='password'
                                                placeholder={t('password')}
                                                onChange={formik.handleChange}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        formik.handleSubmit()
                                                    }
                                                }}
                                                error={
                                                    formik.touched.password &&
                                                    Boolean(formik.errors.password)
                                                }
                                                helperText={
                                                    formik.touched.password && t(formik.errors.password)
                                                }
                                                inputProps={{
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                }}
                                                onClickIcon={togglePassword}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <Checkbox label={t('accept')} link={t('terms_and_conditions')} className={'check-box-wrapper'} />
                                    </div>
                                </div>
                            </div>
                            <Balls numOfBalls={10} />
                            <Button type='submit' disabled={submit} title={submit ? <CircularProgress /> : t('create_account')} className={'btn-submit'} />
                        </form>
                        <div className='action-wrapper'>
                            <p>{t('have_account')}<a href="/login">{t('sign_in')}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export const Register = withErrorHandler(RegisterPage, axios)

