
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class UshersService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/ushers`
    }
    getUshersList(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/list`, { params });
        removeDefaultHeaders(axios)
        return request
    }
    addUsher(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.post(`${this._baseUrl}/`, values);
        removeDefaultHeaders(axios)
        return request;
    }
    deleteUsher(values) {
        addAxiosDefaultHeaders(axios)
        const request = axios.delete(`${this._baseUrl}/list`, { data: values });
        removeDefaultHeaders(axios)
        return request;
    }
}