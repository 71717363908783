import { useLocation } from "react-router-dom"

export const Iframe = ({ iframeUrl }) => {
    const location = useLocation()
    const url = location.state?.url || iframeUrl
    return (
        <div className="container pt-0" style={{ minHeight: '100vh' }} >
            <div style={{ height: '100vh' }}>
                <iframe src={url ? url : ''}
                    style={{ width: "100%", height: "100%" }}
                    title="card"
                ></iframe>
            </div>
        </div >
    )
}