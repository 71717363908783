export const Checkbox = ({ handleChange, checked, label, className, link }) => {
    return (
        <label className={className}>
            <input
                type='checkbox'
                checked={checked}
                onChange={handleChange}
            />
            {label} {link && <a href="/" target="_blank">{link}</a>}
        </label>
    );
};

