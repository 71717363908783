import { StorageService, storageKeys } from '../application/services';


export const removeDefaultHeaders = (axios) => {
  delete axios.defaults.headers.common['Authorization'];

};
export const addAxiosDefaultHeaders = (axios) => {

  const token = StorageService.get(storageKeys.accessToken)

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
