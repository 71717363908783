import { Inputs, RadioBtn } from "./Inputs";
import Plus from "../../../assets/img/plusSmallWhite.svg";
import ushersBlueIcon from "../../../assets/img/ushersBlueIcon.svg";
// import EyePreview from "../../../assets/img/eyePreview.svg";
import Share from "../../../assets/img/share.svg";
import { useState, useEffect } from "react";
import { ServiceProxy } from "../../../ServiceProxy";
import { pageSize } from "../../../config";
import { Overlay } from "../../../components/ui";
import { useFormik } from "formik";
import { t } from 'i18next';
import { addUsherValidationSchema } from '../../../config'
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
export const Ushers = ({ organization, event }) => {
	const navigate = useNavigate()
	const [ushers, setUshers] = useState([])
	const [loading, setLoading] = useState(true)
	const [submit, setSubmit] = useState(false)
	const [assignedUshers, setAssignedUshers] = useState([])
	const ushersParams = {
		page: 1,
		page_size: pageSize,
		organization: organization,
		event: event
	}
	const getUshers = () => {
		organization && ServiceProxy.UshersService.getUshersList(ushersParams)
			.then(response => {
				if (response && response.data) {
					setUshers(response.data)
					setLoading(false)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const addUsher = (values) => {
		setSubmit(true)
		ServiceProxy.UshersService.addUsher(values)
			.then(response => {
				if (response && response.data) {
					setSubmit(false)
					formik.resetForm()
					getUshers()
				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
	}
	const formik = useFormik({
		initialValues: {
			phone_number: '',
			last_name: '',
			first_name: ''
		},
		validationSchema: addUsherValidationSchema,
		onSubmit: (values) => {
			addUsher({ ...values, organization, event })

		},
	});
	const assignUshers = (e, usher) => {
		if (e.target.checked === true) {
			let list = assignedUshers
			// list.push({ phone_number: usher.phone_number })
			addUsher({ ...usher, organization, event, is_assigned: true })
			// setAssignedUshers(list)
		}
		else {
			unAssignUsher({ usher: usher.id, organization, event })
			// setAssignedUshers(assignedUshers.filter(item => item.phone_number !== usher.phone_number))
		}
	}
	const submitEventUshers = () => {
		for (let i = 0; i < assignedUshers.length; i++) {
			addUsher({ ...assignedUshers[i], organization, event })
		}
	}
	const unAssignUsher = (values) => {
		setSubmit(true)
		ServiceProxy.UshersService.deleteUsher(values)
			.then(response => {
				if (response) {
					setSubmit(false)
					getUshers()
				}
			})
			.catch(e => {
				console.error({ e })
				setSubmit(false)
			})
	}
	useEffect(() => {
		getUshers()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container details-event">
			{loading ? <Overlay /> :
				<div className="row align-items-center gap-4">
					<div className="d-flex align-items-center gap-2" style={{ flexDirection: 'row', padding: 0 }}>
						<img src={ushersBlueIcon} alt="ushers" />
						<h4 className="m-0">Ushers</h4>
					</div>
					{ushers.length > 0 &&
						<div className="ushers">
							<div className="row mb-4">
								<div className="col-10">
									<h6 className="m-0">My Ushers</h6>
								</div>
								{/* <div className="col d-flex justify-content-end">
									<p className="w-75">Assign to Scan Tickets</p>
								</div> */}
							</div>
							{/*<div className="row">
						<div className="row">
							<div className="col-3">
								<p>Muhamed Saied</p>
							</div>
							<div className="col-4">
								<p>Muhameds22@gmail.com</p>
							</div>
							<div className="col-3">
								<p>01156693094</p>
							</div>
							<div className="col-2 d-flex justify-content-center">
								<RadioBtn />
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<p>Muhamed Saied</p>
							</div>
							<div className="col-4">
								<p>Muhameds22@gmail.com</p>
							</div>
							<div className="col-3">
								<p>01156693094</p>
							</div>
							<div className="col-2 d-flex justify-content-center">
								<RadioBtn />
							</div>
						</div>
						<div className="row">
							<div className="col-3">
								<p>Muhamed Saied</p>
							</div>
							<div className="col-4">
								<p>Muhameds22@gmail.com</p>
							</div>
							<div className="col-3">
								<p>01156693094</p>
							</div>
							<div className="col-2 d-flex justify-content-center">
								<RadioBtn />
							</div>
						</div>
					</div>*/}
							<div className="the-table">
								<table className="table table-borderless">
									<thead className="position-relative">
										<th scope="col" className="usher-name">Name</th>
										{/* <th scope="col" className="usher-name usher-mail">Mail</th> */}
										<th scope="col" className="usher-name usher-number">Number</th>
										<th scope="col" className="assign-text">Assign to Scan Tickets</th>
									</thead>
									<tbody>
										{ushers.map((usher, index) => {
											return (
												<tr key={index}>
													<td style={{ width: "40%" }}>{usher.first_name} {usher.last_name}</td>
													{/* <td>{usher.email}</td> */}
													<td>{usher.phone_number}</td>
													<td className="d-flex justify-content-end"><RadioBtn value={usher.is_assigned} name={usher.id} onChange={(e) => assignUshers(e, usher)} /></td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>}
					<form onSubmit={formik.handleSubmit} autoComplete="off" >
						<div className='row mt-2 mb-4 gap-3'>
							<div className="col-lg-3 col-12">
								<p className='create-subtitle'><img style={{ width: '1.25rem', height: '1.75rem' }} src={Plus} alt="Plus" />Add New Ushers</p>
							</div>
							<div className="col-lg-3 col-12 note">
								<p>When you add new ushers to the event they can<span> Start scan tickets </span>
									at the entrance ,integrated with our<span> Mobile Application </span></p>
							</div>
							<div className='row mt-4' style={{ rowGap: '1.75rem' }}>
								<div className="col-lg-4 col-12">
									<Inputs label={'First Name'} placeholder={'Ahmed'}
										name="first_name"
										onChange={formik.handleChange}
										error={
											formik.touched.first_name &&
											Boolean(formik.errors.first_name)
										}
										helperText={
											formik.touched.first_name && t(formik.errors.first_name)
										}
										value={formik.values.first_name} />
								</div>
								<div className="col-lg-4 col-12">
									<Inputs label={'Last Name'} placeholder={'Ali'}
										name="last_name"
										onChange={formik.handleChange}
										error={
											formik.touched.last_name &&
											Boolean(formik.errors.last_name)
										}
										helperText={
											formik.touched.last_name && t(formik.errors.last_name)
										}
										value={formik.values.last_name} />
								</div>
								<div className="col-lg-4 col-12">
									<Inputs label={'Mobile Number'} placeholder={'01123XXXXXX'}
										name="phone_number"
										onChange={formik.handleChange}
										error={
											formik.touched.phone_number &&
											Boolean(formik.errors.phone_number)
										}
										helperText={
											formik.touched.phone_number && t(formik.errors.phone_number)
										}
										value={formik.values.phone_number} />
								</div>
							</div>
						</div>
						<div className='row mt-4 gap-4'>
							{/* <div className="col d-flex">
								<RadioBtn style={{ width: '2rem', height: '1rem' }} />
								<span>Add as assigned to my upcoming events </span>
							</div> */}
							<div className="col">
								<button style={{ width: '5.5rem', minWidth: '5.5rem', height: '2.5rem', fontSize: '.875rem' }}
									className='stepper-next m-0' type="submit"><img src={Plus} alt='RightIcon' />{submit ? <CircularProgress /> : "Add"}</button>
							</div>
						</div>
					</form>
					<div className='row mt-4'>
						<div className="col d-flex justify-content-end gap-4">
							{/* <button className='stepper-next bg-transparent border-white'><img src={EyePreview} alt='Preview' />Preview</button> */}
							<button onClick={() => {
								navigate('/my-events')
							}
							} className='stepper-next'><img src={Share} alt='share' />Publish</button>
						</div>
					</div>

				</div>
			}
		</div>
	);
}
