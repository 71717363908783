import Twitter from '../../../assets/img/twitter.svg';
import Facebook from '../../../assets/img/facebook.svg';
import Linkedin from '../../../assets/img/linkedin.svg';
import AR from '../../../assets/img/ar.svg';
import { changeCurrentLanguage, getCurrentLanguage, languageEnum } from '../../../config'
export const Footer = () => {
	const selectedLang = getCurrentLanguage();
	return (
		<div className='container-fliud marginHeaderAndFooter'>
			<div className='row footer'>
				<div className='col'>
					<p className='section'>Products</p>
					<a href="/events" style={{ textDecoration: 'none' }}><p className='content'>Events</p></a>
					<a href="/create-event" style={{ textDecoration: 'none' }}><p className='content'>Create</p></a>
				</div>
				<div className='col'>
					<p className='section'>Support</p>
					<p className='content'>About</p>
					<p className='content'>Contact Us</p>
					{/* <p className='content'>Terms & Conditions</p> */}
				</div>
			</div>
			<div className='footer-container'>
				<div className='row last-layer'>
					<div className='col content'>
						© 2023 XPay, Inc.
					</div>
					<div className='col content'>
						Terms & Conditions
					</div>
					{/* {selectedLang === languageEnum.English ?
						<div className='col lang' onClick={() => changeCurrentLanguage(languageEnum.Arabic)}>
							<img src={AR} alt='AR' />
							<span>العربية</span>
						</div>
						:
						<div className='col lang' onClick={() => changeCurrentLanguage(languageEnum.English)}>
							<img src={AR} alt='AR' />
							<span>EN</span>
						</div>
					} */}
					<div className='col imgs'>
						<img src={Twitter} alt='Twitter' />
						<img src={Facebook} alt='Facebook' />
						<img className='m-0' src={Linkedin} alt='Linkedin' />
					</div>
				</div>
			</div>
		</div>
	);
}
