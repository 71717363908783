import { DateExapmle } from "./DateAndTime";
// import { DropdownOutline, Dropdowns } from "./Dropdowns";
import { CheckBoxUI, Inputs } from "./Inputs";
import DeleteIcon from '../../../assets/img/deleteIcon.svg';
import TicketSmallImg from "../../../assets/img/ticketSmallImg.svg";
import RightIcon from "../../../assets/img/rightIcon.svg";
import CalendarSmallWhite from "../../../assets/img/calendarSmallWhite.svg";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { t } from 'i18next';
import { eventTicketsValidationSchema } from "../../../config";
import { useState, useEffect } from "react";
import { ServiceProxy } from "../../../ServiceProxy";
// import { useState } from "react";
export const TicketsUpdate = ({ addTicket, addNewTicket, event, activeStep, steps, submit, setSubmit, setActiveStep }) => {
	const [lateDate, setLateDate] = useState('')
	const [earlyDate, setEarlyDate] = useState('')
	const [loading, setLoading] = useState(false)
	const [eventTickets, setEventTickets] = useState([])
	const getEventTickets = () => {
		setLoading(true)
		ServiceProxy.EventTicketsService.getEditEventTicketsList({ event })
			.then(response => {
				if (response && response.data) {
					setLoading(false)
					const updatedTickets = response.data.results.map(r => {
						return {
							...r,
							early: r.early_bird_price ? true : false,
							late: r.late_price ? true : false,
							early_bird: r.early_bird_date_limit ? 'byDate' : '',
							late_tickets: r.late_date_start ? 'byDate' : '',
							early_bird_price: r.early_bird_price ? r.early_bird_price : '',
							early_bird_quantity: r.early_bird_quantity ? r.early_bird_quantity : '',
							early_bird_date_limit: r.early_bird_date_limit ? r.early_bird_date_limit : '',
							late_quantity: r.late_quantity ? r.late_quantity : '',
							late_date_start: r.late_date_start ? r.late_date_start : '',
							late_price: r.late_price ? r.late_price : '',
						}
					})
					setEventTickets(updatedTickets)
					formik.setFieldValue('ticket', updatedTickets)

				}
			})
			.catch(e => console.error({ e }))
	}
	const onChangeLateDate = (date, dateString, index) => {

		setLateDate(dateString)
		formik.setFieldValue(`ticket[${index}].late_date_start`, dateString);

		// formik.setFieldValue('date', dateString)
	};
	const onChangeEarlyDate = (date, dateString, index) => {

		setEarlyDate(dateString)

		formik.setFieldValue(`ticket[${index}].early_bird_date_limit`, dateString);
		// formik.setFieldValue('time', timeString)

	}
	const addTicketForm = () => {
		const arr = formik.values.ticket.concat([
			{
				name: '',
				price: null,
				quantity: null,
				early: false,
				late: false,
				early_bird_price: '',
				late_price: '',
				early_bird: '',
				late_tickets: '',
				early_bird_quantity: '',
				early_bird_date_limit: '',
				late_quantity: '',
				late_date_start: ''

			}
		])
		formik.setFieldValue('ticket', arr)
	}
	const formik = useFormik({
		// enableReinitialize: true,
		initialValues: {
			ticket: [
				{
					name: '',
					price: null,
					quantity: null,
					early_bird_price: '',
					early_bird_quantity: '',
					early_bird_date_limit: '',
					late_quantity: '',
					late_date_start: '',
					late_price: '',

					early: false,
					late: false,
					early_bird: '',
					late_tickets: '',



				}
			]

		},
		validationSchema: eventTicketsValidationSchema,
		onSubmit: (values) => {
			setSubmit(true)
			for (let i = 0; i < values.ticket.length; i++) {
				if (values.ticket[i].id)
					addTicket(values.ticket[i].id, {
						...values.ticket[i],
						early_bird_quantity: values.ticket[i].early_bird_quantity ? values.ticket[i].early_bird_quantity : null,
						early_bird_date_limit: values.ticket[i].early_bird_date_limit ? values.ticket[i].early_bird_date_limit : null,
						late_quantity: values.ticket[i].late_quantity ? values.ticket[i].late_quantity : null,
						late_date_start: values.ticket[i].late_date_start ? values.ticket[i].late_date_start : null,

					})
				else {
					addNewTicket({
						...values.ticket[i],
						early_bird_quantity: values.ticket[i].early_bird_quantity ? values.ticket[i].early_bird_quantity : null,
						early_bird_date_limit: values.ticket[i].early_bird_date_limit ? values.ticket[i].early_bird_date_limit : null,
						late_quantity: values.ticket[i].late_quantity ? values.ticket[i].late_quantity : null,
						late_date_start: values.ticket[i].late_date_start ? values.ticket[i].late_date_start : null,

					})
				}
			}
			// setActiveStep(activeStep + 1)
			setSubmit(false)
			// clickNext(updatedValues)
		},
	});
	const deleteTicketType = (index) => {
		var array = [...formik.values.ticket]; // make a separate copy of the array

		if (index !== -1) {
			array.splice(index, 1);
			console.log(array)
			formik.setFieldValue('ticket', array)
		}
	}
	useEffect(() => {
		getEventTickets()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container details-event">

			<form onSubmit={formik.handleSubmit} autoComplete="off" >
				{formik.values.ticket && formik.values.ticket.map((obj, index) => {
					return (
						<div className="row align-items-center gap-4">
							<div className="d-flex align-items-center gap-3 p-0" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
								<div className="d-flex align-items-center gap-3 p-0 mt-3">
									<img src={TicketSmallImg} alt="Ticket" />
									<h4 className="m-0">Your Ticket Variations</h4>
									<span className="opacity-25 pt-2">{index === 0 ? 'Regular' : 'VIP'}</span>
								</div>
								{index !== 0 && <img src={DeleteIcon} alt="delete" className="pointer" onClick={() => deleteTicketType(index)} />}
							</div>
							<div className="row align-items-center gap-4 column-small">
								<div className="col p-0">
									<Inputs label={'Ticket Type'} placeholder={'Regular'}
										name={`ticket[${index}].name`}
										onChange={formik.handleChange}
										error={
											formik.touched.ticket &&
											Boolean(formik.errors.ticket?.[index]?.name)
										}
										helperText={
											formik.touched.ticket && t(formik.errors.ticket?.[index]?.name)
										}
										value={formik.values.ticket?.[index]?.name}
									/>
								</div>
								<div className="col p-0 position-relative m-3">
									<Inputs label={'Ticket Price'} placeholder={'250'}
										name={`ticket[${index}].price`}
										onChange={formik.handleChange}
										error={
											formik.touched.ticket &&
											Boolean(formik.errors.ticket?.[index]?.price)
										}
										helperText={
											formik.touched.ticket && t(formik.errors.ticket?.[index]?.price)
										}
										value={formik.values.ticket?.[index]?.price} />
									<span className="egp">EGP</span>
								</div>
								<div className="col p-0">
									<Inputs label={'Number of Tickets'} placeholder={'1000'}
										name={`ticket[${index}].quantity`}
										onChange={formik.handleChange}
										error={
											formik.touched.ticket &&
											Boolean(formik.errors.ticket?.[index]?.quantity)
										}
										helperText={
											formik.touched.ticket && t(formik.errors.ticket?.[index]?.quantity)
										}
										value={formik.values.ticket?.[index]?.quantity} />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<CheckBoxUI label={'Price for early registered tickets'}
										name={`ticket[${index}].early`} checked={formik.values.ticket?.[index]?.early} onChange={(e) => {
											formik.handleChange(e)

										}} />
								</div>
							</div>
							{formik.values.ticket?.[index]?.early &&
								<div className="row w-100 note gap-4 p-4 justify-content-around">
									<div className="col-lg-5 col-11 p-0 position-relative m-3">
										<Inputs label={'Price'} placeholder={'250'}
											name={`ticket[${index}].early_bird_price`}
											onChange={formik.handleChange}
											error={
												formik.touched.ticket &&
												Boolean(formik.errors.ticket?.[index]?.early_bird_price)
											}
											helperText={
												formik.touched.ticket && t(formik.errors.ticket?.[index]?.early_bird_price)
											}
											value={formik.values.ticket?.[index]?.early_bird_price} />
										<p className="egp">EGP</p>
									</div>
									<div className="col-lg-6 col-11 p-0">
										<div className="row align-items-center mb-4 gap-4">
											<div className="col-lg-4 col-12 check-box">
												<div class="form-check">
													<input class="form-check-input" type="radio" name={`ticket[${index}].early_bird`} value={"byDate"} onChange={(e) => formik.setFieldValue(`ticket[${index}].early_bird`, e.target.value)} />
													<label class="form-check-label" htmlFor={`ticket[${index}].early_bird`}>
														By Date
													</label>
												</div>
											</div>
											<div className="col">
												<DateExapmle label={'Date'} placeholder={"01-12-2023"}
													icon={<img src={CalendarSmallWhite} alt="CalendarSmallWhite" />}
													name={`ticket[${index}].early_bird_date_limit`}
													onChange={(date, dateString) => onChangeEarlyDate(date, dateString, index)}
													value={formik.values.ticket?.[index]?.early_bird_date_limit}
													error={
														formik.touched.ticket &&
														Boolean(formik.errors.ticket?.[index]?.early_bird_date_limit)
													}
													helperText={
														formik.touched.ticket && t(formik.errors.ticket?.[index]?.early_bird_date_limit)
													} />
											</div>
										</div>
										<div className="row align-items-center gap-4">
											<div className="col-lg-4 col-12 check-box">
												<div class="form-check">
													<input class="form-check-input" type="radio" name={`ticket[${index}].early_bird`} value={"byTicket"} onChange={(e) => formik.setFieldValue(`ticket[${index}].early_bird`, e.target.value)} />
													<label class="form-check-label" htmlFor={`ticket[${index}].early_bird`}>
														By Ticket
													</label>
												</div>
											</div>
											<div className="col">
												<Inputs label={'Number of Tickets'} placeholder={'1000'}
													name={`ticket[${index}].early_bird_quantity`}
													onChange={formik.handleChange}
													error={
														formik.touched.ticket &&
														Boolean(formik.errors.ticket?.[index]?.early_bird_quantity)
													}
													helperText={
														formik.touched.ticket && t(formik.errors.ticket?.[index]?.early_bird_quantity)
													}
													value={formik.values.ticket?.[index]?.early_bird_quantity} />
											</div>
										</div>
									</div>
								</div>}
							<div className="row">
								<div className="col">
									<CheckBoxUI label={'Price for late tickets'}
										name={`ticket[${index}].late`}
										checked={formik.values.ticket?.[index]?.late} onChange={(e) => {
											formik.handleChange(e)

										}} />
								</div>
							</div>
							{formik.values.ticket?.[index]?.late &&
								<div className="row w-100 note gap-4 p-4 justify-content-around">
									<div className="col-lg-5 col-11 p-0 position-relative m-3">
										<Inputs label={'Price'} placeholder={'250'}
											name={`ticket[${index}].late_price`}
											onChange={formik.handleChange}
											error={
												formik.touched.ticket &&
												Boolean(formik.errors.ticket?.[index]?.late_price)
											}
											helperText={
												formik.touched.ticket && t(formik.errors.ticket?.[index]?.late_price)
											}
											value={formik.values.ticket?.[index]?.late_price} />
										<p className="egp">EGP</p>
									</div>
									<div className="col-lg-6 col-11 p-0">
										<div className="row align-items-center mb-4 gap-4">
											<div className="col-lg-4 col-12 check-box">
												<div class="form-check">
													<input class="form-check-input" type="radio" name={`ticket[${index}].late_tickets`} value="byDate" onChange={(e) => formik.setFieldValue(`ticket[${index}].late_tickets`, e.target.value)} />
													<label class="form-check-label" htmlFor={`ticket[${index}].late_tickets`}>
														By Date
													</label>
												</div>
											</div>
											<div className="col">
												<DateExapmle label={'Date'} placeholder={"01-12-2023"}
													icon={<img src={CalendarSmallWhite} alt="CalendarSmallWhite" />}
													name={`ticket[${index}].late_date_start`}
													onChange={(date, dateString) => onChangeLateDate(date, dateString, index)}
													value={formik.values.ticket?.[index]?.late_date_start}
													error={
														formik.touched.ticket &&
														Boolean(formik.errors.ticket?.[index]?.late_date_start)
													}
													helperText={
														formik.touched.ticket && t(formik.errors.ticket?.[index]?.late_date_start)
													}
												/>
											</div>
										</div>
										<div className="row align-items-center gap-4">
											<div className="col-lg-4 col-12 check-box">
												<div class="form-check">
													<input class="form-check-input" type="radio" name={`ticket[${index}].late_tickets`} value={"byTicket"} onChange={(e) => formik.setFieldValue(`ticket[${index}].late_tickets`, e.target.value)} />
													<label class="form-check-label" htmlFor={`ticket[${index}].late_tickets`}>
														By Ticket
													</label>
												</div>
											</div>
											<div className="col">
												<Inputs label={'Number of Tickets'} placeholder={'1000'}
													name={`ticket[${index}].late_quantity`}
													onChange={formik.handleChange}
													error={
														formik.touched.ticket &&
														Boolean(formik.errors.ticket?.[index]?.late_quantity)
													}
													helperText={
														formik.touched.ticket && t(formik.errors.ticket?.[index]?.late_quantity)
													}
													value={formik.values.ticket?.[index]?.late_quantity} />
											</div>
										</div>
									</div>
								</div>}
							{/* <div className="row mb-5">
								<div className="col">
									<CheckBoxUI label={'Require user to enter each ticket data'} />
								</div>
							</div> */}

						</div>
					)
				})
				}
				<div className='row mt-4'>
					<div className="col d-flex justify-content-end gap-4">
						<button onClick={addTicketForm} className='stepper-next' type="button"> Add Ticket</button>

						{activeStep !== steps.length - 1
							&& <button className='stepper-next' type="submit"
							>{submit ? <CircularProgress /> : "Next"} <img src={RightIcon} alt='RightIcon' /></button>
						}
					</div>
				</div>
			</form>
		</div>
	);
}
