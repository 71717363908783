
import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers/axiosDefaults";

export class TicketsService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/tickets`
    }
    getTicketsList(params) {
        addAxiosDefaultHeaders(axios)
        const request = axios.get(`${this._baseUrl}/list/`, { params });
        removeDefaultHeaders(axios)
        return request;
    }
}