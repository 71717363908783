import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ErrorPage } from "./components/application";

import {
    Verify, Login, Register, ForgetPassword, Home, EventDetails, OrganizationProfile,
    CreateEvent, MyEvents, OrganizerProfile, Organizations, CreateOrganization,
    Locations,
    Events,
    ResetPassword,
    EditOrganization,
    Checkout,
    Settings,
    Iframe,
    EditEventSteps,
    Payments,
    SoldTickets
} from "./pages";
import { StorageService, storageKeys } from "./application/services";

// import EventOrganizer from "./pages/OrganizationProfile";
const ProtectedRoute = ({ user, redirectPath = '/', children }) => {
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};
export default function Routers() {
    const user = StorageService.get(storageKeys.userID)
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<ProtectedRoute user={user}><Home /></ProtectedRoute>} />
                <Route path="/signup" element={<Register />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/event-details/:id" element={<EventDetails />} />
                <Route path="/organiztion-profile/:id" element={<OrganizationProfile />} />
                <Route path="/organizer-profile/:id" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.accessToken)}><OrganizerProfile /></ProtectedRoute>} />
                <Route path="/my-events" element={<ProtectedRoute user={StorageService.get(storageKeys.userID)}><MyEvents /></ProtectedRoute>} />
                <Route path="/create-event" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><CreateEvent /></ProtectedRoute>} />
                <Route path="/my-organizations/:id" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><Organizations /></ProtectedRoute>} />
                <Route path="/edit-organization/:id" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><EditOrganization /></ProtectedRoute>} />
                <Route path="/edit-event/:id" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><EditEventSteps /></ProtectedRoute>} />
                <Route path="/locations" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><Locations /></ProtectedRoute>} />
                <Route path="/events" element={<Events />} />
                <Route path="/checkout" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><Checkout /></ProtectedRoute>} />
                <Route path="/settings" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><Settings /></ProtectedRoute>} />
                <Route path="/create-organization" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><CreateOrganization /></ProtectedRoute>} />
                <Route path="/buy-tickets" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><Iframe /></ProtectedRoute>} />
                <Route path="/payments" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><Payments /></ProtectedRoute>} />
                <Route path="/sold-tickets" element={<ProtectedRoute redirectPath="/login" user={StorageService.get(storageKeys.userID)}><SoldTickets /></ProtectedRoute>} />

                <Route path="*" element={<ErrorPage />} />
            </Routes>

        </Router>
    );
}

