import React from 'react';
import { Menu } from 'antd';
import Building from '../../../assets/img/building.svg';
import List from '../../../assets/img/arrowDownList.svg';

const MenuCollapsible = ({ cities, categories, handleChangCategory, handleChangeCity }) => {
    return (
        <div className='menu' style={{ width: '100%' }}>
            <Menu
                defaultSelectedKeys={[]}
                // defaultOpenKeys={["menu1"]}
                mode="inline"
                theme="dark"
                className='menu-filter'
            >
                <Menu.SubMenu key="menu1" icon={<img className='m-0' src={List} alt='Categories' />} title={"Categories"} onClick={({ key }) => handleChangCategory(key)}>
                    {categories.map(cate => <Menu.Item key={cate.key}>{cate.label}</Menu.Item>)}
                </Menu.SubMenu>
            </Menu>
            <Menu
                defaultSelectedKeys={[]}
                mode="inline"
                theme="dark"
                className='menu-filter'
            >

                <Menu.SubMenu key="menu2" icon={<img className='m-0' src={Building} alt='Cities' />} title="Cities" onClick={({ key }) => handleChangeCity(key)} >
                    {cities.map(city => <Menu.Item key={city.key}>{city.label}</Menu.Item>)}
                </Menu.SubMenu>
            </Menu>
        </div>
    );
};
export { MenuCollapsible };