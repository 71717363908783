import CalendarBlue from '../../../assets/img/calendarBlue.svg';
// import HeartRed from '../../../assets/img/heartRed.svg';
import Location from '../../../assets/img/location.svg';
import ArrowRight from '../../../assets/img/arrowRight.svg';
// import Eye from '../../../assets/img/eye.svg';
import EventImg2 from '../../../assets/img/eventImg2.svg';
import Skeleton from '@mui/material/Skeleton';
export const EventCard = ({ data, eventDetailsNavigate }) => {
    return (
        <div className="card">

            {
                !data.image ?
                    <div className='event-placeholder-img'></div> :
                    <div className='bg-white' style={{ height: '300px' }} onClick={() => eventDetailsNavigate(data.id, data)} >
                        <img className="card-img-top pointer" height={'100%'} width={'100%'} src={data.image} alt="cardImg" />
                    </div>
            }
            {/* <Skeleton variant="rounded" height={315} sx={{ bgcolor: 'grey.700' }} animation={false} /> */}
            <div className="card-body">
                <div className='col statistics'>
                    {/* <p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
                    <p className='like'><img src={Eye} alt='Eye' /> 33 Views</p> */}
                    <p className='like'>{data.category && data.category.name}</p>
                </div>
                <div className='col statistics' onClick={() => eventDetailsNavigate(data.id, data)}>
                    <p className="card-title m-0 pointer">{data.name}</p>
                </div>
                <div className='col statistics'>
                    <p className="like card-text"><img src={CalendarBlue} alt='HeartRed' />{data.date}  ~ {data.time}</p>
                    <p className="like card-text text-decoration-underline">{data.organization && data.organization.name}</p>
                </div>
                {data.location &&
                    <div className='col statistics'>
                        {data.location.name ?
                            <p className="like card-text location"><img src={Location} alt='Location' />   {data.location.name}- {data.location.address}</p>
                            :
                            <p className="like card-text location"><img src={Location} alt='Location' /> {data.location.address}</p>

                        }
                        <a className="like card-text directions" target={'_blank'} rel="noreferrer" href={data.location.google_map_link}>Get Directions</a>
                    </div>}
                <div className='col m-0 statistics btns'>
                    {/* <button className="btn book-now more-details" onClick={() => eventDetailsNavigate(data.id, data)}>More Details<img src={ArrowRight} alt='ArrowRight' /></button> */}
                    <button className="btn book-now w-100" onClick={() => eventDetailsNavigate(data.id, data)} >Book Now</button>
                </div>
            </div>
        </div>
    )
}

export const ActiveEventCard = ({ data, eventDetailsNavigate, active }) => {
    return (
        <div className="cards">
            <div className="img-card">
                {
                    !data.image ?
                        <div className='event-placeholder-img '></div> :
                        <div style={{ height: '354px' }} onClick={() => eventDetailsNavigate(data.id, data)} >
                            <img className="card-img-top pointer" height={'100%'} width={'100%'} src={data.image} alt="cardImg" />
                        </div>
                }

            </div>
            <div className="card-body">
                <div className='col statistics'>
                    {/* <p className='like'><img src={HeartWhite} alt='HeartRed' />20 Like</p> */}
                    {/* <p className='like'><img src={Eye} alt='Eye' /> 33 Views</p> */}
                    <p className='like'><p className='like'>{data.category && data.category.name}</p></p>
                </div>
                <div className='col statistics'>
                    <p className="card-title m-0">{data.name}</p>
                </div>
                <div className='col statistics'>
                    <p className="like"><img src={CalendarBlue} alt='CalendarBlue' />{data.date}  ~ {data.time}</p>
                </div>
                <div className='col statistics'>
                    {data.location.name ?
                        <p className="like location"><img src={Location} alt='Location' />   {data.location.name}- {data.location.address}</p>
                        :
                        <p className="like location"><img src={Location} alt='Location' />  {data.location.address}</p>
                    }
                    <a className="like card-text directions" target={'_blank'} rel="noreferrer" href={data.location.google_map_link}>Get Directions</a>
                </div>
                {active &&
                    <div className='col m-0 statistics btns'>
                        <button className="btn bookNow more-details" onClick={() => eventDetailsNavigate(data.id, data)}>More Details<img src={ArrowRight} alt='ArrowRight' /></button>
                        <button className="btn bookNow">Book Now</button>
                    </div>}
            </div>
        </div>
    )
}
export const EventCardDark = ({ data, eventDetailsNavigate }) => {
    return (
        <div className="cards">
            {
                !data.image ?
                    <div className='event-placeholder-img '></div> :
                    <div style={{ height: '300px' }} onClick={() => eventDetailsNavigate(data.id, data)} >
                        <img className="card-img-top pointer" height={'100%'} width={'100%'} src={data.image} alt="cardImg" />
                    </div>
            }
            <div className="card-body">
                <div className='col statistics'>
                    {/* <p className='like'><img src={HeartRed} alt='HeartRed' />20 Like</p>
                    <p className='like'><img src={Eye} alt='Eye' /> 33 Views</p> */}
                    <p className='like'>{data.category && data.category.name}</p>
                </div>
                <div className='col statistics' onClick={() => eventDetailsNavigate(data.id, data)}>
                    <p className="card-title m-0 pointer">{data.name}</p>
                </div>
                <div className='col statistics'>
                    <p className="like card-text"><img src={CalendarBlue} alt='HeartRed' />{data.date}  ~ {data.time}</p>
                    <p className="like card-text text-decoration-underline">{data.organization && data.organization.name}</p>
                </div>
                {data.location &&
                    <div className='col statistics'>
                        {data.location.name ?
                            <p className="like card-text location"><img src={Location} alt='Location' />   {data.location.name}- {data.location.address}</p>
                            :
                            <p className="like card-text location"><img src={Location} alt='Location' /> {data.location.address}</p>

                        }
                        <a className="like card-text directions" target={'_blank'} rel="noreferrer" href={data.location.google_map_link}>Get Directions</a>
                    </div>}
                <div className='col m-0 statistics btns'>
                    {/* <button className="btn book-now more-details" onClick={() => eventDetailsNavigate(data.id, data)}>More Details<img src={ArrowRight} alt='ArrowRight' /></button> */}
                    <button className="btn bookNow w-100" onClick={() => eventDetailsNavigate(data.id, data)} >Book Now</button>
                </div>
            </div>
        </div>
    )
}