import { Header } from "../Home/components";
// import HeartRed from '../../assets/img/heartRed.svg';
import UserImgEvent from '../../assets/img/userImgEvent.svg';
// import Play from '../../assets/img/play.svg';
// import Pause from '../../assets/img/pause.svg';
import ActiveEvents from '../../assets/img/avtiveEvents.svg'
import PastEvents from '../../assets/img/pastEvents.svg';
// import Location from '../../assets/img/location.svg';
import FacebookSocial from '../../assets/img/facebookSocial.svg';
import InstagramSocial from '../../assets/img/instagramSocial.svg';
// import LinkedinSocial from '../../assets/img/linkedinSocial.svg';
import Twitter from '../../assets/img/twitterWhite.svg';
import NoEvents from '../../assets/img/noEvents.svg';
// import ArrowRight from '../../assets/img/arrowRight.svg';
// import CalendarBlue from '../../assets/img/calendarBlue.svg';
// import Eye from '../../assets/img/eye.svg';
// import EventImg2 from '../../assets/img/eventImg2.svg';
// import HeartWhite from '../../assets/img/heartWhite.svg';
import { useState, useEffect } from "react";
import { StorageService } from "../../application/services";
import { pageSize } from "../../config";
import { ServiceProxy } from "../../ServiceProxy";
import { useParams, useNavigate } from "react-router-dom";
import { ActiveEventCard, Overlay } from "../../components/ui";
export const OrganizerProfile = () => {
	const [toggle, setToggle] = useState(1)
	let { id } = useParams();
	const navigate = useNavigate()
	const [user, setUser] = useState(null)
	const [activeEvents, setActiveEvents] = useState([])
	const [pastEvents, setPastEvents] = useState([])
	const [loading, setLoading] = useState(true)
	const eventsParams = {
		page_size: pageSize,
		page: 1
	}
	const eventDetailsNavigate = (id) => navigate(`/event-details/${id}`)
	const getUser = () => {
		id && ServiceProxy.UsersService.getUserData(id)
			.then(response => {
				if (response && response.data) {
					setUser(response.data)
					getEvents()
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const getEvents = () => {
		id && ServiceProxy.EventsService.getEventsList({ ...eventsParams, user: id, is_active: true })
			.then(response => {
				if (response && response.data) {
					const result = response.data.results
					setActiveEvents(result)
					getPastEvents()
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const getPastEvents = () => {
		id && ServiceProxy.EventsService.getEventsList({ ...eventsParams, user: id, is_active: false })
			.then(response => {
				if (response && response.data) {
					setPastEvents(response.data.results)
					setLoading(false)
				}
			})
			.catch(e => {
				console.error({ e })
				setLoading(false)
			})
	}
	const logout = () => {
		StorageService.clear()
		navigate('/')
	}
	const loginNavigate = () => navigate('/login')
	const signupNavigate = () => navigate('/signup')
	useEffect(() => {
		getUser()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div className="container-fluid event-organizer">
			<Header logout={logout} loginNavigate={loginNavigate} signupNavigate={signupNavigate} />
			{loading ? <Overlay /> :
				<>
					<div>
						<div className="img-event"></div>
						<div className="all-events-organizer">
							<div className="organizer">
								<div className="img-organizer">
									<img src={user.photo ? user.photo : UserImgEvent} alt="" />
								</div>
								<div className="name-organizer">
									<p className="name m-0">{`${user.first_name} ${user.last_name}`}</p>
									<p className="position m-0">Organizer</p>
								</div>
							</div>
							<ul className="tabs-events">
								<li className={toggle === 1 ? 'tab-events-show' : 'tab-events'} onClick={() => setToggle(1)}><img src={ActiveEvents} alt="Play" /> Active Events</li>
								<li className={toggle === 2 ? 'tab-events-show' : 'tab-events'} onClick={() => setToggle(2)}><img src={PastEvents} alt="Pause" /> Past Events</li>
							</ul>
						</div>
					</div>
					<div className="row m-2 mt-4 gap-3 viewContent">
						<div className="col-xl-3 col-lg-3 col-md-12">
							{/* <div className="p-0 cardContent">
								<div className="content p-4">
									<div className="col">
										<p className="date m-0">20</p>
										<p className="organizedBy text-white">Followers</p>
									</div>
									<button type="button" className="btn viewBtn w-auto">Follow</button>
								</div>
							</div>
							<div className="p-0 cardContent mt-4">
								<div className="content p-4">
									<div className="col">
										<p className="date m-0">About </p>
										<p className="desc pt-3 w-75">It's Mohamed Zaid an Egyptian event organizer specialized in making  business event meetings </p>
										<div className="location-event">
											<img src={Location} alt="Location" />
											<span className="desc">Cairo</span>
										</div>
									</div>
								</div>
							</div> */}
							<div className="p-0 cardContent mt-4">
								<div className="content p-4">
									<div className="col">
										<p className="date m-0">Social Links</p>
										<div className="location-event d-flex gap-3">
											<img src={FacebookSocial} alt="FacebookSocial" />
											<img src={InstagramSocial} alt="InstagramSocial" />
											<img src={Twitter} alt="LinkedinSocial" />
										</div>
									</div>
								</div>
							</div>
						</div>
						{toggle === 1 ?
							<div className="col">
								<div className="row gap-3 m-2 cards-center">
									{activeEvents.length > 0 ?
										<>
											{activeEvents.map((event, index) => {
												return (
													<ActiveEventCard key={index} data={event} active eventDetailsNavigate={eventDetailsNavigate} />
												)
											})}

										</>
										:
										<div className="col cardTickets ">
											<div className="row">
												<div className="col">
													<img src={NoEvents} alt="NoEvents" />
													<p className="date">No Events to show</p>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
							: toggle === 2 && pastEvents.length > 0 ?
								pastEvents.map((event, index) => {
									return (
										<ActiveEventCard key={index} data={event} eventDetailsNavigate={eventDetailsNavigate} />
									)
								})
								:
								<div className="col cardTickets m-2">
									<div className="row">
										<div className="col">
											<img src={NoEvents} alt="NoEvents" />
											<p className="date">No Events to show</p>
										</div>
									</div>
								</div>
						}

					</div>
				</>
			}
		</div>
	)
}
